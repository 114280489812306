import {
    Avatar,
    Box,
    Button,
    IconButton,
    Skeleton,
    TextField,
    Typography,
} from '@mui/material';
import { CampaignMenu } from './CampaignMenu';
import {
    useCampaign,
    useCampaignRenameQuery,
} from '../../../queries/campaigns';
import * as styles from './styles';
import { useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { ButtonDotLoader } from '../../../components/Forms/ButtonDotLoader';
import { getAvatarGradient } from '../../../elements/Avatar/get-avatar-color';
import { getInitials } from './string.helpers';
import { useTrack } from '../../../contexts/analytics';
import { getSquare } from '../../../theme/style.helpers';
import { AdapterLink } from '../../../components/ConversationsList/AdapterLink';
import { ArrowBackIcon } from '../../../icons/common/CArrowBack';
import { BackFromEntityButton } from '../../../pages/NewInbox/Conversation/BackFromEntityButton';
import { HideUpMd } from '../../../components/MediaHelpers/HideUpMd';
import { useNavigate } from 'react-router';
import { UserAvatar } from '../../../components/UserAvatar/UserAvatar';

enum Mode {
    View,
    Edit,
}

type Props = {
    campaignId: number;
    prevUrl?: string;
    redirectUrl: string;
};

type CampaignChange = { name: string };

export const CampaignHeader = ({
    campaignId,
    prevUrl = '',
    redirectUrl,
}: Props) => {
    const track = useTrack();
    const navigate = useNavigate();
    const [mode, setMode] = useState<Mode>(Mode.View);
    const { data, isLoading } = useCampaign(campaignId);
    const background = useMemo(
        () => getAvatarGradient(data?.name || ''),
        [data?.name],
    );
    const { mutateAsync: rename, isPending: renaming } =
        useCampaignRenameQuery();
    const { handleSubmit, register, setValue, setFocus, reset } = useForm({
        resolver: yupResolver<CampaignChange>(
            Yup.object({ name: Yup.string().required().min(1).max(200) }),
        ),
    });

    const onSubmit: SubmitHandler<CampaignChange> = (data: CampaignChange) => {
        rename({ campaignId, name: data.name }).then(() => {
            setMode(Mode.View);
        });
    };

    const onRename = () => {
        setValue('name', data?.name || '');
        setMode(Mode.Edit);

        setTimeout(() => {
            setFocus('name');
        }, 100);
    };

    const onRemove = () => {
        navigate(redirectUrl);
    };

    return (
        <Box sx={styles.header}>
            {isLoading || !data ? (
                <Skeleton width={150} height={14} />
            ) : (
                <>
                    <HideUpMd>
                        <BackFromEntityButton />
                    </HideUpMd>
                    {mode === Mode.View && (
                        <>
                            {!!prevUrl && (
                                <IconButton
                                    LinkComponent={AdapterLink}
                                    href={prevUrl}
                                    sx={{ mr: 2 }}
                                    onClick={() =>
                                        track(
                                            'campaign_message_back_button_clicked',
                                        )
                                    }
                                    color="primary"
                                >
                                    <ArrowBackIcon />
                                </IconButton>
                            )}
                            <Avatar
                                alt={data.name}
                                sx={{
                                    background,
                                    ...getSquare(20),
                                    fontSize: 10,
                                }}
                            >
                                {getInitials(data.name) || (
                                    <UserAvatar size={40} />
                                )}
                            </Avatar>
                            <Typography
                                variant="body3"
                                color="primary.dark"
                                noWrap
                                component="h1"
                                data-testid="campaign-header-name"
                            >
                                {data.name}
                            </Typography>
                        </>
                    )}
                    {mode === Mode.Edit && (
                        <Box
                            sx={{
                                display: 'flex',
                                gap: 2,
                                alignItems: 'center',
                            }}
                            component="form"
                            onSubmit={handleSubmit(onSubmit)}
                        >
                            <TextField
                                disabled={renaming}
                                size="small"
                                sx={{ minWidth: 220, mr: 4 }}
                                {...register('name', {
                                    required: true,
                                })}
                                InputProps={{ 'aria-label': 'Name' }}
                                data-testid="campaign-header-rename-input"
                            />
                            <Button
                                size="small"
                                type="submit"
                                disabled={renaming}
                                sx={{ minWidth: 80 }}
                                data-testid="campaign-header-rename-button"
                            >
                                {renaming ? <ButtonDotLoader /> : 'Rename'}
                            </Button>
                            <Button
                                size="small"
                                color="primary"
                                variant="text"
                                disabled={renaming}
                                onClick={() => {
                                    reset();
                                    setMode(Mode.View);
                                    track('campaign_rename_canceled');
                                }}
                                data-testid="campaign-header-rename-cancel-button"
                            >
                                Cancel
                            </Button>
                        </Box>
                    )}
                </>
            )}
            <Box sx={styles.toolbar}>
                <CampaignMenu
                    onRename={onRename}
                    campaignId={campaignId}
                    onRemoveSuccess={onRemove}
                />
            </Box>
        </Box>
    );
};
