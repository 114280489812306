import { useEffect, useState } from 'react';
import { captureException } from '@sentry/react';
import { useEnabledFeature } from '../queries/user';
import { Feature } from '../api/types';

declare global {
    interface Window {
        Featurebase?: (action: string, options?: object) => void;
    }
}

export const useChangelog = () => {
    const [isLoaded, setIsLoaded] = useState(false);

    const isChangelogEnabled = useEnabledFeature(Feature.Changelog);

    // Load Featurebase SDK
    useEffect(() => {
        if (!isChangelogEnabled) {
            return;
        }
        const scriptId = 'featurebase-sdk';
        if (document.getElementById(scriptId)) {
            setIsLoaded(true);
            return;
        }

        const script = document.createElement('script');
        script.id = scriptId;
        script.src = 'https://do.featurebase.app/js/sdk.js';
        script.async = true;

        script.onload = () => {
            setIsLoaded(true);
        };

        script.onerror = () => {
            captureException(new Error('Failed to load Featurebase script'));
        };

        document.body.appendChild(script);
    }, [isChangelogEnabled]);

    // Initialize Featurebase
    useEffect(() => {
        if (
            !isLoaded ||
            !window.Featurebase ||
            typeof window.Featurebase !== 'function' ||
            !isChangelogEnabled
        ) {
            return;
        }
        window.Featurebase('init_changelog_widget', {
            organization: 'clerkchat',
            dropdown: {
                enabled: false,
                placement: 'right',
            },
            popup: {
                enabled: true,
                autoOpenForNewUpdates: true,
            },
            theme: 'light',
            locale: 'en',
        });
    }, [isLoaded, isChangelogEnabled]);
};
