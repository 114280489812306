import cc from 'classcat';
import styles from './StartStopStep.module.scss';
import { useState } from 'react';
import { useWorkflowContext } from '../WorkflowProvider';
import { TriggerIcon } from '../../../icons/common/TriggerIcon';
import { getSquare } from '../../../theme/style.helpers';

interface StartStopStepProps {
    type: 'trigger' | 'exit';
    onClick?: () => void;
}

export const StartStopStep = ({ type, onClick }: StartStopStepProps) => {
    const [active, setActive] = useState(false);
    const isClickable = type === 'trigger' && onClick;
    const {
        state: { workflow },
    } = useWorkflowContext();

    const handleOnClick = () => {
        if (isClickable) {
            setActive(true);
            onClick();
        }
    };

    return (
        <div
            className={cc([
                styles['start-stop-step'],
                active && styles['start-stop-step__active'],
            ])}
            onClick={handleOnClick}
        >
            {type === 'trigger' && (
                <>
                    <div className={styles['start-stop-step__inner']}>
                        <TriggerIcon sx={{ ...getSquare(20), mr: 2 }} />
                        <span className={styles['start-stop-step__text']}>
                            Trigger
                        </span>
                    </div>
                    <span className={styles['start-stop-step__trigger-type']}>
                        {workflow?.trigger
                            ? workflow?.trigger.replaceAll('_', ' ')
                            : ''}
                    </span>
                </>
            )}
            {type === 'exit' && (
                <span className={styles['start-stop-step__text']}>Exit</span>
            )}
        </div>
    );
};
