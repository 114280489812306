import { useMeQueryData } from '../../../queries/user';
import { useInboxes } from '../../../queries/inboxes';

export const useIsOutOfMessages = () => {
    const me = useMeQueryData();
    const { data: inboxes = [] } = useInboxes({
        select: (data) => data.filter((inbox) => inbox.status === 'active'),
    });

    return (
        me?.activeTeam?.isAbleToSend === false && inboxes && inboxes.length > 0
    );
};
