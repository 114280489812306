import React, { useState } from 'react';
import { PopoverPosition } from '@mui/material/Popover/Popover';

export type MenuProps = {
    anchorEl: HTMLElement | null;
    open: boolean;
    handleClose: () => void;
    handleOpen: (event: React.MouseEvent<HTMLElement>) => void;
};

export const useMenu = ({
    defaultAnchor = null,
}: {
    defaultAnchor?: HTMLElement | null;
} = {}): MenuProps => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(defaultAnchor);
    const open = Boolean(anchorEl);
    const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return { anchorEl, open, handleOpen, handleClose };
};

type AnchorPositionMenuProps = {
    anchorPosition: PopoverPosition | undefined;
    open: boolean;
    handleClose: () => void;
    handleOpen: (event: MouseEvent) => void;
};

export const useAnchorPositionMenu = (): AnchorPositionMenuProps => {
    const [anchorPosition, setAnchorPosition] = useState<
        PopoverPosition | undefined
    >();

    const open = Boolean(anchorPosition);
    const handleOpen = (event: MouseEvent) => {
        setAnchorPosition({ top: event.clientY, left: event.clientX });
    };
    const handleClose = () => {
        setAnchorPosition(undefined);
    };
    return { anchorPosition, open, handleOpen, handleClose };
};
