import * as Sentry from '@sentry/react';
import { Contact, Feature } from '../../api/types';
import { useContactUpdateQuery } from '../../queries/contacts';
import { useEnabledFeature, useMeQueryData } from '../../queries/user';
import CollapsiblePropertiesSection from '../CollapsiblePropertiesSection';
import styles from './ComplianceProperties.module.scss';
import { OptOutIcon } from '../../icons/common/OptOutIcon';
import { LockIcon } from '../../icons/shared/LockIcon';
import { useTrack } from '../../contexts/analytics';
import { queryClient } from '../../queries/queryClient';
import { NEXT_CONTACTS } from '../Contacts/contacts.queries';
import { Box, ListItemText, Switch, Tooltip } from '@mui/material';
import { colors } from '../../theme/palette';
import { useConfirmOptIn } from './use-confirm-opt-in';
import { useIsWorkspaceOwnerOrAdmin } from '../../hooks/users';

type CompliancePropertiesProps = {
    contact: Partial<Contact> & { id: string };
};

export const ComplianceProperties = (props: CompliancePropertiesProps) => {
    const track = useTrack();
    const { mutateAsync: updateContact } = useContactUpdateQuery();
    const me = useMeQueryData();
    const [confirmOptIn, ConfirmOptInDialog] = useConfirmOptIn(props.contact);
    const isWoOrAdmin = useIsWorkspaceOwnerOrAdmin();
    const membersCanOptOut = useEnabledFeature(
        Feature.MembersCanOptOutContacts,
    );

    const optOutDisabled = membersCanOptOut ? false : !isWoOrAdmin;

    const toogleOptOut = () => {
        updateContact({
            ...props.contact,
            optOut: !props.contact?.optOut,
        })
            .then((updated) => {
                track('contact_opt_out_toggled', {
                    contactId: updated.id,
                    optOut: updated.optOut,
                });
                queryClient.invalidateQueries({
                    queryKey: [NEXT_CONTACTS, { optOut: true }],
                    exact: false,
                });
            })
            .catch((error) => {
                Sentry.captureException(error);
            });
    };

    return (
        <CollapsiblePropertiesSection title="Compliance" initiallyExpanded>
            {me?.id === props.contact?.createdByUserId && (
                <Box className={styles['row']}>
                    <LockIcon
                        sx={{
                            width: 20,
                            height: 20,
                            color: colors.superGrey[500],
                        }}
                    />
                    <ListItemText
                        sx={{ m: 0 }}
                        primary="Personal contact"
                        secondary="Only visible to you"
                    />
                    <Box sx={{ ml: 'auto' }}>
                        <Switch
                            checked={!props.contact?.public || false}
                            color="success"
                            onChange={() => {
                                updateContact({
                                    ...props.contact,
                                    public: !props.contact?.public,
                                }).then((updated) => {
                                    track('contact_public_toggled', {
                                        contactId: updated.id,
                                        public: updated.public,
                                    });
                                });
                            }}
                        />
                    </Box>
                </Box>
            )}
            <div className={styles['row']}>
                <OptOutIcon
                    size="20"
                    style={{
                        color: colors.superGrey[500],
                    }}
                />
                <ListItemText
                    sx={{ m: 0 }}
                    primary="Opt out"
                    secondary="Will no longer receive messages"
                />
                <Box sx={{ ml: 'auto' }}>
                    <Tooltip
                        title={
                            !isWoOrAdmin &&
                            !membersCanOptOut &&
                            props.contact?.optOut
                                ? 'Only an Admin can opt-in this contact'
                                : ''
                        }
                    >
                        <span>
                            <Switch
                                checked={props.contact?.optOut || false}
                                color="success"
                                onChange={() => {
                                    if (props.contact?.optOut) {
                                        if (isWoOrAdmin || membersCanOptOut) {
                                            confirmOptIn().then(() =>
                                                toogleOptOut(),
                                            );
                                        }
                                    } else {
                                        toogleOptOut();
                                    }
                                }}
                                disabled={optOutDisabled}
                            />
                        </span>
                    </Tooltip>
                </Box>
            </div>
            <ConfirmOptInDialog />
        </CollapsiblePropertiesSection>
    );
};
