import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export const ClockRevertIcon = (props: Exclude<SvgIconProps, 'component'>) => (
    <SvgIcon {...props} viewBox="0 0 24 24" fill="none">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.34687 4.99668C7.89157 3.74843 9.85869 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 11.574 3.34538 11.2286 3.77143 11.2286C4.19748 11.2286 4.54286 11.574 4.54286 12C4.54286 16.1185 7.88153 19.4571 12 19.4571C16.1185 19.4571 19.4571 16.1185 19.4571 12C19.4571 7.88153 16.1185 4.54286 12 4.54286C10.2879 4.54286 8.71103 5.11925 7.45198 6.08973H8.66116C9.08721 6.08973 9.43259 6.43511 9.43259 6.86116C9.43259 7.28721 9.08721 7.63259 8.66116 7.63259H5.57545C5.1494 7.63259 4.80402 7.28721 4.80402 6.86116V3.77545C4.80402 3.3494 5.1494 3.00402 5.57545 3.00402C6.00149 3.00402 6.34687 3.3494 6.34687 3.77545V4.99668ZM12 7.11429C12.426 7.11429 12.7714 7.45967 12.7714 7.88571V11.6805L15.1169 14.0259C15.4182 14.3272 15.4182 14.8156 15.1169 15.1169C14.8156 15.4182 14.3272 15.4182 14.0259 15.1169L11.4545 12.5455C11.3098 12.4008 11.2286 12.2046 11.2286 12V7.88571C11.2286 7.45967 11.574 7.11429 12 7.11429Z"
            fill="currentColor"
        />
    </SvgIcon>
);
