import {
    QueryObserverOptions,
    useMutation,
    useQuery,
    UseQueryOptions,
} from '@tanstack/react-query';
import { queryClient } from './queryClient';
import {
    addUserToInbox,
    archiveAllForInbox,
    checkHostability,
    createBulkForTeams,
    exportInboxLogs,
    getUserInboxAssignments,
    hostInbox,
    listAvailableChannels,
    ListChanneslDto,
    markAllAsReadForInbox,
    portInbox,
    removeInbox,
    removeUserFromInbox,
    reservePhone,
    setupWhatsappInbox,
    verifyVerificationCallCode,
} from '../api/inboxes';
import {
    Conversation,
    Inbox,
    InboxAssignment,
    InboxProvider,
    TeamsPhoneResponse,
} from '../api/types';
import { CURRENT_USER_KEY, TEAMMATES_KEY } from './user';
import { UNREADS_COUNT_KEY } from './messages';
import cloneDeep from 'lodash/cloneDeep';
import difference from 'lodash/difference';
import remove from 'lodash/remove';
import { CONVERSATIONS_LIST_KEY } from './conversations';
import { UUID } from '../types/uuid';
import { useTrack } from '../contexts/analytics';
import { useSocket } from '../websocket';
import { Action } from '../websocket/event-handlers/handle-conversation.updated.event';
import { useEffect } from 'react';
import { PagedData } from '../types/PagedData';
import { client } from '../api/http';

const INBOX_UPDATE_KEY = 'inbox_update';
export const INBOX_LIST_KEY = 'list_inboxes';
const INBOX_ASSIGNMENTS_KEY = 'list_inbox_assignments';
export const LIST_CHANNELS_KEY = 'list_channels';
const INBOX_ASSIGNMENTS_BY_INBOX_KEY = 'list_inbox_assignments_by_inbox';

const initTime = new Date().getTime();
const subscribed: UUID[] = [];

export const useInboxes = (options?: {
    enabled?: boolean;
    select?: QueryObserverOptions<Inbox[]>['select'];
}) => {
    const socket = useSocket().getSocket();
    const query = useQuery({
        queryKey: [INBOX_LIST_KEY],
        queryFn: () => client.get<Inbox[]>('/inbox').then(({ data }) => data),
        refetchOnMount: ({ state }) =>
            state.dataUpdatedAt < initTime ? 'always' : false,
        enabled: options?.enabled ?? true,
        select: options?.select,
    });

    useEffect(() => {
        const fresh = query.data?.map(({ id }) => id) ?? [];
        const unsubscribe = difference(subscribed, fresh);
        const subscribe = difference(fresh, subscribed);
        if (!socket) {
            return;
        }
        subscribe.forEach((id) => {
            socket.emit('inboxes', {
                id,
                act: Action.Subscribe,
            });
            subscribed.push(id);
        });
        unsubscribe.forEach((id) => {
            socket.emit('inboxes', { id, act: Action.Unsubscribe });
            remove(subscribed, id);
        });
    }, [query.data, socket]);

    return query;
};

export const useInboxesQueryData = () =>
    queryClient.getQueryData<Inbox[]>([INBOX_LIST_KEY]);

export const useInboxQueryData = (id: string) =>
    queryClient
        .getQueryData<Inbox[]>([INBOX_LIST_KEY])
        ?.find((i) => i.id === id);

export const useInboxAssignmentsForUser = (id: string) =>
    useQuery({
        queryKey: [INBOX_ASSIGNMENTS_KEY],
        queryFn: getUserInboxAssignments,
        select: (a) => a.filter((i) => i.userId === id),
    });

export const useInboxAssignmentsForInbox = (id: string) =>
    useQuery({
        queryKey: [INBOX_ASSIGNMENTS_BY_INBOX_KEY, id],
        queryFn: getUserInboxAssignments,
        select: (a) => a.filter((i) => i.inboxId === id),
    });

export const useInboxAssignmentsForInboxes = (ids: string[]) =>
    useQuery({
        queryKey: [INBOX_ASSIGNMENTS_BY_INBOX_KEY, [...ids].sort().join(',')],
        queryFn: getUserInboxAssignments,
        select: (a) => a.filter((i) => ids.includes(i.inboxId)),
    });

export const useInboxExport = () =>
    useMutation({
        mutationKey: ['inbox_export'],
        mutationFn: exportInboxLogs,
    });

export const useReservePhone = () => {
    const track = useTrack();

    return useMutation({
        mutationKey: ['reservePhone'],
        mutationFn: reservePhone,
        onSuccess: (createdInbox) => {
            track('onboarding_provisioned_phone_number', {
                phone: createdInbox.phone,
                provider: createdInbox.provider,
            });
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: [CURRENT_USER_KEY] });
            queryClient.invalidateQueries({ queryKey: [TEAMMATES_KEY] });
            queryClient.invalidateQueries({ queryKey: [INBOX_LIST_KEY] });
            queryClient.refetchQueries({ queryKey: [INBOX_LIST_KEY] });
        },
    });
};

export const useMarkAllAsReadForInbox = () => {
    const track = useTrack();

    return useMutation({
        mutationFn: markAllAsReadForInbox,
        onSuccess: (_, { inboxId }) => {
            track('inbox_all_read_marked', { inboxId });
            queryClient.invalidateQueries({ queryKey: [UNREADS_COUNT_KEY] });
            queryClient.setQueriesData<PagedData<Conversation>>(
                {
                    queryKey: [CONVERSATIONS_LIST_KEY, inboxId],
                    exact: false,
                },
                (prev) => {
                    if (!prev) {
                        return;
                    }
                    return {
                        ...prev,
                        pages: prev.pages.map((page) =>
                            page.map((conv) => ({
                                ...conv,
                                hasUnread: false,
                                unreadMessageCount: 0,
                            })),
                        ),
                    };
                },
            );
        },
    });
};

export const useArchiveAllForInbox = () => {
    const track = useTrack();

    return useMutation({
        mutationFn: archiveAllForInbox,
        onSuccess: (_, { inboxId }) => {
            track('inbox_archive_all', { inboxId });
            queryClient.invalidateQueries({
                queryKey: [CONVERSATIONS_LIST_KEY],
            });
        },
    });
};

export const useAddUserToInbox = () => {
    const track = useTrack();

    return useMutation({
        mutationKey: [INBOX_UPDATE_KEY],
        mutationFn: addUserToInbox,
        onMutate: (data) => {
            queryClient.setQueryData<InboxAssignment[]>(
                [INBOX_ASSIGNMENTS_KEY],
                (staleData = []): InboxAssignment[] => [...staleData, data],
            );
            return data;
        },
        onError: (error, __, context) => {
            // eslint-disable-next-line no-console
            console.warn(error);
            if (context) {
                queryClient.setQueryData<InboxAssignment[]>(
                    [INBOX_ASSIGNMENTS_KEY],
                    (staleData = []): InboxAssignment[] =>
                        staleData.filter(
                            (a) =>
                                a.inboxId !== context.inboxId &&
                                a.userId !== context.userId,
                        ),
                );
            }
        },
        onSuccess: () => track('inbox_assigned'),
    });
};

export const useRemoveUserFromInbox = () => {
    const track = useTrack();

    return useMutation<
        InboxAssignment,
        unknown,
        InboxAssignment,
        InboxAssignment
    >({
        mutationKey: [INBOX_UPDATE_KEY],
        mutationFn: removeUserFromInbox,
        onMutate: (data) => {
            queryClient.setQueryData<InboxAssignment[]>(
                [INBOX_ASSIGNMENTS_KEY],
                (staleData = []) =>
                    staleData.filter(
                        (a) =>
                            a.inboxId !== data.inboxId ||
                            a.userId !== data.userId,
                    ),
            );
            return data;
        },
        onError: (error, __, context) => {
            // eslint-disable-next-line no-console
            console.warn(error);
            if (context) {
                queryClient.setQueryData<InboxAssignment[]>(
                    [INBOX_ASSIGNMENTS_KEY],
                    (staleData = []): InboxAssignment[] => [
                        ...staleData,
                        context,
                    ],
                );
            }
        },
        onSuccess: () => track('inbox_unassigned'),
    });
};

export const useInboxUpdateQuery = () => {
    const track = useTrack();

    return useMutation({
        mutationKey: [INBOX_UPDATE_KEY],
        mutationFn: (inbox: Partial<Inbox>) =>
            client
                .put<Inbox>(`/inbox/${inbox.id}`, inbox)
                .then(({ data }) => data),
        onMutate: (inbox: Partial<Inbox>) => {
            queryClient.setQueryData<Inbox[]>(
                [INBOX_LIST_KEY],
                (inboxes = []) => {
                    const idx = inboxes?.findIndex((i) => i.id === inbox.id);

                    if (idx > -1) {
                        const newData = cloneDeep(inboxes);
                        newData[idx] = { ...inboxes[idx], ...inbox };
                        return newData;
                    }
                    return inboxes;
                },
            );

            return inbox;
        },
        onSuccess: ({ id }: Inbox) => {
            track('inbox_updated', { inboxId: id });
        },
        onError: (_err, __, context) => {
            if (context) {
                queryClient.setQueryData<Inbox[]>(
                    [INBOX_LIST_KEY],
                    (inboxes = []) => {
                        const idx = inboxes?.findIndex(
                            (i) => i.id === context.id,
                        );
                        if (idx > -1) {
                            const newData = cloneDeep(inboxes);
                            newData[idx] = { ...inboxes[idx], ...context };
                            return newData;
                        }
                        return inboxes;
                    },
                );
            }
        },
    });
};

export const useDeleteInbox = () =>
    useMutation({
        mutationKey: ['delete_inbox'],
        mutationFn: removeInbox,
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: [INBOX_LIST_KEY] });
        },
    });

export const useRemoveCallForwarding = () =>
    useMutation({
        mutationKey: [INBOX_UPDATE_KEY],
        mutationFn: (inboxId: string) =>
            client
                .put<Inbox>(`/inbox/${inboxId}/remove-call-forwarding`, {})
                .then(({ data }) => data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [INBOX_LIST_KEY] });
        },
    });

const defaultListChannelsOptions = {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retry: false,
    initialData: [],
};

export function useListChannelsQuery(
    dto: ListChanneslDto,
    isEnabled: boolean,
    options: UseQueryOptions<string[]> = defaultListChannelsOptions,
) {
    return useQuery({
        ...options,
        // eslint-disable-next-line @tanstack/query/exhaustive-deps
        queryKey: [LIST_CHANNELS_KEY, dto.provider],
        queryFn: () => listAvailableChannels(dto),
        enabled: isEnabled,
    });
}

export function useListChannelsQueryData(provider: InboxProvider) {
    return (
        queryClient.getQueryData<string[]>([LIST_CHANNELS_KEY, provider]) || []
    );
}

export function useCheckHostabilityQuery(phones: string[], enabled = true) {
    return useQuery<{ hostable: boolean }, Error, boolean>({
        queryKey: ['check_hostability', phones],
        queryFn: () => checkHostability({ phones }),
        select: (data) => data?.hostable,
        enabled,
    });
}

export function useCheckHostabilityQueryData(phones: string[]) {
    return queryClient.getQueryData<{ hostable: boolean }>([
        'check_hostability',
        phones,
    ]);
}

export function useHostInboxMutation(inboxId: string) {
    return useMutation({
        mutationKey: ['host', inboxId],
        mutationFn: () => hostInbox({ inboxId }),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [INBOX_LIST_KEY] });
            queryClient.refetchQueries({ queryKey: [INBOX_LIST_KEY] });
        },
    });
}

export function usePortInboxMutation(inboxId: string) {
    return useMutation({
        mutationKey: ['host', inboxId],
        mutationFn: () => portInbox({ inboxId }),
    });
}

export function useVerificationCallCodeMutation(phone: string) {
    return useMutation({
        mutationKey: ['verification_call_code', phone],
        mutationFn: ({ code }: { code: string }) =>
            verifyVerificationCallCode({ phone, code }),
    });
}

export function useBulkCreateMutation() {
    return useMutation({
        mutationKey: ['create_bulk'],
        mutationFn: (payload: TeamsPhoneResponse) =>
            createBulkForTeams(payload),
        onSuccess: () => {
            queryClient.refetchQueries({ queryKey: [INBOX_LIST_KEY] });
        },
    });
}

export const useInbox = (inboxId: UUID) => {
    const inboxes = useInboxes();
    const inbox = inboxes.data?.find((inbox) => inbox.id === inboxId);
    return { ...inboxes, data: inbox };
};

export const useCarrierCheck = (phones: string[], carrier: string) => {
    return useQuery<boolean>({
        queryKey: ['carrier_check', phones, carrier],
        queryFn: () =>
            client
                .post<{ match: boolean }>(
                    '/inbox-hosting/phones-not-matching-carrier',
                    {
                        phones,
                        carrier,
                    },
                )
                .then(({ data }) => data?.match ?? false),
    });
};

export const useSetupWhatsappInbox = () => {
    const track = useTrack();

    return useMutation({
        mutationKey: ['setupWhatsappInbox'],
        mutationFn: setupWhatsappInbox,
        onSuccess: (createdInbox) => {
            track('onboarding_provisioned_phone_number', {
                phone: createdInbox.phone,
                provider: createdInbox.provider,
            });
            queryClient.invalidateQueries({ queryKey: [INBOX_LIST_KEY] });
            queryClient.refetchQueries({ queryKey: [INBOX_LIST_KEY] });
        },
    });
};
