import { useEffect } from 'react';
import { TextSerializer } from './serializers/text';
import { insertSnippet } from '../MessageInput/components/Toolbar/Snippet/insertSnippet';
import { compareValues, insertNodes } from './utils';
import {
    CustomEditor,
    DeserializeOptions,
    SerializerMiddleware,
} from './slate';
import { Descendant } from 'slate';

export function useNormalize({
    editor,
    initialValue,
    value,
    serializers,
    deserializerOptions,
}: {
    editor: CustomEditor;
    value: Descendant[];
    initialValue?: string | Descendant[];
    serializers?: SerializerMiddleware[];
    deserializerOptions?: DeserializeOptions;
}) {
    useEffect(() => {
        if (!initialValue) {
            return;
        }

        if (
            typeof initialValue === 'string' &&
            initialValue !== TextSerializer?.serialize(value, serializers)
        ) {
            insertSnippet(editor, initialValue, deserializerOptions);
            return;
        }

        if (
            Array.isArray(initialValue) &&
            !compareValues(value, initialValue)
        ) {
            insertNodes(editor, initialValue, true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialValue, editor, deserializerOptions]);
}
