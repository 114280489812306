import {
    Components,
    listItemIconClasses,
    selectClasses,
    Theme,
} from '@mui/material';
import { borderRadius } from './border-radius';
import { UnfoldMoreOutlined } from '../icons/common/UnfoldMoreOutlined';

export const MuiSelect: Components<Theme>['MuiSelect'] = {
    defaultProps: {
        variant: 'outlined',
        IconComponent: UnfoldMoreOutlined,
    },
    styleOverrides: {
        root: ({ theme }) => ({
            ...theme.typography.body4,
            color: theme.palette.primary.dark,
            border: 'none',
            borderRadius,

            '&::before, &::after': {
                display: 'none',
            },

            '&:focus-visible': {
                outline: '1px solid',
                outlineColor: theme.palette.info.main,
            },

            '&:hover:not(.Mui-disabled)': {
                '.MuiSelect-standard': {
                    color: theme.palette.info.main,
                },
                '.MuiSelect-iconStandard': {
                    color: theme.palette.info.main,
                },
            },
        }),
        outlined: ({ theme }) => ({
            display: 'flex',
            padding: theme.spacing(0, 3),
            alignItems: 'center',

            [`& .${listItemIconClasses.root}`]: {
                marginRight: theme.spacing(2),
                minWidth: 20,
                color: theme.palette.custom.gray.super,

                '& svg': {
                    width: 20,
                },
            },
        }),
        icon: ({ theme }) => ({
            width: 20,
            marginRight: 5,
            color: 'inherit',

            [`&.${selectClasses.iconOutlined}`]: {
                color: theme.palette.custom.gray.super,
                transform: 'none',
                top: 'initial',
            },
        }),
        standard: {
            '&:focus': {
                backgroundColor: 'inherit',
            },
        },
    },
};
