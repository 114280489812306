import { ChangeEvent, useState } from 'react';
import { ClerkPermission, Inbox, Profile } from '../../../api/types';
import { Header } from '../Header';
import {
    Chip,
    Divider,
    InputAdornment,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import { SearchFilledIcon } from '../../../icons/common/SearchFilledIcon';
import { NavLink } from 'react-router-dom';
import { useInboxAssignmentsForInbox } from '../../../queries/inboxes';
import { useTeammates } from '../../../queries/user';
import { UserAvatar } from '../../../components/UserAvatar/UserAvatar';
import { getName } from '../../../main/campaign/v3/get-profile-name';
import { roleLabels } from '../../../main/settings/Members/utils';
import { WithPermission } from '../../WithPermission/WithPermission';
import isEmpty from 'lodash/isEmpty';

type Props = {
    inbox: Inbox;
};

export function Members({ inbox }: Props) {
    const [query, setQuery] = useState('');
    const { data: assigned = [] } = useInboxAssignmentsForInbox(inbox.id);
    const { data: teammates = [] } = useTeammates({
        select: (profiles: Profile[]): Profile[] =>
            profiles.filter((profile) => {
                if (!assigned.some(({ userId }) => userId === profile.id)) {
                    return false;
                }

                if (!query.trim().length) {
                    return true;
                }

                return [
                    `${profile.email}`.trim().toLowerCase(),
                    `${profile.firstname || ''} ${profile.lastname || ''}`
                        .trim()
                        .toLowerCase(),
                    `${profile.lastname || ''} ${profile.firstname || ''}`
                        .trim()
                        .toLowerCase(),
                    `${profile.phone || ''}`.trim().toLowerCase(),
                ].some((property) =>
                    property.includes(query.trim().toLowerCase()),
                );
            }),
    });

    return (
        <>
            <Header
                title="Members"
                description="List of team members assigned to the phone number"
            />
            <Divider />
            <Stack flex={1} gap={6.5}>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <TextField
                        size="small"
                        value={query}
                        onChange={({
                            target,
                        }: ChangeEvent<
                            HTMLInputElement | HTMLTextAreaElement
                        >) => setQuery(target?.value ?? '')}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchFilledIcon />
                                </InputAdornment>
                            ),
                        }}
                        placeholder="Search members"
                        variant="outlined"
                        sx={{ width: { xs: 1, sm: 'auto', md: 256 } }}
                    />
                    <WithPermission
                        requiredPermissions={[
                            ClerkPermission.ModifyPrivateInbox,
                            ClerkPermission.ModifyPublicInbox,
                        ]}
                    >
                        <NavLink
                            to="/settings/members"
                            aria-label="Manage members link"
                        >
                            {'Manage members ->'}
                        </NavLink>
                    </WithPermission>
                </Stack>
                {isEmpty(teammates) ? (
                    <Typography variant="body3" color="text.secondary">
                        {query.trim().length > 0
                            ? 'No results found. Please try a different search.'
                            : 'No team members assigned to this inbox'}
                    </Typography>
                ) : (
                    <Stack gap={4}>
                        {teammates.map((user) => (
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                                key={user.id}
                                aria-label="Member row"
                            >
                                <Stack direction="row" gap={2}>
                                    <UserAvatar
                                        key={user.id}
                                        size={20}
                                        imageUrl={user.avatar?.url}
                                        alt={getName(user)}
                                        username={getName(user)}
                                    />
                                    <Typography
                                        data-testid="inbox-assigned-name"
                                        variant="body3"
                                        aria-label="Member name"
                                    >
                                        {getName(user)}
                                    </Typography>
                                </Stack>
                                <Chip
                                    size="small"
                                    label={
                                        user.role?.type
                                            ? roleLabels[user.role?.type]
                                            : 'No assigned role'
                                    }
                                    aria-label="Member role"
                                />
                            </Stack>
                        ))}
                    </Stack>
                )}
            </Stack>
        </>
    );
}
