import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export const ClerkLogoIcon = (props: Exclude<SvgIconProps, 'component'>) => (
    <SvgIcon inheritViewBox {...props}>
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_764_107879)">
                <circle
                    cx="12"
                    cy="12"
                    r="12"
                    fill="url(#paint0_linear_764_107879)"
                />
                <mask
                    id="mask0_764_107879"
                    style={{ maskType: 'alpha' }}
                    maskUnits="userSpaceOnUse"
                    x="4"
                    y="3"
                    width="14"
                    height="18"
                >
                    <path
                        d="M4.3125 3.46875H17.2031V20.5312H4.3125V3.46875Z"
                        fill="white"
                    />
                </mask>
                <g mask="url(#mask0_764_107879)">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M4.31287 11.9546C4.33067 7.72869 7.25175 4.29525 11.3714 3.56939C13.1723 3.25211 14.7854 3.72084 16.3735 4.4736C16.7883 4.6702 16.8009 4.86953 16.4499 5.13477C16.4115 5.16385 16.2223 5.32034 16.1848 5.3508C15.5439 5.87057 15.3066 6.14484 14.3525 5.79795C11.6983 4.833 8.75697 5.99755 7.23901 8.41638C5.65542 10.9398 5.9203 14.3003 7.87322 16.4623C9.81664 18.6138 12.8215 19.2072 15.3329 17.8703C15.9036 17.5665 16.1185 17.66 16.6502 18.0867C16.8497 18.2467 16.8951 18.3265 17.007 18.4525C17.28 18.7599 17.2865 18.9832 16.9087 19.2354C13.6452 21.4141 9.06986 20.7867 6.47904 17.7962C5.02665 16.1198 4.29357 14.1786 4.31287 11.9546Z"
                        fill="white"
                    />
                </g>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14.9112 6.81749C14.3932 7.2902 13.9518 7.66876 13.5411 8.07798C13.333 8.28535 13.1138 8.24675 12.8698 8.23382C10.1641 8.09064 8.16156 10.6182 9.07222 13.1404C9.32736 13.8471 9.27205 14.3759 8.9553 14.9737C8.79991 15.267 8.67641 15.5769 8.53228 15.8929C6.70163 14.229 6.43924 11.0029 7.91673 8.75502C9.41248 6.47932 12.2477 5.6365 14.9112 6.81749Z"
                    fill="white"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15.4081 16.8661C13.1379 18.2343 10.284 17.9195 8.61829 16.1329C9.22135 15.9013 9.8132 15.6965 10.3833 15.4455C10.6621 15.3227 10.8551 15.456 11.0744 15.5429C11.8798 15.8623 12.7051 15.913 13.5242 15.6393C13.875 15.522 14.1053 15.5491 14.3517 15.8235C14.6724 16.1807 15.0356 16.5023 15.4081 16.8661Z"
                    fill="white"
                />
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear_764_107879"
                    x1="22.2891"
                    y1="22.7812"
                    x2="8.27344"
                    y2="6.25781"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#3538CD" />
                    <stop offset="1" stopColor="#7577F5" />
                </linearGradient>
                <clipPath id="clip0_764_107879">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    </SvgIcon>
);
