import { useEffect, useState } from 'react';
import { Contact } from '../../api/types';
import { CONTACT_BY_PHONE_KEY } from '../../components/Contacts/use-contact-by-phone';
import { aggregator } from '../../components/Contacts/LookupAggregator/phone-lookup-aggregator';
import { queryClient } from '../../queries/queryClient';
import { useMeQueryData } from '../../queries/user';
import { UUID } from '../../types/uuid';
import { getContactName } from '../../utils/contacts';
import { useSocket } from '../../websocket';

export type UserTyping = {
    userId?: string;
    name: string;
    conversationId: string;
};

export function useUserTyping(conversationId: UUID): string {
    const me = useMeQueryData()?.id;
    const [userTyping, setUserTyping] = useState<string>('');
    const connection = useSocket().getSocket();

    useEffect(() => {
        let timeout: number;

        const onTyping = (data: UserTyping) => {
            if (data.userId !== me && data.conversationId === conversationId) {
                setUserTyping(data.name);
                clearTimeout(timeout);
                timeout = window.setTimeout(() => {
                    setUserTyping('');
                }, 5001);
            }
        };

        if (connection) {
            connection.on('typing', onTyping);
        }

        return () => {
            if (connection) {
                connection.off('typing', onTyping);
            }
        };
    }, [conversationId, me, connection]);

    return userTyping;
}

function getContact(phone: string): Promise<Contact> {
    return (
        queryClient.getQueryData<Contact>([CONTACT_BY_PHONE_KEY, phone]) ||
        aggregator.getContact(phone)
    );
}

async function getDisplayName(phone: string): string {
    const contact = await getContact(phone);
    return getContactName(contact);
}

export function useRcsTyping(conversationId: UUID): string {
    const me = useMeQueryData()?.id;
    const [rcsTyping, setRcsTyping] = useState<string>('');
    const connection = useSocket().getSocket();

    useEffect(() => {
        let timeout: number;

        const onTyping = async (data: UserTyping) => {
            if (data.conversationId === conversationId) {
                const name = await getDisplayName(data.name);
                setRcsTyping(name);
                clearTimeout(timeout);
                timeout = window.setTimeout(() => {
                    setRcsTyping('');
                }, 5001);
            }
        };

        if (connection) {
            connection.on('conversation:rcs:typing', onTyping);
        }

        return () => {
            if (connection) {
                connection.off('conversation:rcs:typing', onTyping);
            }
        };
    }, [conversationId, me, connection]);

    return rcsTyping;
}
