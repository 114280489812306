import { Chip, SxProps, Theme } from '@mui/material';
import { RenderElementProps, useSlate } from 'slate-react';
import { memo } from 'react';
import { VariableElement } from '../slate';
import { removeNode } from '../utils';

const styles: SxProps<Theme> = {
    userSelect: 'none',
    margin: (theme) => theme.spacing(0, 0.5, 0.5),
};

export type VariableProps = RenderElementProps & {
    element: VariableElement;
};

export const Variable = memo(function Variable(props: VariableProps) {
    const editor = useSlate();
    const handleDelete = () => {
        removeNode(editor, props.element);
    };

    return (
        <>
            <Chip
                {...props.attributes}
                sx={styles}
                size="small"
                color="warning"
                variant="outlined"
                label={props.element.variable}
                onDelete={handleDelete}
            />
            {props.children}
        </>
    );
});
