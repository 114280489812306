import { NavLink } from 'react-router-dom';
import { Inbox, InboxStatus } from '../../api/types';
import { Box, ListItemText, Stack, SxProps, Typography } from '@mui/material';
import { renderInboxIcon } from '../../utils/inboxes';
import { MuteIcon } from '../../components/Inbox/CMuteIcon';
import { getSquare } from '../../theme/style.helpers';
import { useMeQueryData } from '../../queries/user';
import { useMenu } from '../../hooks/use-menu';
import { inboxItem, inboxWithMenu } from './styles';
import { Indicators } from './Indicators';
import { ContextMenuButton } from './ContextMenuButton';
import { getPath } from './utils';
import { InboxContextMenu } from '../../components/Inbox/InboxContextMenu';
import { focusVisible } from '../../theme/focusVisible';
import { PhoneNumber } from '../../components/PhoneNumber/PhoneNumber';

interface Props {
    inbox: Inbox;
    onClick?: () => void;
    sx?: SxProps;
    isPending?: boolean;
    disableMenus?: boolean;
}

const INBOX_NAME_PLACEHOLDER = 'Phone';

const InboxItem = ({
    inbox,
    onClick,
    sx = inboxItem,
    disableMenus = false,
}: Props) => {
    const currentUser = useMeQueryData();
    const menu = useMenu();
    const { icon, provider, phone = '', name = INBOX_NAME_PLACEHOLDER } = inbox;
    const isMuted = currentUser?.mutedInboxIds?.includes(inbox.id);

    const inboxName = (
        <Typography
            data-testid="inbox-li-name"
            variant="body3"
            color="primary.dark"
        >
            {name || INBOX_NAME_PLACEHOLDER}
        </Typography>
    );

    const url = getPath(inbox);

    return (
        <>
            <Box
                component={url ? NavLink : 'div'}
                sx={[
                    sx,
                    !disableMenus &&
                        inbox.status === InboxStatus.ACTIVE &&
                        inboxWithMenu,
                    {
                        '&:focus-visible': {
                            ...focusVisible,
                            outlineOffset: '0px',
                        },
                    },
                ]}
                tabIndex={0}
                data-navigation-element
                onClick={() => {
                    onClick?.();
                }}
                to={url}
            >
                <Box sx={{ fontSize: 20, ml: 2, mr: 2, p: 1 }}>
                    {renderInboxIcon({ icon, provider })}
                </Box>
                <ListItemText
                    sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        paddingRight: 4,
                    }}
                    data-testid="inbox-item"
                    primary={
                        isMuted ? (
                            <Stack
                                direction="row"
                                alignItems="center"
                                spacing={1}
                            >
                                <Box
                                    sx={{
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                    }}
                                >
                                    {inboxName}
                                </Box>
                                <Box component="span" display="inline-flex">
                                    <MuteIcon
                                        sx={{
                                            ...getSquare(12),
                                            color: 'custom.gray.super',
                                        }}
                                    />
                                </Box>
                            </Stack>
                        ) : (
                            inboxName
                        )
                    }
                    secondary={
                        <PhoneNumber
                            phone={phone}
                            variant="body4"
                            styles={{
                                'data-testid': 'inbox-li-phone',
                                color: 'custom.text.secondary',
                                '&:hover': {
                                    fontWeight: 600,
                                },
                            }}
                        />
                    }
                    secondaryTypographyProps={{
                        // @ts-ignore
                        'data-testid': 'inbox-li-phone',
                        sx: {
                            mt: 0,
                        },
                    }}
                />

                {!disableMenus && inbox.status === InboxStatus.ACTIVE && (
                    <ContextMenuButton inboxId={inbox.id} menu={menu} />
                )}
                <Indicators inbox={inbox} isHidden={menu.open} />
            </Box>
            {!disableMenus && (
                <InboxContextMenu inboxId={inbox.id} menu={menu} />
            )}
        </>
    );
};

export default InboxItem;
