import { useCallback, useEffect, memo } from 'react';
import { Box, IconButton, Tooltip } from '@mui/material';
import { AttachmentIcon } from '../../../../../icons/common/CAttachmentIcon';
import * as styles from './styles';
import { CustomEditor } from '../../../../Slate/slate';
import { acceptFileFormats } from '../../../../../constants';
import {
    ToastActions,
    useToastContext,
} from '../../../../../containers/toast/reducer';

export const MAX_FILE_SIZE_MB = 25;
export const MAX_FILE_SIZE = MAX_FILE_SIZE_MB * 1024 * 1024;
import { focusVisible } from '../../../../../theme/focusVisible';

type Props = {
    disabled?: boolean;
    uploadRef: React.RefObject<HTMLInputElement | null>;
    uploadFile: (file: File) => void;
    editor: CustomEditor;
};

export const FileUploaderButton = memo(function FileUploaderButton({
    disabled,
    uploadFile,
    uploadRef,
    editor,
}: Props) {
    const { dispatch: toastDispatch } = useToastContext();

    const onFileUpload = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const target = e.target as HTMLInputElement;
            const oversizedFiles: string[] = [];

            if (!target.files) {
                return;
            }

            Array.from(target.files).forEach((file) => {
                if (file.size > MAX_FILE_SIZE) {
                    oversizedFiles.push(file.name);
                } else {
                    uploadFile(file);
                }

                if (target.value) {
                    // @ts-ignore reset input to prevent stuck
                    target.value = '';
                }
            });

            if (oversizedFiles.length > 0) {
                toastDispatch({
                    type: ToastActions.ADD,
                    payload: {
                        id: 'file-size-exceeded',
                        title: 'File size exceeded',
                        description: `The following files exceed ${MAX_FILE_SIZE_MB} MB: ${oversizedFiles.join(', ')}`,
                        severity: 'error',
                    },
                });
            }
        },
        [toastDispatch, uploadFile],
    );

    const handleSelectFile = useCallback(() => {
        if (disabled) {
            return;
        }

        if (uploadRef.current) {
            uploadRef.current.click();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [disabled]);

    useEffect(() => {
        const { insertData } = editor;
        editor.insertData = (data) => {
            if (data?.files.length) {
                Array.from(data.files).forEach((file: File) => {
                    uploadFile(file);
                });
                return;
            }

            insertData(data);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <Box
                component="input"
                sx={styles.fileInput}
                ref={uploadRef}
                type="file"
                accept={acceptFileFormats}
                multiple
                onChange={onFileUpload}
            />
            <Tooltip title="Attach file" arrow placement="top">
                <span>
                    <IconButton
                        color="primary"
                        onClick={handleSelectFile}
                        disabled={disabled}
                        data-testid="upload-file-button"
                        aria-label="Attach file"
                        data-navigation-element
                        sx={{
                            '&:focus-visible': {
                                ...focusVisible,
                                outlineOffset: '2px',
                            },
                        }}
                    >
                        <AttachmentIcon />
                    </IconButton>
                </span>
            </Tooltip>
        </div>
    );
});
