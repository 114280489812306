import { CustomEditor, DeserializeOptions } from '../../../../Slate/slate';
import { insertNodes } from '../../../../Slate/utils';
import { TextSerializer } from '../../../../Slate/serializers/text';

export const insertSnippet = (
    editor: CustomEditor,
    snippetString: string,
    deserializeOptions?: DeserializeOptions,
) => {
    const nodes = TextSerializer.deserialize(snippetString, deserializeOptions);
    if (nodes.length) {
        insertNodes(editor, nodes, true);
    }
};
