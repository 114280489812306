import { TextField } from '@mui/material';
import { SettingsRow } from '../SettingsRow';
import { OutOfOfficeInputs } from './constants';
import { useFormContext } from 'react-hook-form';

export function MessageRow({ disabled = false }: { disabled?: boolean }) {
    const { register, formState } = useFormContext<OutOfOfficeInputs>();

    return (
        <SettingsRow label="Message" alignItems="start">
            <TextField
                {...register('message')}
                placeholder="Message will send when outside the working schedule"
                multiline
                fullWidth
                sx={{
                    height: 88,
                    width: {
                        xs: 1,
                        md: 320,
                    },
                }}
                disabled={disabled}
                error={Boolean(formState.errors.message)}
                helperText={formState.errors.message?.message}
                inputProps={{
                    'aria-label': 'Out of office message',
                }}
            />
        </SettingsRow>
    );
}
