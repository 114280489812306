import range from 'lodash/range';

import { Skeleton, Stack } from '@mui/material';

export const InboxListSkeleton = () => {
    return (
        <Stack p={2} gap={1}>
            {range(5).map((i) => (
                <Skeleton
                    variant="rectangular"
                    key={i}
                    height={56}
                    sx={{ bgcolor: 'custom.action.disabledBackground' }}
                />
            ))}
        </Stack>
    );
};
