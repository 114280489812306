import { CustomEditor } from '../../../../Slate/slate';
import { BaseRange, Transforms } from 'slate';
import uniq from 'lodash/uniq';

let emojiShortcodesCache: Record<string, string> | null = null;

export const fetchEmojiShortcodes = async () => {
    if (emojiShortcodesCache) {
        return emojiShortcodesCache;
    }

    const [emojis, shortcodes] = await Promise.all([
        import('emojibase-data/en/compact.json'),
        import('emojibase-data/en/shortcodes/emojibase.json'),
    ]);

    emojiShortcodesCache = Object.keys(shortcodes.default)
        .filter((key) => !key.includes('-'))
        .reduce<Record<string, string>>((acc, key) => {
            const item = shortcodes.default[key];
            const unicode = emojis.default.find(
                (emoji) => emoji.hexcode === key,
            )?.unicode;

            if (typeof item === 'string') {
                return {
                    ...acc,
                    [item]: unicode,
                };
            }
            if (Array.isArray(item)) {
                return {
                    ...acc,
                    ...item.reduce(
                        (acc2, it) => ({
                            ...acc2,
                            [it]: unicode,
                        }),
                        acc,
                    ),
                };
            }

            return acc;
        }, {});

    return emojiShortcodesCache;
};

export const findEmoji = (text: string, shortcodes: Record<string, string>) => {
    if (text.length < 2) {
        return [];
    }

    return uniq(
        Object.keys(shortcodes).filter((shortcode) =>
            shortcode.startsWith(text),
        ),
    );
};

export function insertEmoji(
    editor: CustomEditor,
    targetRange: BaseRange,
    emoji: string,
) {
    Transforms.setSelection(editor, {
        focus: targetRange.anchor,
    });

    Transforms.insertText(editor, emoji);

    Transforms.move(editor);
}
