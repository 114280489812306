import { SvgIcon } from '@mui/material';
import { ClerkIconProps } from '../../types/clerk-icon.props';

export const Activity = (props: ClerkIconProps) => (
    <SvgIcon inheritViewBox {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.0417 2.5C11.0417 2.15482 11.3215 1.875 11.6667 1.875H14.1667C14.5118 1.875 14.7917 2.15482 14.7917 2.5C14.7917 2.84518 14.5118 3.125 14.1667 3.125H12.2917V4.375H14.3601C15.0375 4.37499 15.5838 4.37499 16.0262 4.41114C16.4818 4.44835 16.8819 4.52699 17.2521 4.7156C17.8401 5.01521 18.3181 5.49327 18.6177 6.08128C18.8063 6.45146 18.885 6.85158 18.9222 7.30711C18.9583 7.74953 18.9583 8.29587 18.9583 8.97323V15C18.9583 15.3452 18.6785 15.625 18.3333 15.625H12.2917V17.5C12.2917 17.8452 12.0118 18.125 11.6667 18.125H8.33333C7.98816 18.125 7.70833 17.8452 7.70833 17.5V15.625H2.5C2.15482 15.625 1.875 15.3452 1.875 15V8.33333C1.875 6.14721 3.64721 4.375 5.83333 4.375H11.0417V2.5ZM11.0417 5.625H8.7201C9.38464 6.33304 9.79167 7.28566 9.79167 8.33333V14.375H17.7083V9C17.7083 8.28962 17.7078 7.79443 17.6763 7.4089C17.6454 7.03067 17.5878 6.81337 17.504 6.64877C17.3242 6.29596 17.0374 6.00913 16.6846 5.82936C16.52 5.7455 16.3027 5.68789 15.9244 5.65698C15.5389 5.62549 15.0437 5.625 14.3333 5.625H12.2917V10C12.2917 10.3452 12.0118 10.625 11.6667 10.625C11.3215 10.625 11.0417 10.3452 11.0417 10V5.625ZM11.0417 15.625H8.95833V16.875H11.0417V15.625ZM8.54167 14.375V8.33333C8.54167 6.83756 7.3291 5.625 5.83333 5.625C4.33756 5.625 3.125 6.83756 3.125 8.33333V14.375H8.54167ZM5.20833 10C5.20833 9.65482 5.48816 9.375 5.83333 9.375H5.84167C6.18685 9.375 6.46667 9.65482 6.46667 10C6.46667 10.3452 6.18685 10.625 5.84167 10.625H5.83333C5.48816 10.625 5.20833 10.3452 5.20833 10Z"
                fill="currentColor"
            />
        </svg>
    </SvgIcon>
);
