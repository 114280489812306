import { Avatar } from '@mui/material';
import { ClerkLogoIcon } from '../../ContactProperty/SourceIcon/ClerkLogoIcon';
import { getSquare } from '../../../theme/style.helpers';

export const AssistantAvatar = () => (
    <Avatar
        alt="AI Assistant"
        sx={{
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: 'grey.200',
        }}
    >
        <ClerkLogoIcon sx={getSquare(40)} />
    </Avatar>
);
