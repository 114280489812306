import { SvgIcon } from '@mui/material';
import { ClerkIconProps } from '../../types/clerk-icon.props';

export const UnfoldMoreOutlined = (props: ClerkIconProps) => (
    <SvgIcon inheritViewBox {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.6535 4.44295L13.0582 6.88968C13.6431 7.48484 13.2346 8.5051 12.4082 8.5051H7.59892C6.7726 8.5051 6.3548 7.48484 6.93972 6.88968L9.34439 4.44295C9.70648 4.07452 10.2914 4.07452 10.6535 4.44295ZM9.34667 15.5569L6.94201 13.1102C6.35709 12.515 6.76561 11.4947 7.59192 11.4947H12.4012C13.2276 11.4947 13.6454 12.515 13.0604 13.1102L10.6558 15.5569C10.2937 15.9253 9.70877 15.9253 9.34667 15.5569Z"
                fill="currentColor"
            />
        </svg>
    </SvgIcon>
);
