import { Chip } from '@mui/material';
import { SourceIcon } from './SourceIcon';
import { useState } from 'react';
import { DeleteSourceItemModal } from './DeleteSourceItemModal';
import { Source } from '../../../../queries/source';

export const SourceItem = ({ source }: { source: Source }) => {
    const [open, setOpen] = useState(false);
    const close = () => setOpen(false);

    return (
        <>
            <Chip
                variant="outlined"
                icon={<SourceIcon type={source.type} />}
                label={source.title}
                onDelete={() => setOpen(true)}
                sx={{
                    maxWidth: 230,
                }}
            />
            <DeleteSourceItemModal
                open={open}
                sources={[source]}
                onClose={close}
            />
        </>
    );
};
