import { Box } from '@mui/material';
import { useIsBannerShown } from '../useIsBannerShown';
import { BANNER_HEIGHT } from '../../../../components/Banner/Banner';
import { OutOfMessagesBanner } from '../OutOfMessagesBanner';
import { TenDLCBanner } from '../TenDLCBanner';
import { useIs10DLCRequired } from '../useIs10DLCRequired';
import { useIsOutOfMessages } from '../useIsOutOfMessages';

export const BannerHolder = () => {
    const is10DLCRequired = useIs10DLCRequired();
    const isOutOfMessages = useIsOutOfMessages();
    const isBannerShown = useIsBannerShown();

    return (
        <Box
            sx={{
                height: isBannerShown ? BANNER_HEIGHT : 0,
                overflow: 'hidden',
            }}
        >
            {isOutOfMessages && <OutOfMessagesBanner />}
            {is10DLCRequired && <TenDLCBanner />}
        </Box>
    );
};
