import {
    Checkbox,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Typography,
} from '@mui/material';
import * as styles from './styles';
import React from 'react';

type Option = {
    id: string;
    label: string;
    secondaryLabel?: string;
    description?: string;
    icon?: React.ElementType;
    disabled?: boolean;
};

export function OptionItem({
    option,
    menuMaxWidth,
    onChange,
    hasCheckbox = false,
    isChecked = false,
}: {
    onChange: (e: React.MouseEvent<HTMLLIElement>) => void;
    menuMaxWidth?: number;
    hasCheckbox?: boolean;
    isChecked: boolean;
    option: Option;
}) {
    const Icon = option?.icon;

    return (
        <MenuItem
            disabled={!!option.disabled}
            value={option.id}
            key={option.id}
            sx={{
                maxWidth: menuMaxWidth,
                ...(hasCheckbox ? styles.menuItemWithCheckbox : undefined),
            }}
            onClick={onChange}
        >
            {hasCheckbox && (
                <Checkbox
                    size="small"
                    sx={styles.checkbox}
                    color="info"
                    checked={isChecked}
                />
            )}

            {!!Icon && (
                <ListItemIcon>
                    <Icon sx={styles.icon} />
                </ListItemIcon>
            )}

            <ListItemText
                primaryTypographyProps={{
                    variant: 'body3',
                    color: 'primary.dark',
                }}
                secondaryTypographyProps={{
                    variant: 'body4',
                    color: 'custom.gray.super',
                    sx: { whiteSpace: 'break-spaces' },
                }}
                primary={option.label}
                secondary={option.description}
            />

            {!!option.secondaryLabel && (
                <Typography variant="body3" color="primary.dark">
                    {option.secondaryLabel}
                </Typography>
            )}
        </MenuItem>
    );
}
