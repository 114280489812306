import parse, {
    CountryCode,
    findPhoneNumbersInText,
    NumberFormat,
} from 'libphonenumber-js';

/**
 * Function formats phone number in national (domestic) format if it corresponds to the provided team country code.
 * Otherwise it returns phone number in international format.
 *
 * @param phoneNumber phone number
 * @param teamCountry ISO country code. Optinal parameter.
 * @returns formatted phone number
 */
export const formatPhoneNumber = (
    phoneNumber: string | null = '',
    teamCountry?: string,
    format: NumberFormat = 'INTERNATIONAL',
): string => {
    if (!phoneNumber) {
        return '';
    }

    const parsed = parse(phoneNumber);
    if (!parsed?.isValid()) {
        return phoneNumber;
    }

    if (format === 'NATIONAL' || teamCountry === parsed.country) {
        return parsed.formatNational();
    }

    return parsed.formatInternational();
};

export const parsePhoneNumber = (phone: string, country?: string) => {
    try {
        let parsed = parse(addPlusIfNeeded(phone), country as CountryCode);
        if (!parsed || !parsed?.isValid()) {
            parsed = parse(phone, country as CountryCode);
        }

        if (parsed?.isValid()) {
            return parsed?.number;
        }

        return undefined;
    } catch (e) {
        // eslint-disable-next-line no-console
        console.error('Error parsing phone number', e);
    }

    return undefined;
};

export const addPlusIfNeeded = (phone: string) =>
    phone.startsWith('+') ? phone : `+${phone}`;

/**
 * Function parses phone numbers from the text and returns them in E.164 format.
 * @param text
 * @param defaultCountry
 */
export const findAndFormatPhonesInText = (
    text: string,
    defaultCountry = 'US',
): string[] => {
    const phoneNumbers = findPhoneNumbersInText(
        text,
        defaultCountry as CountryCode,
    );
    return phoneNumbers.map((p) => p.number.number);
};
