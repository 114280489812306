import styles from './WorkflowPlaceholder.module.scss';
import { useNavigate } from 'react-router';
import { Button } from '@mui/material';
import { Add } from '../../ConversationsList/CAdd';
import { CodeForkIcon } from '../../../assets/newAssets/icons/CCodeForkIcon';
import { getSquare } from '../../../theme/style.helpers';

export const WorkflowPlaceholder = () => {
    const navigate = useNavigate();

    return (
        <div className={styles['workflow-placeholder']}>
            <div className={styles['workflow-placeholder__content']}>
                <div className={styles['workflow-placeholder__text-container']}>
                    <CodeForkIcon sx={getSquare(40)} />
                    <h6 className={styles['workflow-placeholder__greeting']}>
                        Welcome to workflows
                    </h6>
                    <p className={styles['workflow-placeholder__description']}>
                        Workflow is the series of activities that are necessary
                        to complete a task.
                    </p>
                    <Button
                        startIcon={<Add />}
                        onClick={() => navigate('/workflows/new')}
                    >
                        Create workflow
                    </Button>
                </div>
            </div>
        </div>
    );
};
