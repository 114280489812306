import styles from './InboxItemActions.module.scss';
import { useRef } from 'react';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import { Icon } from '../../icons/Icon';
import { Typography } from '@mui/material';
import { SettingsIcon } from '../../components/Inbox/CSettingsIcon';
import { MarkAsReadIcon } from '../../components/Inbox/CMarkAsReadIcon';
import { MuteIcon } from '../../components/Inbox/CMuteIcon';
import { UnmuteIcon } from '../../components/Inbox/CUnmuteIcon';

interface InboxItemActionsProps {
    isMuted: boolean;
    onOutsideClick: () => void;
    onMarkReadClick: () => void;
    onSettingsClick: () => void;
    onArchiveAllClick: () => void;
    onMute: () => void;
    onUnmute: () => void;
}

/** @deprecated needs to be rewritten to mui/menu */
const InboxItemActions = ({
    isMuted,
    onOutsideClick,
    onMarkReadClick,
    onSettingsClick,
    onArchiveAllClick,
    onMute,
    onUnmute,
}: InboxItemActionsProps) => {
    const ulRef = useRef<HTMLUListElement>(null);

    useOnClickOutside(ulRef, onOutsideClick);

    return (
        <div className={styles['inbox-actions']}>
            <ul className={styles['inbox-actions__list']} ref={ulRef}>
                <li className={styles['inbox-actions__list-item']}>
                    <button
                        data-testid="inbox-context-settings-btn"
                        className={styles['inbox-actions__btn']}
                        onClick={(e) => {
                            e.preventDefault();
                            onSettingsClick();
                        }}
                        type="button"
                    >
                        <SettingsIcon />
                        <Typography variant="body3">Settings</Typography>
                    </button>
                </li>
                <li className={styles['inbox-actions__list-item']}>
                    <button
                        data-testid="inbox-mark-all-read-btn"
                        className={styles['inbox-actions__btn']}
                        onClick={(e) => {
                            e.preventDefault();
                            onMarkReadClick();
                        }}
                        type="button"
                    >
                        <MarkAsReadIcon />
                        <Typography variant="body3">Mark All Read</Typography>
                    </button>
                </li>
                <li className={styles['inbox-actions__list-item']}>
                    <button
                        data-testid="inbox-context-archive-btn"
                        className={styles['inbox-actions__btn']}
                        onClick={(e) => {
                            e.preventDefault();
                            onArchiveAllClick();
                        }}
                        type="button"
                    >
                        <Icon
                            name="archive"
                            className={styles['inbox-actions__icon']}
                        />
                        <Typography variant="body3">Archive All</Typography>
                    </button>
                </li>
                {isMuted ? (
                    <li className={styles['inbox-actions__list-item']}>
                        <button
                            data-testid="inbox-context-mute-btn"
                            className={styles['inbox-actions__btn']}
                            onClick={(e) => {
                                e.preventDefault();
                                onUnmute();
                            }}
                            type="button"
                        >
                            <UnmuteIcon />
                            <Typography variant="body3">Unmute</Typography>
                        </button>
                    </li>
                ) : (
                    <li className={styles['inbox-actions__list-item']}>
                        <button
                            data-testid="inbox-context-mute-btn"
                            className={styles['inbox-actions__btn']}
                            onClick={(e) => {
                                e.preventDefault();
                                onMute();
                            }}
                            type="button"
                        >
                            <MuteIcon />
                            <Typography variant="body3">Mute</Typography>
                        </button>
                    </li>
                )}
            </ul>
        </div>
    );
};

export default InboxItemActions;
