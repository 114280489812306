import { CountryCode } from 'libphonenumber-js';
import * as Yup from 'yup';
import { countriesList, countryFlags } from '../../../utils/countries';
import {
    CompanyEntityType,
    CompanyVertical,
    CreateBrandDto,
} from '../../../api/types';
import { canadianStatesList, statesList } from '../../../utils/states';
import { Option } from '../../../components/EnhancedSelect/types';

export type FormData = CreateBrandDto;

export const stateOptions = statesList.map((item) => ({
    id: item.code,
    label: item.name,
}));

export const canadaStateOptions = canadianStatesList.map((item) => ({
    id: item.code,
    label: item.name,
}));

export const countriesOptions: Option[] = countriesList
    .filter(({ code }) => ['CA', 'US'].includes(code))
    .map((item) => ({
        id: item.code as CountryCode,
        label: item.name,
        icon: countryFlags[item.code as keyof typeof countryFlags],
    }));

export const organizationLegalFormOptions = [
    {
        id: CompanyEntityType.PRIVATE_PROFIT,
        label: 'Private Profit',
    },
    {
        id: CompanyEntityType.PUBLIC_PROFIT,
        label: 'Public Profit',
    },
    {
        id: CompanyEntityType.NON_PROFIT,
        label: 'Non Profit',
    },
    {
        id: CompanyEntityType.GOVERNMENT,
        label: 'Government',
    },
];

export const verticalOptions = [
    {
        id: CompanyVertical.AGRICULTURE,
        label: 'AGRICULTURE',
    },
    {
        id: CompanyVertical.COMMUNICATION,
        label: 'COMMUNICATION',
    },
    {
        id: CompanyVertical.CONSTRUCTION,
        label: 'CONSTRUCTION',
    },
    {
        id: CompanyVertical.EDUCATION,
        label: 'EDUCATION',
    },
    {
        id: CompanyVertical.ENERGY,
        label: 'ENERGY',
    },
    {
        id: CompanyVertical.ENTERTAINMENT,
        label: 'ENTERTAINMENT',
    },
    {
        id: CompanyVertical.RETAIL,
        label: 'RETAIL',
    },
    {
        id: CompanyVertical.TRANSPORTATION,
        label: 'TRANSPORTATION',
    },
    {
        id: CompanyVertical.INSURANCE,
        label: 'INSURANCE',
    },
    {
        id: CompanyVertical.POSTAL,
        label: 'POSTAL',
    },
    {
        id: CompanyVertical.HOSPITALITY,
        label: 'HOSPITALITY',
    },
    {
        id: CompanyVertical.FINANCIAL,
        label: 'FINANCIAL',
    },
    {
        id: CompanyVertical.POLITICAL,
        label: 'POLITICAL',
    },
    {
        id: CompanyVertical.GAMBLING,
        label: 'GAMBLING',
    },
    {
        id: CompanyVertical.LEGAL,
        label: 'LEGAL',
    },
    {
        id: CompanyVertical.NGO,
        label: 'NGO',
    },
    {
        id: CompanyVertical.MANUFACTURING,
        label: 'MANUFACTURING',
    },
    {
        id: CompanyVertical.GOVERNMENT,
        label: 'GOVERNMENT',
    },
    {
        id: CompanyVertical.TECHNOLOGY,
        label: 'TECHNOLOGY',
    },
];

export const validationSchema = Yup.object({
    companyName: Yup.string().required().max(255),
    brandName: Yup.string().required().max(255),
    organizationLegalForm: Yup.mixed<CompanyEntityType>()
        .required()
        .oneOf(Object.values(CompanyEntityType)),
    ein: Yup.string().required().max(21),
    altBusinessId: Yup.string().optional().max(50),
    companyCountry: Yup.string().required().min(2).max(2),
    state: Yup.string().required().max(20),
    city: Yup.string().required().max(100),
    companyZip: Yup.string().required().max(10),
    companyAddress: Yup.string().required().max(100),
    organizationVertical: Yup.mixed<CompanyVertical>()
        .required()
        .oneOf(Object.values(CompanyVertical)),
    contactPhone: Yup.string().required(),
    contactEmail: Yup.string().required().email().max(100),
    website: Yup.string().required(),
});
