import { useMeQueryData, useTeammates } from '../queries/user';
import { RoleTypes } from '../api/types';

export const useWorkspaceOwnersAndAdmins = () => {
    const { data: teammates = [] } = useTeammates();
    return teammates.filter(
        (teammate) =>
            teammate?.role?.type === RoleTypes.WorkspaceOwner ||
            teammate?.role?.type === RoleTypes.Admin,
    );
};

export const useIsWorkspaceOwnerOrAdmin = (): boolean => {
    const owners = useWorkspaceOwnersAndAdmins();
    const me = useMeQueryData();
    return owners.some((owner) => owner.id === me?.id);
};
