import { Box, Typography, LinearProgress, Paper, Chip } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Inbox } from '../../api/types';
import { PhoneNumber } from '../PhoneNumber/PhoneNumber';

type PendingActivationInboxProps = {
    inbox: Inbox;
};

export const PendingActivationInbox = (props: PendingActivationInboxProps) => {
    return (
        <Box
            sx={{
                width: 1,
                maxWidth: 480,
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                gap: 3,
            }}
        >
            <Paper
                elevation={3}
                sx={{
                    p: 3,
                    borderRadius: 2,
                    border: 1,
                    borderColor: 'divider',
                    backgroundColor: 'white',
                }}
            >
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <div>
                        <Typography variant="h2" id="pending-activation-title">
                            Phone Activation
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            <PhoneNumber
                                phone={props.inbox.phone}
                                styles={{ fontWeight: 600 }}
                            />
                        </Typography>
                    </div>
                    <Chip
                        icon={<AccessTimeIcon />}
                        label="Activating"
                        size="small"
                        color="warning"
                        sx={{ ml: 'auto' }}
                    />
                </Box>

                <div>
                    <LinearProgress
                        color="warning"
                        variant="indeterminate"
                        sx={{ height: 6, borderRadius: 1, my: 2 }}
                    />
                </div>

                <Typography variant="body3" sx={{ mb: 3 }}>
                    <PhoneNumber
                        phone={props.inbox.phone}
                        styles={{ fontWeight: 600 }}
                    />{' '}
                    is being activated and should be ready shortly. We&apos;ll
                    notify you once it&apos;s complete.
                </Typography>

                <div>
                    <Typography variant="body4" color="text.secondary">
                        Estimated time: 2-5 minutes
                    </Typography>
                </div>
            </Paper>
        </Box>
    );
};
