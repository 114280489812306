import { createTheme } from '@mui/material/styles';
import { typography } from './typography';
import { shadows } from './shadows';
import { MuiAlert } from './MuiAlert.override';
import { MuiCard } from './MuiCard.override';
import { MuiTooltip } from './mui-tooltip.override';
import { MuiButton } from './MuiButton.override';
import { MuiChip } from './MuiChip.override';
import { MuiAvatar } from './MuiAvatar.override';
import { MuiButtonBase } from './MuiButtonBase.override';
import { MuiListItemIcon } from './MuiListItemIcon.override';
import { palette } from './palette';
import { MuiMenu } from './MuiMenu.override';
import { MuiMenuItem } from './MuiMenuItem.override';
import { MuiListItemText } from './MuiListItemText.override';
import { MuiDrawer } from './MuiDrawer.override';
import { MuiSelect } from './MuiSelect.override';
import { MuiAutocomplete } from './MuiAutocomplete.override';
import { MuiInputLabel } from './MuiInputLabel.override';
import { MuiInputBase } from './MuiInputBase.override';
import { MuiDialogTitle } from './MuiDialogTitle.override';
import { MuiDialogActions } from './MuiDialogActions.override';
import { MuiOutlinedInput } from './MuiOutlinedInput.override';
import { MuiPaper } from './MuiPaper.override';
import { MuiFormHelperText } from './MuiFormHelperText.override';
import { MuiToggleButton } from './MuiToggleButton.override';
import { MuiTextField } from './MuiTextField.override';
import { MuiToggleButtonGroup } from './MuiToggleButtonGroup.override';
import { MuiTableCell } from './MuiTableCell.override';
import { MuiCheckbox } from './MuiCheckbox.override';
import { MuiDialogContent } from './MuiDialogContent.override';
import { MuiIconButton } from './MuiIconButton.override';
import { MuiDialog } from './MuiDialog.override';
import { MuiAlertTitle } from './MuiAlertTitle.override';
import { MuiInputAdornment } from './MuiInputAdornment.override';
import { MuiInput } from './MuiInput.override';
import { MuiButtonGroup } from './MuiButtonGroup.override';
import { MuiTabs } from './MuiTabs.override';
import { MuiTab } from './MuiTab.override';
import { MuiDivider } from './MuiDivider.override';
import { MuiLink } from './MuiLink.override';
import { MuiTableHead } from './MuiTableHead.override';
import { MuiTableRow } from './MuiTableRow.override';
import { MuiList } from './MuiList.override';
import { MuiFormControlLabel } from './FormControlLabel.override';
import { MuiSwitch } from './MuiSwitch.override';
import { MuiListItem } from './MuiListItem.override';
import { MuiRadio } from './MuiRadio.override';
import { MuiBadge } from './MuiBadge.override';
import { MuiListSubheader } from './MuiListSubheader.override';

export const theme = createTheme({
    spacing: 4,
    palette,
    typography,
    shadows,
    components: {
        MuiAlert,
        MuiAlertTitle,
        MuiAutocomplete,
        MuiAvatar,
        MuiBadge,
        MuiButton,
        MuiButtonBase,
        MuiButtonGroup,
        MuiCard,
        MuiCheckbox,
        MuiChip,
        MuiDialog,
        MuiDialogActions,
        MuiDialogContent,
        MuiDialogTitle,
        MuiDrawer,
        MuiFormControlLabel,
        MuiFormHelperText,
        MuiIconButton,
        MuiInput,
        MuiInputAdornment,
        MuiInputBase,
        MuiInputLabel,
        MuiList,
        MuiListItem,
        MuiListItemIcon,
        MuiListItemText,
        MuiListSubheader,
        MuiMenu,
        MuiMenuItem,
        MuiOutlinedInput,
        MuiPaper,
        MuiRadio,
        MuiSelect,
        MuiTabs,
        MuiTab,
        MuiTableCell,
        MuiTableHead,
        MuiTableRow,
        MuiTextField,
        MuiToggleButton,
        MuiToggleButtonGroup,
        MuiTooltip,
        MuiDivider,
        MuiLink,
        MuiSwitch,
    },
});
