import { ToastActions, useToastContext } from '../../containers/toast/reducer';
import { useEffect } from 'react';
import {
    removeConversationAssignment,
    useConversationAssignments,
} from '../../websocket/event-handlers/handle-conversation-assigned.event';
import {
    CURRENT_USER_KEY,
    useEnabledFeature,
    usePhoneNumberFormatter,
    useTeammates,
} from '../../queries/user';
import { getFullName } from '../../utils/user';
import { Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { aggregator } from '../../components/Contacts/LookupAggregator/phone-lookup-aggregator';
import { Feature, Profile } from '../../api/types';
import { formatPhoneNumber } from '../../utils/phoneNumber';
import { getPrimaryPhone } from '../../components/NewConversation/utils';
import { useQueryClient } from '@tanstack/react-query';

export const useConversationAssigneeToasts = () => {
    const { data: assignations = [] } = useConversationAssignments();
    const { dispatch: toastDispatch } = useToastContext();
    const { data: teammates = [] } = useTeammates();
    const isMultipleContactPhonesEnabled = useEnabledFeature(
        Feature.MultipleContactPhones,
    );
    const country =
        useQueryClient().getQueryData<Profile>([CURRENT_USER_KEY])?.activeTeam
            .countryCode || 'US';
    const format = usePhoneNumberFormatter();

    useEffect(() => {
        assignations.forEach(async (assignation) => {
            const assignor = teammates.find(
                ({ id }) => id === assignation.assignorId,
            );
            const name = getFullName(assignor) || 'Your teammate';
            const conversationName = (
                await Promise.all(
                    assignation.members.map((phone) =>
                        aggregator.getContact(
                            phone,
                            isMultipleContactPhonesEnabled,
                        ),
                    ),
                )
            )
                .map(
                    (contact) =>
                        contact?.name ||
                        formatPhoneNumber(
                            (isMultipleContactPhonesEnabled
                                ? getPrimaryPhone(contact?.phones ?? [])
                                : contact
                            )?.phone,
                            country,
                        ),
                )
                .join(', ');

            toastDispatch({
                type: ToastActions.ADD,
                payload: {
                    id: `assigned-${assignation.conversationId}`,
                    severity: 'info',
                    duration: 365 * 60 * 60 * 1_000,
                    title: 'You assigned to the conversation',
                    description: (
                        <div>
                            {name} assigned you to the{' '}
                            <Link
                                component={RouterLink}
                                to={`/inbox/${assignation.inboxId}/conversation/${assignation.conversationId}`}
                            >
                                Conversation{' '}
                                {conversationName
                                    ? `(${conversationName})`
                                    : `(${assignation.members.map(format).join(', ')})`}
                            </Link>
                        </div>
                    ),
                },
            });

            removeConversationAssignment(assignation);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assignations]);
};
