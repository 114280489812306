import { createContext, useContext, useState, ReactNode } from 'react';

interface MessageInputFocusContextType {
    messageInputFocus: boolean;
    setMessageInputFocus: (value: boolean) => void;
}

const MessageInputFocusContext =
    createContext<MessageInputFocusContextType | null>(null);

export const MessageInputFocusProvider = ({
    children,
}: {
    children: ReactNode;
}) => {
    const [messageInputFocus, setMessageInputFocus] = useState(false);

    return (
        <MessageInputFocusContext.Provider
            value={{ messageInputFocus, setMessageInputFocus }}
        >
            {children}
        </MessageInputFocusContext.Provider>
    );
};

export const useMessageInputFocus = (): MessageInputFocusContextType => {
    const context = useContext(MessageInputFocusContext);
    if (!context) {
        throw new Error(
            'useMessageInputFocus must be used within a MessageInputFocusProvider',
        );
    }
    return context;
};
