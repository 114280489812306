import { Alert, AlertTitle } from '@mui/material';
import { pluralize } from '../../utils/pluralize';

type Props = { count: number };

export const PhonesOptedOutBanner = ({ count }: Props) => (
    <Alert severity="error" icon="⛔">
        <AlertTitle>
            {pluralize(count, 'Recipient', 'Recipients', '', '')} has been
            unsubscribed from SMS updates
        </AlertTitle>
        To send a message, kindly disable the opt out option for{' '}
        {pluralize(count, 'recipient', 'recipients', '', '')}.
    </Alert>
);
