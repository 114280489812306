import { Inbox } from '../../../api/types';
import { Button, Divider, Stack, Typography } from '@mui/material';
import { DeleteIcon } from '../../../icons/common/CDeleteIcon';
import { useDeleteInbox } from '../../../queries/inboxes';
import { Header } from '../Header';
import { ToastActions, useToastContext } from '../../toast/reducer';
import { useNavigate } from 'react-router-dom';

export function DeleteInbox({
    inbox,
    onClose,
}: {
    inbox: Inbox;
    onClose: () => void;
}) {
    const navigate = useNavigate();
    const { mutateAsync: removeInbox } = useDeleteInbox();
    const { dispatch: toastDispatch } = useToastContext();

    return (
        <>
            <Header title="About Phone" description="General information" />
            <Divider />
            <Stack spacing={6} alignItems="center" flex={1} pt={10}>
                <Stack spacing={4} alignItems="center" maxWidth={420}>
                    <Typography fontSize={40} lineHeight="40px">
                        ❌
                    </Typography>
                    <Stack spacing={2} alignItems="center" textAlign="center">
                        <Typography variant="h2" color="primary.dark">
                            Delete phone?
                        </Typography>
                        <Typography variant="body3" color="primary.dark">
                            All messages, conversations, and phone number will
                            be removed immediately.
                        </Typography>
                    </Stack>
                    <Typography
                        variant="h4"
                        color="error.main"
                        textAlign="center"
                    >
                        This can NOT be undone.
                    </Typography>
                </Stack>
                <Stack
                    useFlexGap
                    direction={{
                        xs: 'column',
                        md: 'row',
                    }}
                    spacing={4}
                    alignItems="center"
                >
                    <Button
                        variant="outlined"
                        color="primary"
                        size="large"
                        sx={{ width: 148 }}
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="outlined"
                        color="error"
                        size="large"
                        sx={{ width: 148 }}
                        onClick={async () => {
                            try {
                                await removeInbox({ inboxId: inbox.id });

                                toastDispatch({
                                    type: ToastActions.ADD,
                                    payload: {
                                        severity: 'success',
                                        title: 'Phone settings',
                                        description: 'Inbox has been deleted',
                                    },
                                });
                                navigate('/');
                            } catch (_) {
                                toastDispatch({
                                    type: ToastActions.ADD,
                                    payload: {
                                        severity: 'error',
                                        title: 'Phone settings',
                                        description:
                                            'Failed to delete the inbox',
                                    },
                                });
                            }
                            onClose();
                        }}
                        startIcon={<DeleteIcon />}
                    >
                        Delete Phone
                    </Button>
                </Stack>
            </Stack>
        </>
    );
}
