import { Conversation } from '../../../api/types';
import { SparklesIcon } from '../../../icons/common/SparklesIcon';
import {
    useRcsTyping,
    useUserTyping,
} from '../../ConversationRow/use-conversation-typing';
import { useIsAssistantReply } from './use-is-assistant-reply';
import { getTextFromJSON } from '../../Slate/serializers/json';
import { colors, SxProps, Typography } from '@mui/material';
import { useMessageStoppedBy } from '../../ConversationRow/stop-phrase/use-message-stopped-by';
import { AttachmentsStatus } from './AttachmentsStatus';

type Props = {
    conversation: Conversation;
};

const label: SxProps = {
    display: 'inline-flex',
    alignItems: 'center',
    borderRadius: '4px',
    gap: '8px',
    paddingTop: '4px',
    paddingRight: '6px',
    paddingBottom: '4px',
    paddingLeft: '6px',
};

const flowStopped: SxProps = {
    ...label,
    color: colors.red[500],
    backgroundColor: colors.red[50],
};

const waitingForReview: SxProps = {
    ...label,
    color: colors.orange[700],
    backgroundColor: colors.orange[50],
};

export const ConversationStatus = ({ conversation }: Props) => {
    const userTyping = useUserTyping(conversation.id);
    const rcsTyping = useRcsTyping(conversation.id);
    const isAssistantReply = useIsAssistantReply(conversation);
    const phrase = useMessageStoppedBy(conversation.lastMessageBody);
    const draft = getTextFromJSON(
        window.localStorage.getItem(`${conversation.id}_draft`) ?? '',
    );

    if (userTyping || rcsTyping) {
        return (
            <Typography component="span" variant="inherit" color="info.main">
                {userTyping || rcsTyping} is typing ...
            </Typography>
        );
    }
    if (isAssistantReply && !phrase) {
        return (
            <Typography variant="body4" sx={waitingForReview}>
                <SparklesIcon size="16px" />
                AI waiting for review
            </Typography>
        );
    }

    if (conversation.assistantDisabled) {
        return (
            <Typography variant="body4" sx={flowStopped}>
                <SparklesIcon size="16px" />
                Agent stopped
            </Typography>
        );
    }

    if (!conversation.unreadMessageCount && draft) {
        return <Typography variant="inherit">Draft: {draft}</Typography>;
    }

    if (
        !conversation.lastMessageBody?.trim() &&
        conversation.lastMessageAttachmentsCount > 0
    ) {
        return (
            <AttachmentsStatus
                count={conversation.lastMessageAttachmentsCount}
            />
        );
    }

    return (
        <Typography variant="inherit">
            {conversation.lastMessageBody}
        </Typography>
    );
};
