import { useState } from 'react';
import {
    Box,
    SxProps,
    Tooltip,
    Typography,
    TypographyProps,
} from '@mui/material';
import { usePhoneNumberFormatter } from '../../queries/user';

type PhoneNumberProps = {
    phone: string;
    styles?: SxProps;
    variant?: TypographyProps['variant'];
    tooltipEnabled?: boolean;
};

export const PhoneNumber = ({
    phone,
    styles,
    variant = 'body3',
    tooltipEnabled = true,
}: PhoneNumberProps) => {
    const [copied, setCopied] = useState(false);

    const formatter = usePhoneNumberFormatter();
    const formattedPhone = formatter(phone);

    const handleCopy = () => {
        navigator.clipboard.writeText(phone);
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
    };

    return (
        <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
            <Tooltip
                title={
                    tooltipEnabled
                        ? (copied && 'Copied!') || 'Click to copy'
                        : undefined
                }
                placement="top"
            >
                <Typography
                    variant={variant}
                    onClick={handleCopy}
                    sx={{
                        cursor: 'pointer',
                        ...styles,
                    }}
                >
                    {formattedPhone}
                </Typography>
            </Tooltip>
        </Box>
    );
};
