import { SvgIcon } from '@mui/material';
import { ClerkIconProps } from '../../types/clerk-icon.props';

export const ReceiptCheckIcon = (props: ClerkIconProps) => (
    <SvgIcon {...props}>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                fill="currentColor"
                d="M14.9956 4.238C14.6853 3.92415 14.1795 3.92019 13.8643 4.22918L7.95096 10.0735L5.38037 7.41236C5.07378 7.09281 4.56621 7.08232 4.2467 7.38891C3.92715 7.69551 3.91666 8.20308 4.22325 8.52259C4.22464 8.52402 4.226 8.52544 4.22739 8.52686L7.36166 11.775C7.51166 11.928 7.71667 12.0147 7.93095 12.0155H7.93977C8.14999 12.0149 8.35156 11.9317 8.50104 11.7838L14.9884 5.36935C15.3019 5.05852 15.3051 4.55282 14.9956 4.238Z"
            />
        </svg>
    </SvgIcon>
);
