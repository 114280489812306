import { useState, useRef } from 'react';
import cc from 'classcat';
import styles from './TimeSelect.module.scss';
import { getTimeList, TimeSelectItem } from './utils';
import {
    DropdownContainer,
    DropdownItem,
    DropdownList,
} from '../../../../elements/Dropdown';
import useOnClickOutside from '../../../../hooks/useOnClickOutside';
import { CheckIcon } from '../../../../icons/common/CCheckIcon';
import { colors } from '../../../../theme/palette';

const timeList = getTimeList();

interface TimeSelectProps {
    value: string;
    direction?: 'left' | 'right';
    disabled?: boolean;
    onClick: (value: TimeSelectItem) => void;
}

export const TimeSelect = ({
    value,
    direction = 'right',
    disabled,
    onClick,
}: TimeSelectProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const wrapperRef = useRef<HTMLDivElement>(null);

    useOnClickOutside(wrapperRef, () => setIsOpen(false));

    return (
        <div className={styles['time-select']}>
            <div
                className={cc([
                    styles['time-select__value'],
                    {
                        [styles['time-select__value_active']]: isOpen,
                        [styles['time-select__value_disabled']]: disabled,
                    },
                ])}
                onClick={() => !disabled && setIsOpen(true)}
            >
                {timeList.find((item) => item.id === value)?.label || '-'}
            </div>
            {isOpen && (
                <DropdownContainer
                    className={cc([
                        styles['time-select__dropdown'],
                        {
                            [styles['time-select__dropdown_left']]:
                                direction === 'left',
                        },
                    ])}
                    ref={wrapperRef}
                >
                    <DropdownList className={styles['time-select__list']}>
                        {timeList.map((rec) => (
                            <DropdownItem
                                key={rec.id}
                                onClick={() => {
                                    setIsOpen(false);
                                    onClick(rec);
                                }}
                            >
                                <div
                                    className={styles['time-select__list-item']}
                                >
                                    <span>{rec.label}</span>
                                    {rec.id === value && (
                                        <CheckIcon
                                            sx={{ color: colors.blue[500] }}
                                        />
                                    )}
                                </div>
                            </DropdownItem>
                        ))}
                    </DropdownList>
                </DropdownContainer>
            )}
        </div>
    );
};
