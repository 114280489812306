import dayjs from 'dayjs';
import { Box } from '@mui/material';
import { Message, MessageStatus } from '../../../../api/types';
import { DotDivider } from '../../../../components/common/DotDivider';
import { ReceiptCheckIcon } from '../../../../icons/common/ReceiptCheckIcon';
import { ReceiptDoubleCheckIcon } from '../../../../icons/common/ReceiptDoubleCheckIcon';
import { info } from './chat-message.styles';

type ChatMessageReceiptProps = {
    message: Message;
};

type ReceiptBoxProps = {
    message: Message;
    receiptLabel: string;
    timestamp: Date;
    children: React.ReactNode;
};

const ReceiptBox = (props: ReceiptBoxProps) => {
    return (
        <Box
            role="note"
            aria-label="message receipt"
            typography="body5"
            color="custom.text.secondary"
            display="flex"
            sx={info(props.message.inbound)}
        >
            {props.children}
            <Box role="note" aria-label="receipt status">
                {props.receiptLabel}
            </Box>
            <DotDivider />
            <Box role="note" aria-label="receipt timestamp">
                {dayjs(props.timestamp).format('hh:mm A')}
            </Box>
        </Box>
    );
};

export const ChatMessageReceipt = (props: ChatMessageReceiptProps) => {
    const receiptStatuses = [MessageStatus.Delivered, MessageStatus.Read];
    const iconStyle = { width: 16, height: 16 };
    const { message } = props;

    if (message.inbound) {
        return null;
    }

    if (!receiptStatuses.includes(message.status)) {
        return null;
    }

    const receiptLabel =
        message.status === MessageStatus.Delivered ? 'Delivered' : 'Read';
    const receiptIcon =
        message.status === MessageStatus.Delivered ? (
            <ReceiptCheckIcon sx={iconStyle} />
        ) : (
            <ReceiptDoubleCheckIcon sx={iconStyle} />
        );

    return (
        <ReceiptBox
            message={message}
            receiptLabel={receiptLabel}
            timestamp={message.timestamp}
        >
            {receiptIcon}
        </ReceiptBox>
    );
};
