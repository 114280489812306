import { Descendant } from 'slate';
import { ParagraphElement } from '../slate';
import { createTextNode } from '../utils';

export function createParagraphNode(
    children: string | Descendant[],
): ParagraphElement {
    return {
        type: 'paragraph',
        children: Array.isArray(children)
            ? [...children]
            : [createTextNode(children)],
    };
}
