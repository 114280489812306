import dayjs, { ManipulateType } from 'dayjs';

import { Period, TeamStats } from '../types';

const periodMap: Record<Period, ManipulateType> = {
    '1 day': 'day',
    '1 hour': 'hour',
    '1 week': 'week',
    '1 month': 'month',
};

export type PickedStats = {
    incoming: TeamStats['incoming'];
    outgoing: TeamStats['outgoing'];
    users?: TeamStats['users'];
    callQueue?: TeamStats['callQueue'];
    callTypeFilters?: TeamStats['callTypeFilters'];
    callQueuesList?: TeamStats['callQueuesList'];
    period: Period;
};

export const fillEmptyDatesInUsage = (
    usage: TeamStats,
    start: string,
    end: string,
): PickedStats => {
    if (!periodMap[usage.period]) {
        return usage;
    }

    const startDate = dayjs(start);
    const endDate = dayjs(end);
    const period = periodMap[usage.period] ?? 'day';

    const filledUsage: PickedStats = {
        incoming: [],
        outgoing: [],
        users: usage.users,
        callQueue: usage.callQueue,
        period: usage.period,
    };

    let date = startDate;
    let i = 0;

    while (date.isBefore(endDate.add(1, period), period) && i <= 10000) {
        i++;
        filledUsage.incoming.push({
            date: date.toDate().toISOString(),
            count:
                usage.incoming.find((item) =>
                    dayjs(item.date).isSame(date, period),
                )?.count || 0,
            duration:
                usage.incoming.find((item) =>
                    dayjs(item.date).isSame(date, period),
                )?.duration || 0,
        });

        filledUsage.outgoing.push({
            date: date.toDate().toISOString(),
            count:
                usage.outgoing.find((item) =>
                    dayjs(item.date).isSame(date, period),
                )?.count || 0,
            duration:
                usage.outgoing.find((item) =>
                    dayjs(item.date).isSame(date, period),
                )?.duration || 0,
        });

        date = date.add(1, period);

        if (date.isAfter(endDate)) {
            break;
        }
    }

    return {
        ...filledUsage,
        users: usage.users,
        callTypeFilters: usage.callTypeFilters,
        callQueuesList: usage.callQueuesList,
    };
};
