import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export const ConnectionHubspotIcon = (
    props: Exclude<SvgIconProps, 'component'>,
) => (
    <SvgIcon inheritViewBox {...props}>
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M20.6417 9.14047C19.8445 8.50653 18.9073 8.0908 17.916 7.93128H17.9203V5.08513C18.6589 4.72597 19.1324 3.95553 19.136 3.10681V3.0403C19.1329 1.83069 18.1917 0.850862 17.0299 0.847627H16.966C15.8041 0.850862 14.863 1.83069 14.8598 3.0403V3.10681C14.8635 3.95553 15.3369 4.72597 16.0755 5.08513V7.93724C15.0352 8.10285 14.0553 8.55172 13.2352 9.23835L5.71673 3.1423C6.0586 1.80983 5.29813 0.441127 4.01826 0.0852055C2.73839 -0.270716 1.42371 0.520956 1.08184 1.85342C0.739969 3.18589 1.50039 4.5546 2.78031 4.91052C3.39228 5.08067 4.04402 4.99199 4.59336 4.66367L11.9854 10.6547C10.6228 12.795 10.6594 15.586 12.0777 17.6866L9.82811 20.0286C9.64752 19.9688 9.4595 19.9369 9.26999 19.934C8.19151 19.934 7.31727 20.8442 7.31727 21.967C7.31727 23.0898 8.19151 24 9.26999 24C10.3485 24 11.2227 23.0898 11.2227 21.967C11.2201 21.7697 11.1894 21.5739 11.1318 21.3859L13.3572 19.0691C15.9907 21.1633 19.7562 20.6383 21.7678 17.8966C23.7793 15.1549 23.2751 11.2347 20.6417 9.14047ZM17.0014 17.3096L16.9943 17.3081C15.2939 17.304 13.9186 15.8656 13.9225 14.0953C13.9264 12.3249 15.3081 10.8931 17.0086 10.8972C18.7068 10.9012 20.0812 12.3361 20.0804 14.1041C20.0804 15.8745 18.7019 17.3096 17.0014 17.3096Z"
                fill="#EC6744"
            />
        </svg>
    </SvgIcon>
);
