import { SettingsRow } from '../SettingsRow';
import { Box, FormControlLabel, Switch } from '@mui/material';
import { colors } from '../../../theme/palette';

export function EnableRow({
    value,
    onChange,
}: {
    value: boolean;
    onChange: (value: boolean) => void;
}) {
    return (
        <SettingsRow label="Enable business hours">
            <Box
                sx={{
                    alignSelf: 'flex-start',
                    borderRadius: 1.5,
                    boxShadow: ({ shadows }) => shadows[3],
                    borderWidth: 1,
                    borderStyle: 'solid',
                    borderColor: colors.primary.gray.borderEnabled,
                    width: 124,
                    height: 36,
                    px: 3,
                }}
            >
                <FormControlLabel
                    value={value}
                    onClick={(e) => {
                        e.preventDefault();
                        onChange(!value);
                    }}
                    control={
                        <Switch
                            checked={value}
                            size="small"
                            color="info"
                            inputProps={{
                                'aria-label': 'Switch business hours enabling',
                            }}
                        />
                    }
                    label={value ? 'Turn off' : 'Turn on'}
                    labelPlacement="start"
                    sx={{
                        minWidth: 1,
                        justifyContent: 'space-between',
                        ml: 0,
                        color: 'custom.gray.super',
                        userSelect: 'none',
                        height: 36,
                    }}
                />
            </Box>
        </SettingsRow>
    );
}
