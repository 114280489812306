/* eslint-disable no-param-reassign */
import { RenderLeafProps } from 'slate-react';

export function renderLeaf({ children, leaf, attributes }: RenderLeafProps) {
    if (leaf.bold) {
        children = <strong>{children}</strong>;
    }

    if (leaf.italic) {
        children = <em>{children}</em>;
    }

    if (leaf.decoration === 'link') {
        children = (
            <a
                href={leaf.text}
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => {
                    window.open(leaf.text);
                }}
            >
                {children}
            </a>
        );
    }

    return <span {...attributes}>{children}</span>;
}
