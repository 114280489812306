import { SvgIconProps } from '@mui/material';
import { ContactPropertyType } from '../../../api/types';
import { createElement, FC } from 'react';
import { TextIcon } from './TextIcon';
import { NumberIcon } from './NumberIcon';
import { SelectIcon } from './SelectIcon';
import { CalendarIcon } from './CalendarIcon';
import { PrivateUserIcon } from './PrivateUserIcon';
import { RefreshIcon } from './RefreshIcon';
import { AccessTimeIcon } from './AccessTimeIcon';
import { AttachmentIcon } from '../../../icons/common/CAttachmentIcon';
import { CheckmarkIcon } from '../../../icons/shared/CheckmarkIcon';
import { EmailIcon } from '../../../icons/common/CEmailIcon';
import { PhoneIcon } from '../../../icons/common/CPhoneIcon';
import { UrlIcon } from '../../../icons/common/UrlIcon';

const map: Record<ContactPropertyType, FC<SvgIconProps>> = {
    [ContactPropertyType.Text]: TextIcon,
    [ContactPropertyType.Number]: NumberIcon,
    [ContactPropertyType.Select]: SelectIcon,
    [ContactPropertyType.MultiSelect]: SelectIcon,
    [ContactPropertyType.Date]: CalendarIcon,
    [ContactPropertyType.Person]: PrivateUserIcon,
    [ContactPropertyType.FilesAndMedia]: AttachmentIcon,
    [ContactPropertyType.Boolean]: CheckmarkIcon,
    [ContactPropertyType.URL]: UrlIcon,
    [ContactPropertyType.Email]: EmailIcon,
    [ContactPropertyType.Phone]: PhoneIcon,
    [ContactPropertyType.CreatedTime]: AccessTimeIcon,
    [ContactPropertyType.LastEditedTime]: RefreshIcon,
    [ContactPropertyType.LastEditedBy]: PrivateUserIcon,
    [ContactPropertyType.LastContactedBy]: PrivateUserIcon,
};

export const getTypeIcon = (
    type: ContactPropertyType,
    props: SvgIconProps = {},
) => createElement(map[type], props);
