import { useState } from 'react';
import { Messages } from './Messages';
import { ConversationMessageForm } from '../MessageFormContainers/ConversationMessageForm';
import { Conversation, Message, ScheduledMessage } from '../../api/types';
import { WorkflowHeader } from '../ConversationHeader/WorkflowHeader';
import { TypingIndicator } from '../TypingIndicator/TypingIndicator';
import { Box } from '@mui/material';
import { content } from './styles';
import { PaymentFailedBanner } from '../Banner/PaymentFailedBanner';
import { OutOfMessagesBanner } from '../Banner/OutOfMessagesBanner';
import { PhonesOptedOutBanner } from '../Banner/PhonesOptedOutBanner';
import { useCountOptedOut } from '../Contacts/use-contact-by-phone';

interface Props {
    conversation: Conversation;
    messageFormDisabled?: boolean;
    onBackClick: () => void;
}

export const Chat = (props: Props) => {
    const [smForEdit, setSmForEdit] = useState<ScheduledMessage | null>(null);
    const [messageForEdit, setMessageForEdit] = useState<Message | null>(null);
    const optedOutCount = useCountOptedOut(props.conversation?.members ?? []);

    return (
        <Box
            component="section"
            sx={{
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                borderRightWidth: 1,
                borderRightStyle: 'solid',
                borderRightColor: '#eaeaea',
            }}
        >
            <Box flex="0 0 auto">
                <WorkflowHeader
                    conversation={props.conversation}
                    onBackClick={props.onBackClick}
                />
            </Box>
            <Box sx={content}>
                {props.conversation && (
                    <Messages
                        setMessageForEdit={setMessageForEdit}
                        conversation={props.conversation}
                        setSmForEdit={setSmForEdit}
                    />
                )}
            </Box>

            <div>
                {props.conversation && (
                    <TypingIndicator conversationId={props.conversation.id} />
                )}
            </div>
            <div>
                {optedOutCount > 0 && (
                    <Box sx={{ p: 6 }}>
                        <PhonesOptedOutBanner count={optedOutCount} />
                    </Box>
                )}
                <PaymentFailedBanner />
                <OutOfMessagesBanner />
                <ConversationMessageForm
                    conversation={props.conversation}
                    smForEdit={smForEdit}
                    messageForEdit={messageForEdit}
                    onEditCancel={() => {
                        setSmForEdit(null);
                        setMessageForEdit(null);
                    }}
                    disabled={props.messageFormDisabled}
                />
            </div>
        </Box>
    );
};
