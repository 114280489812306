import { ClerkIconProps } from '../../types/clerk-icon.props';
import { SvgIcon } from '@mui/material';

export const UrlIcon = (props: ClerkIconProps) => (
    <SvgIcon inheritViewBox {...props}>
        <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.875 10C1.875 7.35368 4.0203 5.20837 6.66667 5.20837H8.33333C8.67851 5.20837 8.95833 5.4882 8.95833 5.83337C8.95833 6.17855 8.67851 6.45837 8.33333 6.45837H6.66667C4.71066 6.45837 3.125 8.04403 3.125 10C3.125 11.956 4.71066 13.5417 6.66667 13.5417H8.33333C8.67851 13.5417 8.95833 13.8215 8.95833 14.1667C8.95833 14.5119 8.67851 14.7917 8.33333 14.7917H6.66667C4.0203 14.7917 1.875 12.6464 1.875 10ZM11.0417 5.83337C11.0417 5.4882 11.3215 5.20837 11.6667 5.20837H13.3333C15.9797 5.20837 18.125 7.35368 18.125 10C18.125 12.6464 15.9797 14.7917 13.3333 14.7917H11.6667C11.3215 14.7917 11.0417 14.5119 11.0417 14.1667C11.0417 13.8215 11.3215 13.5417 11.6667 13.5417H13.3333C15.2893 13.5417 16.875 11.956 16.875 10C16.875 8.04403 15.2893 6.45837 13.3333 6.45837H11.6667C11.3215 6.45837 11.0417 6.17855 11.0417 5.83337ZM6.04167 10C6.04167 9.65486 6.32149 9.37504 6.66667 9.37504H13.3333C13.6785 9.37504 13.9583 9.65486 13.9583 10C13.9583 10.3452 13.6785 10.625 13.3333 10.625H6.66667C6.32149 10.625 6.04167 10.3452 6.04167 10Z"
                fill="currentColor"
            />
        </svg>
    </SvgIcon>
);
