import { Box, SxProps, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import { acceptDocFormats } from '../../../../constants';
import { useKnowledgeBaseSourceForm } from '../../../../hooks/useKnowledgeBaseSourceForm';

type Props = { sx?: SxProps };

/** todo: add dropzone */
export const SourceForm = ({ sx }: Props) => {
    const {
        file,
        fileInput,
        textInput,
        setTextSource,
        updateFile,
        loading,
        onSubmit,
        textFieldValue,
        onFileChange,
        errorMessage,
        startAdornment,
        endAdornment,
    } = useKnowledgeBaseSourceForm();

    return (
        <Box component="form" width={1} sx={sx} onSubmit={onSubmit}>
            <Box display="flex" gap={6} width={1}>
                <Box
                    ref={fileInput}
                    component="input"
                    display="none"
                    id="source-file-input"
                    type="file"
                    accept={acceptDocFormats}
                    onChange={onFileChange}
                />
                <TextField
                    value={textFieldValue}
                    onChange={({
                        target,
                    }: React.ChangeEvent<HTMLInputElement>) =>
                        setTextSource(target?.value || '')
                    }
                    onPaste={(event: React.ClipboardEvent) => {
                        if (!event.clipboardData?.files.length) {
                            return;
                        }
                        const [first] = Array.from(event.clipboardData?.files);
                        if (first && first instanceof File) {
                            updateFile(first);
                            event.preventDefault();
                        }
                    }}
                    disabled={loading}
                    aria-label="Knowledge Source"
                    placeholder="Paste link, article text or attach file"
                    InputProps={{
                        ref: textInput,
                        endAdornment,
                        startAdornment,
                        readOnly: !!file,
                    }}
                    sx={{ flexGrow: 1 }}
                    helperText={
                        errorMessage || 'PDF, doc and csv files are supported'
                    }
                    error={errorMessage.length > 1}
                />
                <div>
                    <Button
                        type="submit"
                        disabled={loading}
                        sx={{ minWidth: 140 }}
                        variant="contained"
                    >
                        Save
                    </Button>
                </div>
            </Box>
        </Box>
    );
};
