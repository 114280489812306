import { Avatar, AvatarProps } from '@mui/material';
import avatarPlaceholder from '../../assets/newAssets/icons/avatarPlaceholder.svg';
import { useMemo } from 'react';
import { getAvatarGradient } from '../../elements/Avatar/get-avatar-color';
import { Size, getFont } from './utils';
import { getSquare } from '../../theme/style.helpers';
import { getInitials } from '../../main/campaign/v3/string.helpers';

type Props = AvatarProps & {
    imageUrl?: string;
    size: Size;
    variant?: 'circular' | 'rounded';
    username?: string;
    placeholderUrl?: string;
};

export function UserAvatar({
    imageUrl,
    placeholderUrl = avatarPlaceholder,
    variant = 'circular',
    size,
    username,
    ...props
}: Props) {
    const background = useMemo(() => {
        if (imageUrl || !username) {
            return undefined;
        }

        return getAvatarGradient(username ?? '');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [username]);

    const initials = useMemo(() => {
        if (!username) {
            return undefined;
        }

        return getInitials(username);
    }, [username]);

    const url = useMemo(() => {
        if (imageUrl) {
            return imageUrl;
        }

        if (initials) {
            return undefined;
        }

        return placeholderUrl;
    }, [initials, imageUrl, placeholderUrl]);

    return (
        <Avatar
            {...props}
            alt={props.alt || username}
            src={url}
            variant={variant}
            sx={{
                background,
                ...getSquare(size),
                ...getFont(size),
            }}
        >
            {initials}
        </Avatar>
    );
}
