import {
    useRcsTyping,
    useUserTyping,
} from '../ConversationRow/use-conversation-typing';
import { Collapse, SxProps, Typography } from '@mui/material';

type TypingIndicatorProps = {
    conversationId: string;
};

const indicator: SxProps = {
    display: 'block',
    mb: 2,
    px: 7,
    color: 'custom.gray.super',
};

export const TypingIndicator = (props: TypingIndicatorProps) => {
    const userTyping = useUserTyping(props.conversationId);

    if (!userTyping) {
        return null;
    }

    return (
        <Collapse in={!!userTyping}>
            <Typography variant="body4" sx={indicator}>
                {userTyping} is typing ...
            </Typography>
        </Collapse>
    );
};

export const RcsTypingIndicator = (props: TypingIndicatorProps) => {
    const rcsTyping = useRcsTyping(props.conversationId);

    if (!rcsTyping) {
        return null;
    }

    /* TODO: Return a new component with a typing bubble here.
     * We'll probably want to mimic the layout of ChatMessage
     * (pages/NewInbox/Conversation/Message/ChatMessage), which
     * will require us to refactor that class to pull out more
     * generalized components for reuse outside of a message
     * context.
     */
    return (
        <Collapse in={!!rcsTyping}>
            <Typography variant="body4" sx={indicator}>
                {rcsTyping} is typing ...
            </Typography>
        </Collapse>
    );
};
