import { useCallback, useState } from 'react';
import { useRequestMsTeamsAdminAccess } from '../../../../queries/integrations';
import { useTrack } from '../../../../contexts/analytics';
import { useWorkspaceOwnersAndAdmins } from '../../../../hooks/users';

export const useSendAdminEmail = () => {
    const { mutateAsync: requestAdminAccess } = useRequestMsTeamsAdminAccess();
    const track = useTrack();
    const [isSend, setIsSend] = useState(false);
    const workspaceOwners = useWorkspaceOwnersAndAdmins();

    const sendEmail = useCallback(() => {
        workspaceOwners.forEach((wo) => {
            if (wo?.email) {
                setIsSend(true);
                requestAdminAccess(wo.email).then(() => {
                    setIsSend(true);
                });
                track('onboarding_teams_request_admin_access', {
                    admin_email: wo.email,
                });
            }
        });
    }, [requestAdminAccess, track, workspaceOwners]);

    return { isSend, sendEmail };
};
