import { useState } from 'react';
import {
    CircularProgress,
    IconButton,
    Stack,
    TableCell,
    TableRow,
    Theme,
    Typography,
    useMediaQuery,
} from '@mui/material';
import { UserAvatar } from '../../../../components/UserAvatar/UserAvatar';
import { userName } from '../../../../helpers/formatting';
import {
    ClerkPermission,
    Feature,
    Profile,
    RoleTypes,
} from '../../../../api/types';
import { TableSelect } from '../../../../components/TableSelect/TableSelect';
import { rolesOptions, roleLabels } from '../utils';
import {
    useAddUserToInbox,
    useInboxAssignmentsForUser,
    useInboxes,
    useRemoveUserFromInbox,
} from '../../../../queries/inboxes';
import { useInactivateUser, useMeQueryData } from '../../../../queries/user';
import { useUpdateRole } from '../../../../queries/roles';
import { getFullName } from '../../../../utils/user';
import { TrashCanIcon } from '../../../../icons/shared/TrashCanIcon';
import { AttentionIcon } from './AttentionIcon';
import { GenericDialog } from '../../../../components/Modals/GenericDialog/GenericDialog';
import { WithFeature } from '../../../../containers/Feature/WithFeature';
import { ShowUpLg } from '../../../../components/MediaHelpers/ShowUpLg';
import { HideDownSm } from '../../../../components/MediaHelpers/HideDownSm';
import { formatPhoneNumber } from '../../../../utils/phoneNumber';
import {
    usePermission,
    WithPermission,
} from '../../../../containers/WithPermission/WithPermission';

type Props = { user: Profile };

export const UserRow = ({ user }: Props) => {
    const authId = useMeQueryData()?.id;
    const isMd = useMediaQuery<Theme>((theme) => theme.breakpoints.up('md'));
    const [showConfirmation, setShowConfirmation] = useState(false);
    const { data: inboxes = [] } = useInboxes();
    const { data: associations = [] } = useInboxAssignmentsForUser(user.id);
    const { mutate: removeUser, isPending } = useInactivateUser();
    const { mutate: updateRole } = useUpdateRole();
    const { mutate: removeFromInbox } = useRemoveUserFromInbox();
    const { mutate: addToInbox } = useAddUserToInbox();
    const canModify = usePermission(ClerkPermission.ModifyTeamMember);

    const role = user.role?.type;

    return (
        <>
            <TableRow key={user.id} sx={{ height: 40 }}>
                <TableCell>
                    <Stack spacing={4} direction="row">
                        <ShowUpLg>
                            <UserAvatar size={40} imageUrl={user.avatar?.url} />
                        </ShowUpLg>
                        <div>
                            <Typography
                                variant="body3"
                                color="primary.dark"
                                noWrap
                            >
                                {userName(user)}
                            </Typography>
                            <Typography
                                noWrap
                                variant="body4"
                                color="custom.gray.super"
                                component="div"
                            >
                                {user.email}
                            </Typography>
                        </div>
                    </Stack>
                </TableCell>
                <WithPermission
                    requiredPermissions={[
                        ClerkPermission.ModifyPublicInbox,
                        ClerkPermission.ModifyPrivateInbox,
                    ]}
                >
                    <HideDownSm>
                        <TableCell>
                            {role !== RoleTypes.Admin && (
                                <TableSelect
                                    width={isMd ? 200 : undefined}
                                    variant="standard"
                                    multiple
                                    hasCheckbox
                                    searchable
                                    placeholder="Not assigned"
                                    value={associations.map(
                                        ({ inboxId }) => inboxId,
                                    )}
                                    onChange={(inboxId, check) => {
                                        if (check) {
                                            addToInbox({
                                                inboxId,
                                                userId: user.id,
                                            });
                                        } else {
                                            removeFromInbox({
                                                inboxId,
                                                userId: user.id,
                                            });
                                        }
                                    }}
                                    options={inboxes.map((inbox) => ({
                                        id: inbox.id,
                                        label: inbox.name || inbox.phone,
                                        description: formatPhoneNumber(
                                            inbox.phone,
                                        ),
                                    }))}
                                />
                            )}
                        </TableCell>
                    </HideDownSm>
                </WithPermission>
                <TableCell>
                    {canModify ? (
                        <TableSelect
                            width={isMd ? 180 : undefined}
                            variant="standard"
                            placeholder="Not assigned"
                            value={role}
                            onChange={(value) => {
                                updateRole({
                                    userId: user.id,
                                    role: value as RoleTypes,
                                });
                            }}
                            disabled={authId === user.id}
                            options={rolesOptions}
                            menuFullHeight
                            footer={
                                <a
                                    href="https://support.clerk.chat/articles/4839678-team-management"
                                    target="_blank"
                                    rel="noreferrer noopener"
                                >
                                    More about roles ↗
                                </a>
                            }
                        />
                    ) : (
                        <Typography variant="body3">
                            {role ? roleLabels[role] : 'Not assigned'}
                        </Typography>
                    )}
                </TableCell>
                <TableCell>
                    <WithFeature feature={Feature.RemoveTeammate}>
                        <WithPermission
                            requiredPermissions={[
                                ClerkPermission.RemoveTeamMembers,
                            ]}
                        >
                            <IconButton
                                sx={{
                                    padding: 0,
                                    color: 'custom.gray.super',
                                    '&:hover': { color: 'error.main' },
                                }}
                                disabled={authId === user.id || isPending}
                                onClick={() => setShowConfirmation(true)}
                            >
                                {isPending || showConfirmation ? (
                                    <CircularProgress size={20} />
                                ) : (
                                    <TrashCanIcon
                                        sx={{ width: 20, height: 20 }}
                                    />
                                )}
                            </IconButton>
                        </WithPermission>
                    </WithFeature>
                </TableCell>
            </TableRow>
            <WithFeature feature={Feature.RemoveTeammate}>
                <WithPermission
                    requiredPermissions={[ClerkPermission.RemoveTeamMembers]}
                >
                    <GenericDialog
                        confirmLabel="Yes, delete"
                        confirmButtonProps={{
                            startIcon: <TrashCanIcon />,
                            color: 'error',
                            variant: 'outlined',
                        }}
                        onConfirm={() => {
                            setShowConfirmation(false);
                            removeUser(user.id);
                        }}
                        open={showConfirmation}
                        onClose={() => setShowConfirmation(false)}
                        IconComponent={AttentionIcon}
                        title="Delete a member"
                        description={
                            <>
                                Are you sure want to delete{' '}
                                <Typography variant="h4" component="span">
                                    {getFullName(user)}
                                </Typography>
                                ? This action cannot be undone.
                            </>
                        }
                    />
                </WithPermission>
            </WithFeature>
        </>
    );
};
