import {
    Source,
    useSourceFileMutation,
    useTextSourceMutation,
} from '../queries/source';
import {
    ChangeEvent,
    Dispatch,
    FormEvent,
    ReactNode,
    RefObject,
    SetStateAction,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { isAllowed } from '../main/settings/KnowledgeBase/SourceForm/mime-types';
import { prettySize } from '../main/settings/KnowledgeBase/SourceForm/pretty-bytes';
import { isURL } from '../main/settings/KnowledgeBase/SourceForm/is-url.helper';
import { WebsiteIcon } from '../main/settings/KnowledgeBase/List/SourceIcon';
import { TextSourceIcon } from '../icons/common/files/ExtensionIcon';
import { getMimeIcon } from '../main/settings/KnowledgeBase/SourceForm/mime-icon.factory';
import {
    Box,
    CircularProgress,
    IconButton,
    InputAdornment,
    Tooltip,
} from '@mui/material';
import { attachmentButton } from '../main/settings/KnowledgeBase/SourceForm/styles';
import CloseIcon from '@mui/icons-material/Close';
import { getSquare } from '../theme/style.helpers';
import { AttachmentIcon } from '../icons/common/CAttachmentIcon';

const ONE_HUNDRED_MEGABYTES = 100_000_000;

export type KnowledgeBaseSourceFormProps = {
    file: File | null;
    fileInput: RefObject<HTMLInputElement | null>;
    textInput: RefObject<HTMLInputElement | null>;
    setTextSource: Dispatch<SetStateAction<string>>;
    updateFile: (file?: File) => void;
    loading: boolean;
    onSubmit: (event: FormEvent) => void;
    textFieldValue: string;
    onFileChange: (event: ChangeEvent<HTMLInputElement>) => void;
    errorMessage?: string;
    startAdornment: ReactNode;
    endAdornment: ReactNode;
};

export function useKnowledgeBaseSourceForm({
    onSubmitted,
}: {
    onSubmitted?: (source: Source) => void;
} = {}): KnowledgeBaseSourceFormProps {
    const fileMutation = useSourceFileMutation();
    const textSourceMutation = useTextSourceMutation();
    const fileInput = useRef<HTMLInputElement>(null);
    const textInput = useRef<HTMLInputElement>(null);

    const [file, setFile] = useState<File | null>(null);
    const [textSource, setTextSource] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string>('');

    const submitFile = () => {
        setErrorMessage('');
        if (!file) {
            fileInput.current?.querySelector('input')?.focus();
            setErrorMessage('Please add URL, File or text');
            return;
        }
        if (!isAllowed(file.type)) {
            textInput.current?.querySelector('input')?.focus();
            setErrorMessage('File should be PDF, DOC or csv format');
            return;
        }
        if (file.size > ONE_HUNDRED_MEGABYTES) {
            textInput.current?.querySelector('input')?.focus();
            setErrorMessage(
                `File should be less than ${prettySize(ONE_HUNDRED_MEGABYTES)}`,
            );
            return;
        }
        fileMutation.mutateAsync(file).then((result) => {
            onSubmitted?.(result);
        });
    };

    const submitText = () => {
        setErrorMessage('');
        if (!textSource) {
            textInput.current?.querySelector('input')?.focus();
            setErrorMessage('Please enter a text or add a file');
            return;
        }
        if (
            !isURL(textSource) &&
            (textSource.length < 120 || textSource.length > 65_000)
        ) {
            textInput.current?.querySelector('input')?.focus();
            setErrorMessage(
                'Please enter a text between 120 and 65000 characters',
            );
            return;
        }
        textSourceMutation.mutateAsync(textSource).then((result) => {
            onSubmitted?.(result);
        });
    };

    const updateFile = (file?: File) => {
        setTextSource('');
        setFile(file ?? null);
    };

    const onFileChange = ({ currentTarget }: ChangeEvent<HTMLInputElement>) => {
        if (!currentTarget) {
            return;
        }
        updateFile(currentTarget?.files?.[0]);
    };

    const textFieldValue = file
        ? `${file.name} (${prettySize(file.size)})`
        : textSource;
    const clear = () => {
        setFile(null);
        setTextSource('');
        setErrorMessage('');
        if (fileInput.current) {
            fileInput.current.value = '';
        }
    };

    const onSubmit = (event: FormEvent) => {
        event.preventDefault();
        if (!file && !textSource) {
            textInput.current?.querySelector('input')?.focus();
            setErrorMessage('Please add URL, File or text');
            return;
        }
        if (file) {
            submitFile();
            return;
        }
        submitText();
    };

    useEffect(() => {
        if (fileMutation.isSuccess || textSourceMutation.isSuccess) {
            clear();
        }
    }, [fileMutation.isSuccess, textSourceMutation.isSuccess]);

    const StartIcon = useMemo(
        () => (isURL(textSource) ? WebsiteIcon : TextSourceIcon),
        [textSource],
    );
    const MimeIcon = useMemo(() => getMimeIcon(file?.type), [file?.type]);
    const loading = fileMutation.isPending || textSourceMutation.isPending;

    const fileAdornment = file ? (
        <Box mx={1} alignItems="center" display="flex">
            <MimeIcon />
        </Box>
    ) : (
        <Tooltip title="Attach">
            <IconButton
                component="label"
                htmlFor="source-file-input"
                aria-label="Attach"
                size="small"
                sx={attachmentButton}
            >
                <AttachmentIcon sx={getSquare(20)} />
            </IconButton>
        </Tooltip>
    );

    const startAdornment = (
        <InputAdornment position="start">
            {textSource ? (
                <Box mx={1} alignItems="center" display="flex">
                    <StartIcon />
                </Box>
            ) : (
                fileAdornment
            )}
        </InputAdornment>
    );

    const endAdornment = useMemo(() => {
        if (loading) {
            return <CircularProgress color="orange" size={20} />;
        }
        return textFieldValue || errorMessage ? (
            <InputAdornment position="end">
                <Tooltip title="Clear">
                    <span>
                        <IconButton
                            aria-label="Clear"
                            size="small"
                            onClick={clear}
                            disabled={loading}
                        >
                            <CloseIcon />
                        </IconButton>
                    </span>
                </Tooltip>
            </InputAdornment>
        ) : undefined;
    }, [errorMessage, loading, textFieldValue]);

    return {
        file,
        fileInput,
        textInput,
        setTextSource,
        updateFile,
        loading,
        onSubmit,
        textFieldValue,
        onFileChange,
        errorMessage,
        startAdornment,
        endAdornment,
    };
}
