import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export const TriggerIcon = (props: Exclude<SvgIconProps, 'component'>) => (
    <SvgIcon inheritViewBox {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 3.125C6.20304 3.125 3.125 6.20304 3.125 10C3.125 13.797 6.20304 16.875 10 16.875C13.797 16.875 16.875 13.797 16.875 10C16.875 6.20304 13.797 3.125 10 3.125ZM1.875 10C1.875 5.51269 5.51269 1.875 10 1.875C14.4873 1.875 18.125 5.51269 18.125 10C18.125 14.4873 14.4873 18.125 10 18.125C5.51269 18.125 1.875 14.4873 1.875 10Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.0087 5.37459C11.022 5.26645 10.9578 5.16319 10.8546 5.12831C10.7513 5.09343 10.6376 5.13598 10.5825 5.23017C9.672 6.79087 7.92363 9.78808 7.332 10.8025C7.29084 10.8737 7.29014 10.9616 7.33131 11.0327C7.37177 11.1039 7.44782 11.1478 7.53014 11.1478H9.42573C9.42573 11.1478 9.16131 13.2674 8.99107 14.6257C8.97782 14.7339 9.042 14.8371 9.14526 14.872C9.24852 14.9069 9.36224 14.8644 9.41735 14.7702C10.3278 13.2095 12.0762 10.2123 12.6678 9.19784C12.709 9.12668 12.7097 9.03877 12.6685 8.96761C12.6281 8.89645 12.552 8.85249 12.4697 8.85249C11.8118 8.85249 10.5741 8.85249 10.5741 8.85249C10.5741 8.85249 10.8385 6.73296 11.0087 5.37459Z"
                fill="currentColor"
            />
        </svg>
    </SvgIcon>
);
