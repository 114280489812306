import { Activity, Status } from '../components/ActivityConstructor/types';
import { client } from './http';

export type ActivityResponse = {
    data: Activity[];
    links: Record<string, string>;
    total: number;
};

export function getActivities(): Promise<ActivityResponse> {
    return client.get('/activity').then((res) => res.data);
}

export function markAllActivitiesAsRead(): Promise<ActivityResponse> {
    return client
        .patch('/activity', {
            status: Status.Read,
        })
        .then((res) => res.data);
}

export function markActivityAsRead(id: string): Promise<Activity> {
    return client
        .patch(`/activity/${id}`, {
            status: Status.Read,
        })
        .then((res) => res.data);
}

export function countUnread(): Promise<number> {
    return client.get('/activity/count').then((res) => res.data.unread);
}
