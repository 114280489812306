import { useState } from 'react';
import {
    useAddContactsToCohortsMutation,
    useCohortsQueryData,
} from '../../queries/cohorts';
import { UUID } from '../../types/uuid';

import { DialogAttentionIcon } from '../../elements/Dialog/DialogAttentionIcon';
import { DialogTitleTwoLine } from '../../elements/Dialog/DialogTitleTwoLine';
import { ButtonDotLoader } from '../Forms/ButtonDotLoader';
import {
    FormControlLabel,
    List,
    ListItem,
    Typography,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
} from '@mui/material';

interface ContactsMoveDialogProps {
    selected: Set<UUID>;
    onClose: () => void;
}

export const ContactsMoveDialog = ({
    selected,
    onClose,
}: ContactsMoveDialogProps) => {
    const [selectedCohorts, setSelectedCohorts] = useState<number[]>([]);
    const cohorts = useCohortsQueryData();

    const { mutateAsync: mutate, isPending } =
        useAddContactsToCohortsMutation();

    return (
        <Dialog open={true} onClose={onClose} maxWidth="xs" fullWidth>
            <DialogAttentionIcon color="primary" />
            <DialogTitleTwoLine
                title="Add contacts to cohort"
                subtitle={
                    <Typography variant="body3" color="text.secondary">
                        Select a cohort(s) where you want to move contact.
                    </Typography>
                }
            />
            <DialogContent>
                <List>
                    {cohorts.map((cohort) => (
                        <ListItem key={cohort.id} disablePadding>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        size="small"
                                        color="info"
                                        checked={selectedCohorts.includes(
                                            cohort.id,
                                        )}
                                        onClick={() => {
                                            if (
                                                selectedCohorts.includes(
                                                    cohort.id,
                                                )
                                            ) {
                                                setSelectedCohorts(
                                                    selectedCohorts.filter(
                                                        (id) =>
                                                            id !== cohort.id,
                                                    ),
                                                );
                                            } else {
                                                setSelectedCohorts(
                                                    selectedCohorts.concat(
                                                        cohort.id,
                                                    ),
                                                );
                                            }
                                        }}
                                    />
                                }
                                label={cohort.name}
                            />
                        </ListItem>
                    ))}
                </List>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        onClose();
                    }}
                    sx={{ minWidth: 96 }}
                    variant="outlined"
                >
                    Cancel
                </Button>
                <Button
                    color="primary"
                    size="large"
                    onClick={() => {
                        mutate({
                            cohortIds: selectedCohorts,
                            contactIds: Array.from(selected),
                        });
                    }}
                    sx={{ minWidth: 200 }}
                    fullWidth
                    disabled={isPending || selectedCohorts.length === 0}
                    variant="contained"
                >
                    {isPending ? <ButtonDotLoader /> : 'Move'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
