import {
    QueryObserverOptions,
    useMutation,
    useQueries,
    useQuery,
} from '@tanstack/react-query';
import {
    addContactsToCohorts,
    addContactsToCohortsByFilters,
    CohortMetaDto,
    getCohort,
    getContactCohorts,
    removeContactsFromCohorts,
    removeContactsFromCohortsByFilters,
} from '../api/cohorts';
import { Cohort, CohortMeta, RemoteCohortMeta } from '../api/types';
import { queryClient } from './queryClient';
import { UUID } from '../types/uuid';
import { getCohortContactIds } from '../utils/cohorts';
import { client } from '../api/http';
import { NEXT_CONTACTS } from '../components/Contacts/contacts.queries';
import { CreateCohortDto } from '../api/contacts';
import { useTrack } from '../contexts/analytics';

export const COHORTS_KEY = 'cohorts_list';
export const COHORT_KEY = 'cohort';
const REMOTE_COHORTS_KEY = 'remote_cohorts_list';

const COHORT_CREATE_KEY = 'cohort_create';
const COHORT_UPDATE_KEY = 'cohort_update';
const COHORTS_ADD_CONTACTS_KEY = 'cohorts_add_contacts';
const CONTACT_COHORTS_KEY = 'contact_cohorts';

export const useCohorts = (options?: {
    enabled?: boolean;
    select?: QueryObserverOptions<CohortMeta[]>['select'];
}) =>
    useQuery({
        queryKey: [COHORTS_KEY],
        queryFn: () =>
            client.get<CohortMeta[]>('/cohorts').then(({ data }) => data),
        select: options?.select,
        enabled: options?.enabled ?? true,
    });

export function useCohortsQuery() {
    const { data } = useCohorts();

    return data || [];
}

export function useCohortsQueryData() {
    return queryClient.getQueryData<CohortMetaDto[]>([COHORTS_KEY]) || [];
}

export const useRemoteCohorts = () => {
    return useQuery({
        queryKey: [REMOTE_COHORTS_KEY],
        queryFn: () =>
            client
                .get<RemoteCohortMeta[]>('/remote-cohorts')
                .then(({ data }) => data),
    });
};

export const useCohort = (id?: number, options?: { enabled: boolean }) =>
    useQuery({
        queryKey: [COHORT_KEY, id],
        queryFn: () => getCohort(id!),
        enabled: options?.enabled || !!id,
    });

export function useContactIdsFromCohortsQuery(cohortIds: number[]) {
    const result = useQueries({
        queries: cohortIds.map((id) => ({
            queryKey: [COHORT_KEY, id],
            queryFn: () => getCohort(id),
        })),
    });

    const contactIds = [
        ...new Set<string>([
            ...result.reduce(
                (acc: string[], item) =>
                    item.data
                        ? acc.concat(getCohortContactIds(item.data))
                        : acc,
                [],
            ),
        ]),
    ];

    const isPending = result
        .map((item) => item.isPending)
        .some((state) => state);
    const isFetching = result
        .map((item) => item.isFetching)
        .some((state) => state);
    const isError = result.map((item) => item.isError).some((state) => state);

    return {
        data: contactIds,
        isPending,
        isFetching,
        isError,
    };
}

export function useGetCohortsForContact(
    contactId: UUID,
    enabled: boolean = true,
) {
    return useQuery<UUID, unknown, Cohort[]>({
        queryKey: [CONTACT_COHORTS_KEY, contactId],
        queryFn: () => getContactCohorts({ contactId }).then((res) => res.data),
        enabled,
    });
}

export function useCohortCreateQuery() {
    const track = useTrack();

    return useMutation({
        mutationKey: [COHORT_CREATE_KEY],
        mutationFn: (payload: CreateCohortDto) =>
            client
                .post<Cohort>('/v2/cohorts', payload)
                .then(({ data }) => data),
        onMutate: async (cohort) => {
            await queryClient.cancelQueries({ queryKey: [COHORTS_KEY] });

            queryClient.setQueryData<CohortMeta[]>(
                [COHORTS_KEY],
                (cohorts = []) =>
                    cohorts.concat({
                        id: -1,
                        userId: '',
                        name: '',
                        isPublic: true,
                        updated: new Date(),
                        contactsCount: 0,
                        ...cohort,
                    }),
            );
        },
        onSuccess: (data) => {
            track('cohort_created', {
                isPublic: data.isPublic,
                filtersCount: data.query?.length || 0,
                queryTermLength: data.queryTerm?.length || 0,
                contactIdsCount: data.contactIds.length,
                includedCount: data.included.length,
                excludedCount: data.excluded.length,
            });
            queryClient.setQueryData<CohortMeta[]>(
                [COHORTS_KEY],
                (cohorts = []) => {
                    const newCohorts = cohorts.slice();
                    newCohorts[newCohorts.length - 1] = {
                        id: data.id,
                        userId: data.userId,
                        name: data.name,
                        isPublic: data.isPublic,
                        updated: data.updated,
                        contactsCount: data.contactsCount,
                    };
                    return newCohorts;
                },
            );
        },
        onError: () => {
            queryClient.setQueryData<CohortMeta[]>(
                [COHORTS_KEY],
                (cohorts = []) => cohorts.slice(0, -1),
            );
        },
    });
}

export function useCohortUpdateQuery() {
    const track = useTrack();

    return useMutation({
        mutationKey: [COHORT_UPDATE_KEY],
        mutationFn: (payload: Partial<Cohort>) =>
            client.put<Cohort>('/cohorts', payload).then(({ data }) => data),
        onSuccess: (cohort) => {
            track('cohort_updated', { cohortId: cohort.id });
        },
        onMutate: async (updatedCohort) => {
            await queryClient.cancelQueries({ queryKey: [COHORTS_KEY] });
            await queryClient.cancelQueries({
                queryKey: [COHORT_KEY, updatedCohort.id],
            });

            const previousCohorts = queryClient.getQueryData<CohortMeta[]>([
                COHORTS_KEY,
            ]);
            const previousActiveCohort = queryClient.getQueryData<Cohort>([
                COHORT_KEY,
                updatedCohort.id,
            ]);

            queryClient.setQueryData<CohortMeta[]>(
                [COHORTS_KEY],
                (cohorts = []) => {
                    const idx = cohorts.findIndex(
                        ({ id }) => id === updatedCohort.id,
                    );
                    const updatedCohorts = cohorts.slice();
                    updatedCohorts[idx] = {
                        ...updatedCohorts[idx],
                        ...updatedCohort,
                    };

                    return updatedCohorts;
                },
            );

            if (previousActiveCohort) {
                queryClient.setQueryData<Cohort>(
                    [COHORT_KEY, updatedCohort.id],
                    {
                        ...previousActiveCohort,
                        ...updatedCohort,
                    },
                );
            }

            return {
                previousCohorts,
                previousActiveCohort,
            };
        },
        onError: (_error, _updatedCohort, context) => {
            const { previousCohorts = [], previousActiveCohort } =
                context || {};

            queryClient.setQueryData<CohortMeta[]>(
                [COHORTS_KEY],
                () => previousCohorts,
            );

            if (previousActiveCohort) {
                queryClient.setQueryData<Cohort>(
                    [COHORT_KEY, previousActiveCohort.id],
                    previousActiveCohort,
                );
            }
        },
    });
}

export function useCohortDeleteQuery() {
    const track = useTrack();

    return useMutation({
        mutationKey: ['cohort_delete'],
        mutationFn: (id: number) =>
            client.delete(`/cohorts/${id}`).then<void>(({ data }) => data),
        onMutate: async (id: number) => {
            await queryClient.cancelQueries({ queryKey: [COHORTS_KEY] });

            const previousCohorts = queryClient.getQueryData<CohortMeta[]>([
                COHORTS_KEY,
            ]);

            queryClient.setQueryData<CohortMeta[]>(
                [COHORTS_KEY],
                (cohorts = []) => cohorts.filter((c) => c.id !== id),
            );

            return {
                previousCohorts,
            };
        },
        onError: (_error, _updatedCohort, context) => {
            const { previousCohorts = [] } = context || {};
            queryClient.setQueryData<CohortMeta[]>(
                [COHORTS_KEY],
                () => previousCohorts,
            );
        },
        onSuccess: () => {
            track('cohort_deleted');
        },
    });
}

export function useAddContactsToCohortsMutation() {
    return useMutation({
        mutationKey: [COHORTS_ADD_CONTACTS_KEY],
        mutationFn: addContactsToCohorts,
        onSettled: () => {
            queryClient.refetchQueries({ queryKey: [CONTACT_COHORTS_KEY] });
            queryClient.resetQueries({
                queryKey: [NEXT_CONTACTS],
                exact: false,
            });
        },
    });
}

export function useAddContactsToCohortByFiltersMutation() {
    return useMutation({
        mutationKey: [COHORTS_ADD_CONTACTS_KEY],
        mutationFn: addContactsToCohortsByFilters,
        onSettled: () => {
            queryClient.resetQueries({
                queryKey: [NEXT_CONTACTS],
                exact: false,
            });
        },
    });
}

export function useRemoveContactsFromCohortsMutation() {
    return useMutation({
        mutationKey: ['cohorts_remove_contacts'],
        mutationFn: removeContactsFromCohorts,
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: [CONTACT_COHORTS_KEY] });
            queryClient.resetQueries({
                queryKey: [NEXT_CONTACTS],
                exact: false,
            });
        },
    });
}

export function useRemoveContactsFromCohortsByFiltersMutation() {
    return useMutation({
        mutationKey: ['cohorts_remove_contacts'],
        mutationFn: removeContactsFromCohortsByFilters,
        onSettled: () => {
            queryClient.resetQueries({
                queryKey: [NEXT_CONTACTS],
                exact: false,
            });
        },
    });
}
