import { forwardRef } from 'react';
import { List } from '@mui/material';
import { Virtuoso } from 'react-virtuoso';
import { Inbox } from '../../../api/types';
import { InboxDraggableItem } from '../../../elements/InboxItem/InboxDraggableItem';
import { list } from './styles';

interface VirtualizedInboxListProps {
    inboxes: Inbox[];
    onInboxClick: () => void;
}

const VirtualizedInboxList = ({
    inboxes,
    onInboxClick,
}: VirtualizedInboxListProps) => {
    return (
        <Virtuoso
            data-testid="virtualized-list"
            data={inboxes}
            components={{
                List: forwardRef(function VirtuosoList(props, ref) {
                    return (
                        <List {...props} component="div" ref={ref} sx={list} />
                    );
                }),
            }}
            itemContent={(index, inbox: Inbox) => (
                <InboxDraggableItem
                    key={inbox.id}
                    index={index}
                    inbox={inbox}
                    onClick={onInboxClick}
                />
            )}
            overscan={200}
            increaseViewportBy={200}
        />
    );
};

export default VirtualizedInboxList;
