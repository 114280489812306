import cc from 'classcat';
import { useNavigate } from 'react-router';
import { useEffect, useMemo } from 'react';
import { Contact } from '../../api/types';
import { Chat } from '../../components/Chat/Chat';
import { CampaignConversationsList } from '../../components/ConversationsList';
import { useContactsQueryData } from '../../queries/contacts';
import { useConversation } from '../../queries/conversations';
import styles from './Campaign.module.scss';
import { Campaign } from '../../api/campaign.types';
import { getByPhoneOrEmpty } from '../../queries/contact-library.helpers';

const isDisabled = (contacts: Contact[]) =>
    contacts.length === 1 && contacts[0].optOut;

interface CampaignInnerProps {
    campaign?: Campaign;
    conversationId: string;
}

export const CampaignInner = ({
    campaign,
    conversationId,
}: CampaignInnerProps) => {
    const { data: conversation } = useConversation(conversationId);
    const contacts = useContactsQueryData();
    const navigate = useNavigate();

    const memberContacts = useMemo(() => {
        return conversation?.members
            ? (conversation?.members.map((member) =>
                  getByPhoneOrEmpty(member, contacts),
              ) as Contact[])
            : [];
    }, [contacts, conversation?.members]);

    useEffect(() => {
        if (conversation && conversation.status === 'deleted') {
            navigate(`/campaigns/${campaign?.id}`);
        }
    }, [campaign?.id, conversation, conversationId, navigate]);

    return (
        <div className={styles['campaigns']}>
            <div>
                {campaign && <CampaignConversationsList campaign={campaign} />}
            </div>

            <section
                className={cc([
                    styles['chat'],
                    {
                        [styles['chat_empty']]: !conversation,
                        [styles['campaigns_mobile-hidden']]: !conversation,
                    },
                ])}
            >
                {conversationId && conversation && (
                    <Chat
                        conversation={conversation}
                        onBackClick={() =>
                            navigate(`/campaigns/${campaign?.id}`)
                        }
                        messageFormDisabled={isDisabled(memberContacts)}
                    />
                )}
                {!conversationId && (
                    <p className={styles['campaigns__chat-placeholder']}>
                        Choose chat to start conversation
                    </p>
                )}
            </section>
        </div>
    );
};
