import {
    Chip,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    Stack,
} from '@mui/material';
import { DialogAttentionIcon } from '../../../../elements/Dialog/DialogAttentionIcon';
import { DialogTitleTwoLine } from '../../../../elements/Dialog/DialogTitleTwoLine';
import { SourceIcon } from './SourceIcon';
import Button from '@mui/material/Button';
import { DeleteIcon } from '../../../../icons/common/CDeleteIcon';
import { Source, useDeleteSourceMutation } from '../../../../queries/source';
import { useState } from 'react';

export function DeleteSourceItemModal({
    sources,
    onClose,
    open = false,
}: {
    sources: Source[];
    onClose: () => void;
    open?: boolean;
}) {
    const deletion = useDeleteSourceMutation();
    const [isPending, setIsPending] = useState(false);

    return (
        <Dialog
            open={open}
            maxWidth="xs"
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogAttentionIcon />
            <DialogTitleTwoLine
                title="Confirm source deletion"
                subtitle="You’re trying to delete a file that affects the assistant’s knowledge. Are you sure you want to delete a source?"
            />
            <DialogContent sx={{ textAlign: 'center' }}>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    flexWrap="wrap"
                    spacing={2}
                    useFlexGap
                >
                    {sources.map((source) => (
                        <Chip
                            key={source.id}
                            variant="outlined"
                            icon={<SourceIcon type={source.type} />}
                            label={source.title}
                            sx={{
                                maxWidth: 320,
                            }}
                        />
                    ))}
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button
                    sx={{ minWidth: 96 }}
                    disabled={isPending}
                    variant="outlined"
                    onClick={onClose}
                    autoFocus
                >
                    Cancel
                </Button>
                <Button
                    color="error"
                    startIcon={
                        isPending ? (
                            <CircularProgress color="error" size={18} />
                        ) : (
                            <DeleteIcon />
                        )
                    }
                    disabled={isPending}
                    onClick={async () => {
                        setIsPending(true);
                        await Promise.all(
                            sources.map((source) =>
                                deletion.mutateAsync(source.id),
                            ),
                        );
                        setIsPending(false);
                        onClose();
                    }}
                    variant="outlined"
                    fullWidth
                >
                    Yes, Delete
                </Button>
            </DialogActions>
        </Dialog>
    );
}
