import { useState } from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Typography,
    SxProps,
    Theme,
} from '@mui/material';
import { ExpandMoreFilledIcon } from '../../icons/common/ExpandMoreFilled';
import { getSquare } from '../../theme/style.helpers';
import { colors } from '../../theme/palette';

interface CollapsibleAccordionSectionProps {
    title: string;
    children: React.ReactNode;
    defaultExpanded?: boolean;
    maxContentHeight?: number;
    sx?: SxProps<Theme>;
}

export const CollapsibleAccordionSection = ({
    title,
    children,
    defaultExpanded = true,
    maxContentHeight,
    sx,
}: CollapsibleAccordionSectionProps) => {
    const [isClosed, setIsClosed] = useState(false);

    return (
        <Accordion
            defaultExpanded={defaultExpanded}
            disableGutters
            square
            onChange={() => {
                setIsClosed(!isClosed);
            }}
            sx={{
                background: colors.lightGrey[50],
                '&.MuiAccordion-root:before': {
                    display: 'none',
                },
                boxShadow: 'none',
                position: 'relative',
                '& .MuiCollapse-root, & .MuiCollapse-wrapper, & .MuiAccordion-region':
                    {
                        height: maxContentHeight,
                    },
                ...sx,
                borderBottom: `1px solid ${colors.primary.gray.divider}`,
            }}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreFilledIcon sx={getSquare(20)} />}
                id="panel-header"
                aria-controls="panel-content"
                sx={{
                    minHeight: 20,
                    my: 3,
                    mx: 2,
                    '& .MuiAccordionSummary-content': { m: 0 },
                }}
            >
                <Typography variant="body4" color={colors.text.secondary}>
                    {title}
                </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ p: 0, pb: 4 }}>{children}</AccordionDetails>
        </Accordion>
    );
};
