import {
    AssignedConversation,
    AssignedConversationActivity,
} from './Activities/AssignedConversations';
import {
    SentTeamInvite,
    SentTeamInviteActivity,
} from './Activities/SentTeamInvite';

export type Activity = AssignedConversationActivity | SentTeamInviteActivity;

export enum ActivityType {
    AssignedConversation = 'assignedConversation',
    SentTeamInvite = 'sentTeamInvite',
    ContactAttributeExtracted = 'contactAttributeExtracted',
}

export enum ActivityCategories {
    AssignedToMe = 'assignedToMe',
    Conversation = 'conversation',
}

export enum Status {
    Read = 'read',
    Unread = 'unread',
}

export interface ActivityPropsMap {
    [ActivityType.AssignedConversation]: AssignedConversationActivity;
    [ActivityType.SentTeamInvite]: SentTeamInviteActivity;
}

export const ActivityComponentMap: {
    [K in keyof ActivityPropsMap]: React.FC<ActivityPropsMap[K]>;
} = {
    [ActivityType.AssignedConversation]: AssignedConversation,
    [ActivityType.SentTeamInvite]: SentTeamInvite,
};
