import { Components, Theme } from '@mui/material';

export const MuiFormHelperText: Components<Theme>['MuiFormHelperText'] = {
    styleOverrides: {
        root: ({ theme }) => ({
            ...theme.typography.body4,
            marginLeft: 0,
            textTransform: 'initial',
            marginTop: theme.spacing(1),
        }),
    },
};
