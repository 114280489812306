import NavigationItem from '../../../elements/Navigation';
import { AnalyticsIcon } from './AnalyticsIcon';
import { ClerkPermission, Feature, RoleTypes } from '../../../api/types';
import { useTrack } from '../../../contexts/analytics';
import { SearchFilledIcon } from '../../../icons/common/SearchFilledIcon';
import { useState } from 'react';
import { SearchDialog } from '../../SearchDialog/SearchDialog';
import { WorkflowsIcon } from '../../../icons/common/CWorkflows';
import { SettingsIcon } from '../../../components/Inbox/CSettingsIcon';
import {
    navItemActiveStyle,
    navItemIconForNarrowScreens,
    navItemStyle,
} from '../../../elements/Navigation/styles';
import {
    Badge,
    Box,
    Button,
    Chip,
    CircularProgress,
    ListItemIcon,
    MenuItem,
    Stack,
    Tooltip,
    Typography,
} from '@mui/material';
import { useEnabledFeature, useMeQueryData } from '../../../queries/user';
import { AdapterLink } from '../../../components/ConversationsList/AdapterLink';
import { getSquare } from '../../../theme/style.helpers';
import { useOnboardingData } from '../../../hooks/useOnboardingData';
import { useUpdateOnboardingData } from '../../../hooks/useUpdateOnboardingData';
import { WithFeature } from '../../Feature/WithFeature';
import { WithoutFeature } from '../../Feature/WithoutFeature';
import { ContactsIcon } from '../../../icons/common/CContactsIcon';
import { CompanyRegistrationTooltip } from '../../../components/CompanyRegistrationTooltip/CompanyRegistrationTooltip';
import { useIsCampaignRegistered } from '../../../hooks/useIsCampaignRegistered';
import { SubscribersOnly } from '../../SubscribersOnly/SubscribersOnly';
import { WithPermission } from '../../WithPermission/WithPermission';
import { useSidebar } from '../../../contexts/sidebar';
import { useIsDownMd } from '../../../hooks/useIsDownMd';
import { CampaignsIcon } from '../../../icons/common/CCampaigns';
import { AIAssistantIcon } from '../../../icons/common/CAIAssistantIcon';
import { Activity } from '../../../icons/common/CActivity';
import { Indicator } from '../../../components/common/Indicator';
import { useUnreadActivitiesCount } from '../../../queries/activity';
import { isNumber } from 'lodash/fp';

interface Props {
    setMenuOpen: (isOpen: boolean) => void;
}

const IS_MAC = window.navigator.userAgent.indexOf('Mac OS');

export const SideBarNavigation = ({ setMenuOpen }: Props) => {
    const track = useTrack();
    const [isSearchOpen, setIsSearchOpen] = useState<boolean>(false);
    const canManageBilling = useEnabledFeature(Feature.ManageBilling);
    const { isCollapsed } = useSidebar();
    const isMobile = useIsDownMd();
    const isShrinkMode = isCollapsed && !isMobile;
    const { data: unreadActivitiesCount } = useUnreadActivitiesCount();
    const me = useMeQueryData();
    useUpdateOnboardingData();
    const {
        score,
        finished,
        total,
        onboarding,
        isOnboardingBlockedForOldTeams,
    } = useOnboardingData();

    const isCampaignRegistered = useIsCampaignRegistered();

    return (
        <Box
            component="nav"
            sx={{
                backgroundColor: 'custom.gray.superLight',
                paddingY: 1,
                display: 'grid',
                gridGap: 4,
                gridTemplateColumns: '100%',
            }}
        >
            {!isShrinkMode &&
                !!onboarding &&
                score < 100 &&
                canManageBilling &&
                !isOnboardingBlockedForOldTeams &&
                [RoleTypes.WorkspaceOwner, RoleTypes.Admin].includes(
                    me?.activeRole?.type ?? RoleTypes.Member,
                ) && (
                    <Button
                        sx={{
                            elevate: 1,
                            bgcolor: '#fff',
                            width: 'auto',
                            mx: 2,
                            my: 3,
                            display: 'flex',
                            justifyContent: 'flex-start',
                            '&:focus-within:not(.Mui-disabled)': {
                                bgcolor: '#fff',
                            },
                            '&:hover:not(.Mui-disabled)': {
                                bgcolor: 'primary.hover',
                            },
                        }}
                        variant="outlined"
                        onClick={() => {
                            track('clicked_onboarding_page');
                        }}
                        startIcon={
                            <Box
                                sx={{
                                    ...getSquare(20),
                                    bgcolor: 'custom.action.disabledBackground',
                                    borderRadius: '50%',
                                }}
                            >
                                <CircularProgress
                                    variant="determinate"
                                    size={20}
                                    value={100}
                                    sx={{
                                        position: 'absolute',
                                        color: 'passive.focusVisible',
                                    }}
                                />
                                <CircularProgress
                                    size={20}
                                    color="info"
                                    variant="determinate"
                                    value={score}
                                />
                            </Box>
                        }
                        href="/onboarding-progress"
                        LinkComponent={AdapterLink}
                    >
                        <Typography
                            variant="body3"
                            sx={{ ml: 2, color: 'info.main' }}
                        >
                            Onboarding
                        </Typography>
                        <Typography
                            variant="body4"
                            color="text.secondary"
                            sx={{ ml: 'auto' }}
                        >
                            {finished} of {total}
                        </Typography>
                    </Button>
                )}
            <Tooltip
                disableFocusListener
                arrow
                title={
                    <Stack>
                        <Typography variant="body4">Search</Typography>
                        <Typography
                            variant="body5"
                            noWrap
                            color="custom.gray.disabledText"
                        >
                            {`${IS_MAC ? 'Command' : 'CTRL'} + K`}
                        </Typography>
                    </Stack>
                }
                placement="right"
            >
                <MenuItem
                    sx={isSearchOpen ? navItemActiveStyle : navItemStyle}
                    onClick={() => {
                        track('clicked_search_sidebar');
                        setIsSearchOpen((prev) => !prev);
                    }}
                    data-navigation-element
                >
                    <ListItemIcon
                        sx={
                            isShrinkMode
                                ? navItemIconForNarrowScreens
                                : undefined
                        }
                    >
                        <SearchFilledIcon />
                    </ListItemIcon>
                    {!isShrinkMode && 'Search'}
                </MenuItem>
            </Tooltip>
            <SearchDialog open={isSearchOpen} setOpen={setIsSearchOpen} />
            <WithFeature feature={Feature.ActivityFeed}>
                <NavigationItem
                    to="/activity"
                    icon={
                        <Badge
                            badgeContent={
                                unreadActivitiesCount &&
                                unreadActivitiesCount > 0
                                    ? unreadActivitiesCount
                                    : undefined
                            }
                            color="error"
                            sx={{ width: 'auto' }}
                            invisible={
                                !isShrinkMode ||
                                !isNumber(unreadActivitiesCount)
                            }
                            showZero={false}
                        >
                            <Activity />
                        </Badge>
                    }
                    onClick={() => {
                        track('clicked_activity_sidebar');
                        setMenuOpen(false);
                    }}
                    label="Activity"
                    keyboardNav
                >
                    {isNumber(unreadActivitiesCount) &&
                        unreadActivitiesCount > 0 && (
                            <Indicator data-testid="activity-unread-count">
                                {unreadActivitiesCount}
                            </Indicator>
                        )}
                </NavigationItem>
            </WithFeature>
            <WithoutFeature feature={Feature.NextContacts}>
                <NavigationItem
                    to="/contacts"
                    icon={<ContactsIcon />}
                    onClick={() => {
                        track('clicked_contacts_sidebar');
                        setMenuOpen(false);
                    }}
                    label="Contacts"
                    keyboardNav
                />
            </WithoutFeature>
            <WithFeature feature={Feature.NextContacts}>
                <NavigationItem
                    icon={<ContactsIcon />}
                    to="/contacts"
                    onClick={() => {
                        track('clicked_next_contacts_sidebar');
                        setMenuOpen(false);
                    }}
                    label="Contacts"
                    keyboardNav
                />
            </WithFeature>
            <WithFeature feature={Feature.CampaignsV3}>
                <NavigationItem
                    to="/campaigns"
                    icon={<CampaignsIcon />}
                    onClick={() => {
                        track('clicked_analytics_sidebar');
                        setMenuOpen(false);
                    }}
                    label="Campaigns"
                    keyboardNav
                >
                    <Chip
                        label="New"
                        size="small"
                        color="info"
                        sx={{ px: 0.5, py: 0, height: 16, fontSize: 12 }}
                    />
                </NavigationItem>
            </WithFeature>
            <WithFeature feature={Feature.Analytics}>
                <NavigationItem
                    to="/analytics"
                    icon={<AnalyticsIcon />}
                    onClick={() => {
                        track('clicked_analytics_sidebar');
                        setMenuOpen(false);
                    }}
                    label="Analytics"
                    keyboardNav
                />
            </WithFeature>
            <WithFeature feature={Feature.AiWorkflow}>
                <NavigationItem
                    icon={<AIAssistantIcon />}
                    to="/agents"
                    onClick={() => {
                        track('clicked_ai_workflow_sidebar');
                        setMenuOpen(false);
                    }}
                    label="Agents"
                    keyboardNav
                />
            </WithFeature>
            <WithFeature feature={Feature.Workflows}>
                <NavigationItem
                    to="/workflows"
                    icon={<WorkflowsIcon />}
                    onClick={() => {
                        track('clicked_workflows_sidebar');
                        setMenuOpen(false);
                    }}
                    label="Workflows"
                    keyboardNav
                />
            </WithFeature>
            <NavigationItem
                to="/settings/profile"
                icon={<SettingsIcon />}
                onClick={() => {
                    track('clicked_settings_sidebar');
                    setMenuOpen(false);
                }}
                label="Settings"
                keyboardNav
            >
                <WithPermission
                    requiredPermissions={[
                        ClerkPermission.BrandAccess,
                        ClerkPermission.ModifyTeam,
                    ]}
                >
                    <SubscribersOnly>
                        {!isCampaignRegistered && (
                            <CompanyRegistrationTooltip />
                        )}
                    </SubscribersOnly>
                </WithPermission>
            </NavigationItem>
        </Box>
    );
};
