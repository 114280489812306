import { Profile } from '../../../api/types';
import { AvailableIntegration } from './integrations-list';

export const createIntegrationUrl = (
    availableIntegration: AvailableIntegration,
    user: Profile,
    extraState: Record<string, unknown> = {},
): string => {
    if (!availableIntegration.url) {
        return '#';
    }

    const url = new URL(availableIntegration.url);
    const state = {
        userId: user?.id,
        teamId: user?.activeTeam.id,
        integrationSource: availableIntegration.source,
        integrationType: 'contact',
        ...extraState,
    };

    url.searchParams.append('state', JSON.stringify(state));
    return url.href;
};

export const createMSAppIntegrationUrl = (
    availableIntegration: AvailableIntegration,
    user: Profile,
): string => {
    if (!availableIntegration.url) {
        return '#';
    }
    const clientId = import.meta.env.VITE_AZURE_CLIENT_ID;
    const redirectUri = import.meta.env.VITE_MS_ADMIN_APPLICATION_REDIRECT_URI;
    const url = new URL(
        `${availableIntegration.url}/${user.activeTeam.microsoftTenantId}/adminconsent`,
    );
    const state = {
        userId: user.id,
        teamId: user.activeTeam.id,
        integrationSource: availableIntegration.source,
        integrationType: availableIntegration.integrationType,
    };

    url.searchParams.append('client_id', clientId);
    url.searchParams.append('redirect_uri', redirectUri);
    url.searchParams.append('state', JSON.stringify(state));

    return url.href;
};
