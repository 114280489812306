import { Chip, SxProps, Theme } from '@mui/material';
import { RenderElementProps, useSlate } from 'slate-react';
import { TemplateElement } from '../../../../Slate/slate';
import { removeNode } from '../../../../Slate/utils';
import { memo } from 'react';

const styles: SxProps<Theme> = {
    userSelect: 'none',
    margin: (theme) => theme.spacing(0, 0.5, 0.5),
};

export type TemplateProps = RenderElementProps & {
    element: TemplateElement;
};

export const Template = memo(function Template(props: TemplateProps) {
    const editor = useSlate();
    const handleDelete = () => {
        removeNode(editor, props.element);
    };

    return (
        <>
            <Chip
                {...props.attributes}
                sx={styles}
                size="small"
                color="warning"
                variant="outlined"
                label={props.element.template}
                onDelete={handleDelete}
            />
            {props.children}
        </>
    );
});
