import isEmpty from 'lodash/isEmpty';
import range from 'lodash/range';
import { useCampaignPage } from '../../queries/campaigns';
import { UUID } from '../../types/uuid';
import { ItemSkeleton } from './Item/ItemSkeleton';
import dayjs from 'dayjs';
import { AdapterLink } from './AdapterLink';
import { ListErrorIndicator } from './ListErrorIndicator';
import { useTrack } from '../../contexts/analytics';
import { EmptyCampaignList } from './EmptyCampaignList';
import { useEffect, useMemo } from 'react';
import { Virtuoso } from 'react-virtuoso';
import { FetchingSkeleton } from './FetchingSkeleton';
import { useEnabledFeature } from '../../queries/user';
import { Feature } from '../../api/types';
import { InboxListItem } from './Item/InboxListItem';
import { staticBadgeProps } from './badge.styled';
import { useParams } from 'react-router';
import { focusVisible } from '../../theme/focusVisible';

type Props = {
    inboxId: UUID;
    startChat?: () => void;
};

export const InboxCampaignList = ({ inboxId, startChat }: Props) => {
    const params = useParams<{ entityId: string }>();
    const track = useTrack();
    const isNewInbox = useEnabledFeature(Feature.NewInbox);
    const {
        data,
        isError,
        isLoading,
        isFetchingNextPage,
        fetchNextPage,
        hasNextPage,
    } = useCampaignPage({ inboxId });

    const campaigns = useMemo(
        () =>
            data?.pages
                .flatMap(({ data }) => data)
                .sort(
                    (a, b) =>
                        new Date(b.latestTimestamp).getTime() -
                        new Date(a.latestTimestamp).getTime(),
                ) ?? [],
        [data?.pages],
    );

    useEffect(() => {
        track('inbox_campaign_page_fetched', {
            page: (data?.pages.length ?? 0) + 1,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data?.pages.length ?? 0]);

    if (isLoading) {
        return (
            <>
                {range(7).map((key) => (
                    <ItemSkeleton key={key} />
                ))}
            </>
        );
    }

    if (!data && !isError) {
        return <ListErrorIndicator startChat={startChat} />;
    }

    if (!data || isEmpty(data?.pages)) {
        return <EmptyCampaignList startChat={startChat} />;
    }

    return (
        <Virtuoso
            data={campaigns}
            overscan={1050}
            increaseViewportBy={200}
            endReached={() => {
                if (!isFetchingNextPage && hasNextPage) {
                    fetchNextPage();
                }
            }}
            style={
                isNewInbox
                    ? undefined
                    : {
                          height: 'calc(100vh - 145px)',
                      }
            }
            components={{
                Footer: isFetchingNextPage
                    ? () => <FetchingSkeleton />
                    : undefined,
            }}
            tabIndex={-1}
            itemContent={(index, campaign) => (
                <InboxListItem
                    key={campaign.id}
                    BadgeProps={{
                        ...staticBadgeProps,
                        invisible:
                            !campaign.latestTimestamp ||
                            dayjs(campaign.latestTimestamp).isBefore(
                                new Date(),
                            ),
                        componentsProps: {
                            badge: {
                                ['aria-label']:
                                    'Campaigns list item scheduled badge',
                            },
                        },
                    }}
                    sx={{
                        height: 72,
                        position: 'relative',
                        '&focus-visible': {
                            ...focusVisible,
                            outlineOffset: '2px',
                        },
                    }}
                    LinkComponent={AdapterLink}
                    href={`/inbox/${campaign.inboxId}/campaigns/${campaign.id}`}
                    title={campaign.name}
                    description={campaign.body}
                    selected={
                        !!(params.entityId && campaign.id === +params.entityId)
                    }
                    date={dayjs(campaign.latestTimestamp).format('HH:mm')}
                />
            )}
        />
    );
};
