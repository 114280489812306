import { useEffect, useState } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import styles from './LastContactedStep.module.scss';
import { TIME_VALUES, UNITS } from './utils';
import { LastContactedSettings, WorkflowCondition } from '../../types';
import { useWorkflowContext } from '../../WorkflowProvider';
import { WorkflowReducerActionTypes } from '../../WorkflowProvider/reducer';
import Button from '../../../../elements/Buttons';
import Input from '../../../../elements/Inputs/Input';
import AdvancedSelect from '../../../../elements/AdvancedSelect';
import { Icon } from '../../../../icons/Icon';
import { STEP_ICON_MAP } from '../../utils';
import { CloseIcon } from '../../../../icons/common/CCloseIcon';
import { getSquare } from '../../../../theme/style.helpers';
import { colors } from '../../../../theme/palette';

interface LastContactedStepProps {
    step: WorkflowCondition;
}

type State = {
    valueId: string;
    unitId: string;
};

export const LastContactedStep = ({ step }: LastContactedStepProps) => {
    const { dispatch } = useWorkflowContext();

    const [conditionalStep, setStep] = useState(cloneDeep(step));
    const [state, setState] = useState<State>(() => {
        const stepSettings =
            (conditionalStep.settings as LastContactedSettings) || {};

        return {
            valueId:
                TIME_VALUES.find(
                    ({ duration }) => duration === stepSettings.duration,
                )?.id || TIME_VALUES[0].id,
            unitId:
                UNITS.find(({ unit }) => unit === stepSettings.unit)?.id ||
                UNITS[0].id,
        };
    });

    useEffect(() => {
        setStep(cloneDeep(step));

        const stepSettings = (step.settings as LastContactedSettings) || {};
        setState({
            valueId:
                TIME_VALUES.find(
                    ({ duration }) => duration === stepSettings.duration,
                )?.id || TIME_VALUES[0].id,
            unitId:
                UNITS.find(({ unit }) => unit === stepSettings.unit)?.id ||
                UNITS[0].id,
        });
    }, [step]);

    const onSaveHandler = () => {
        dispatch({
            type: WorkflowReducerActionTypes.UPDATE_WORKFLOW_STEP,
            payload: {
                ...conditionalStep,
                settings: {
                    duration: TIME_VALUES.find(({ id }) => id === state.valueId)
                        ?.duration,
                    unit: UNITS.find(({ id }) => id === state.unitId)?.unit,
                } as LastContactedSettings,
            },
        });
    };

    return (
        <div className={styles['aside']}>
            <header className={styles['aside__header']}>
                <h3 className={styles['aside__title']}>
                    <Icon name={STEP_ICON_MAP[step.kind]} />
                    <span>Last contacted</span>
                </h3>
                <button
                    className={styles['aside__close-btn']}
                    onClick={() =>
                        dispatch({
                            type: WorkflowReducerActionTypes.SET_SELECTED_STEP,
                            payload: null,
                        })
                    }
                >
                    <CloseIcon
                        sx={{ ...getSquare(24), color: colors.superGrey[500] }}
                    />
                </button>
            </header>

            <div className={styles['aside__content']}>
                <section className={styles['aside__box']}>
                    <label className={styles['aside__box-label']}>
                        Item name
                    </label>
                    <Input
                        placeholder="Enter name"
                        value={conditionalStep?.name || ''}
                        onChange={(name) =>
                            setStep({ ...conditionalStep, name })
                        }
                        uncontrolled
                        fullWidth
                    />
                </section>

                <section className={styles['aside__box']}>
                    <label className={styles['aside__box-label']}>
                        Last contacted (time ago)
                    </label>
                    <div className={styles['aside__time-row']}>
                        <AdvancedSelect
                            options={TIME_VALUES}
                            value={state.valueId}
                            onChange={(valueId) =>
                                setState({
                                    ...state,
                                    valueId,
                                })
                            }
                        />
                        {state.valueId !== 'never' && (
                            <AdvancedSelect
                                options={UNITS}
                                value={state.unitId}
                                onChange={(unitId) =>
                                    setState({
                                        ...state,
                                        unitId,
                                    })
                                }
                            />
                        )}
                    </div>
                </section>
            </div>

            <div className={styles['aside__button-container']}>
                <Button onClick={onSaveHandler} fullWidth>
                    Save
                </Button>
            </div>
        </div>
    );
};
