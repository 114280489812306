import { useQuery } from '@tanstack/react-query';
import { queryClient } from '../../queries/queryClient';
import { UNREAD_ACTIVITIES_COUNT_KEY } from '../../queries/activity';

const ASSIGNMENTS_KEY = ['conversation-assignments'];
const addConversationAssignment = (payload: Payload) => {
    queryClient.setQueryData<Payload[]>(ASSIGNMENTS_KEY, (prev) => {
        if (!prev) {
            return [payload];
        }
        return [...prev, payload];
    });
};

export const removeConversationAssignment = (payload: Payload) => {
    queryClient.setQueryData<Payload[]>(ASSIGNMENTS_KEY, (prev) => {
        if (!prev) {
            return [];
        }
        return prev.filter(
            (assignment) =>
                assignment.conversationId !== payload.conversationId,
        );
    });
};

export const CONVERSATION_ASSIGNED_EVENT = 'conversation:assigned';

type Payload = {
    assignorId: string;
    assigneeId: string;
    conversationId: string;
    members: string[];
    inboxId: string;
};

export const useConversationAssignments = () =>
    useQuery<Payload[]>({
        queryKey: ASSIGNMENTS_KEY,
        initialData: [],
        queryFn: () => Promise.resolve([]),
        gcTime: Infinity,
        staleTime: Infinity,
    });

export type ConversationAssignedHandler = (p: Payload) => void;

export const handleConversationAssigned: ConversationAssignedHandler = (
    payload: Payload,
) => {
    addConversationAssignment(payload);
    void queryClient.refetchQueries({ queryKey: UNREAD_ACTIVITIES_COUNT_KEY });
};
