import { List } from '@mui/material';
import { Inbox } from '../../../api/types';
import { InboxDraggableItem } from '../../../elements/InboxItem/InboxDraggableItem';
import { list } from './styles';

interface Props {
    inboxes: Inbox[];
    onInboxClick: () => void;
}

export const InboxList = ({ inboxes, onInboxClick }: Props) => {
    return (
        <List component="div" sx={list} data-testid="regular-list">
            {inboxes.map((inbox, index) => (
                <InboxDraggableItem
                    key={inbox.id}
                    index={index}
                    inbox={inbox}
                    onClick={onInboxClick}
                />
            ))}
        </List>
    );
};
