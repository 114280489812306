import { Components, Theme } from '@mui/material';

export const MuiTableCell: Components<Theme>['MuiTableCell'] = {
    styleOverrides: {
        root: ({ theme }) => ({
            ...theme.typography.body3,
            borderBottomColor: theme.palette.custom.gray.tableDivider,
            padding: theme.spacing(4, 2, 4, 4),

            '&:last-child': {
                paddingRight: theme.spacing(4),
            },
        }),
        head: ({ theme }) => ({
            ...theme.typography.caption,
            color: theme.palette.custom.gray.super,
            padding: theme.spacing(0, 2, 2, 4),
            whiteSpace: 'nowrap',
            textTransform: 'none',
        }),
        sizeSmall: ({ theme }) => ({
            ...theme.typography.body3,
        }),
    },
};
