import { Checkbox, FormControlLabel } from '@mui/material';
import { DayForm } from './DayForm';
import { SettingsRow } from '../SettingsRow';
import { OutOfOfficeInputs, ScheduleDay } from './constants';
import { Controller, useFormContext } from 'react-hook-form';
import clone from 'lodash/clone';
import setWith from 'lodash/setWith';

export function DayRow({
    disabled = false,
    day,
}: {
    disabled?: boolean;
    day: { id: ScheduleDay; label: string; fullName: string };
}) {
    const { control } = useFormContext<OutOfOfficeInputs>();

    return (
        <Controller
            key={day.id}
            control={control}
            name="schedule"
            render={({ field, formState }) => {
                const schedule = field.value as OutOfOfficeInputs['schedule'];
                const error =
                    formState.errors?.schedule?.[day.id]?.from?.message;

                return (
                    <SettingsRow
                        label={
                            <FormControlLabel
                                label={day.fullName}
                                control={
                                    <Checkbox
                                        disabled={disabled}
                                        checked={schedule[day.id].enabled}
                                        size="small"
                                        color="info"
                                        aria-label="Select working hours day"
                                        onChange={() => {
                                            field.onChange(
                                                setWith(
                                                    clone(schedule),
                                                    `${day.id}.enabled`,
                                                    !schedule[day.id].enabled,
                                                ),
                                            );
                                        }}
                                    />
                                }
                            />
                        }
                    >
                        <div>
                            <DayForm
                                error={error}
                                to={schedule[day.id].to}
                                from={schedule[day.id].from}
                                onChangeFrom={(val) => {
                                    field.onChange(
                                        setWith(
                                            clone(schedule),
                                            `${day.id}.from`,
                                            val,
                                        ),
                                    );
                                }}
                                onChangeTo={(val) => {
                                    field.onChange(
                                        setWith(
                                            clone(schedule),
                                            `${day.id}.to`,
                                            val,
                                        ),
                                    );
                                }}
                                disabled={disabled || !schedule[day.id].enabled}
                            />
                        </div>
                    </SettingsRow>
                );
            }}
        />
    );
}
