import { Descendant, Node as SlateNode } from 'slate';
import { jsx } from 'slate-hyperscript';
import isNil from 'lodash/isNil';
import { DeserializerMiddleware, Serializer } from '../slate';

const deserializeElement = (
    el: Node,
    {
        markAttributes = {},
        middlewares = [],
    }: {
        markAttributes?: Record<string, string>;
        middlewares?: DeserializerMiddleware[];
    },
    isLast = false,
) => {
    if (el.nodeType === Node.TEXT_NODE) {
        return jsx('text', markAttributes, el.textContent);
    }

    if (el.nodeType !== Node.ELEMENT_NODE) {
        return null;
    }

    const nodeAttributes = { ...markAttributes };

    const children = Array.from(el.childNodes)
        .map((node, idx) =>
            deserializeElement(
                node,
                {
                    markAttributes: nodeAttributes,
                    middlewares,
                },
                idx === el.childNodes.length - 1,
            ),
        )
        .flat() as Descendant[];

    if (children.length === 0) {
        children.push(jsx('text', nodeAttributes, ''));
    }

    switch (el.nodeName.toLowerCase()) {
        case 'body':
            return [jsx('element', { type: 'paragraph' }, children)];
        case 'p':
            return jsx('element', { type: 'paragraph' }, children);
        case 'br':
            return '\n';
        default:
            for (const middleware of middlewares) {
                const deserialized = middleware(el, children);
                if (!isNil(deserialized)) {
                    return isLast
                        ? [deserialized, jsx('text', nodeAttributes, '')]
                        : deserialized;
                }
            }

            return children;
    }
};

export const TextSerializer: Serializer<string | Descendant[]> = {
    serialize: (nodes, middlewares = []) => {
        return (Array.isArray(nodes) ? nodes : [nodes])
            .map((n) => {
                for (const middleware of middlewares) {
                    const serialized = middleware(n);
                    if (!isNil(serialized)) {
                        return serialized;
                    }
                }

                if (
                    'children' in n &&
                    n.children.some(
                        (c) => 'type' in c && c.type === 'paragraph',
                    )
                ) {
                    return TextSerializer.serialize(n.children, middlewares);
                }

                return SlateNode.string(n);
            })
            .join('\n')
            .trim();
    },

    deserialize: (text, { regExps = [], middlewares = [] } = {}) => {
        if (Array.isArray(text)) {
            return text;
        }

        let html = text;
        if (regExps && regExps.length) {
            html = regExps.reduce((acc, { regEx, tag }) => {
                return acc.replaceAll(regEx, `<${tag}>$1</${tag}>`);
            }, text);
        }

        const body = new DOMParser().parseFromString(html, 'text/html').body;
        const deserialized = deserializeElement(body, { middlewares });

        return deserialized as Descendant[];
    },
};
