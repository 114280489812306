import { useState, forwardRef } from 'react';
import DatePicker from 'react-datepicker';
import dayjs from 'dayjs';
import styles from '../values.module.scss';
import { ValueProps } from '../../types';
import { InputProps } from '../../../../types/ElementsProps';
import '../../../../assets/styles/react-datepicker-theme.scss';
import Input from '../../../../elements/Inputs/Input';
import { fuzzyDate } from '../../../../utils/format';
import { Box, Tooltip } from '@mui/material';
import { EmptyValuePlaceholder } from '../../EmptyValuePlaceholder';
import { limitedValue } from '../styles';
import isDate from 'lodash/isDate';

const DatePickerCustomInput = forwardRef<HTMLInputElement, InputProps>(
    function DatePickerCustomInput(
        { value, onClick, 'aria-label': ariaLabel },
        ref,
    ) {
        return (
            <Input
                aria-label={ariaLabel}
                wrapperClassName={styles['edit-input-wrapper']}
                className={styles['edit-input']}
                onClick={onClick}
                value={value}
                ref={ref}
                fullWidth
                readonly
                uncontrolled
            />
        );
    },
);

const renderValue = (val: string | Date, fuzzy = true) => {
    const dateValue = val instanceof Date ? val : new Date(val);

    if (!isDate(dateValue)) {
        return 'Invalid date';
    }

    if (fuzzy) {
        return fuzzyDate(dateValue);
    }
    return dayjs(dateValue).toString();
};

// Function to get relative time in browser's timezone
const getRelativeTimeInLocalTZ = (val: string | Date) => {
    const dateValue = val instanceof Date ? val : new Date(val);

    if (!isDate(dateValue)) {
        return 'Invalid date';
    }

    return `${dayjs(dateValue).format('MMMM D, YYYY h:mm A')} (${fuzzyDate(dateValue)})`;
};

export const DateValue = ({
    userProperty,
    readonly,
    onChange,
}: ValueProps<string | Date>) => {
    const [isEditEnabled, setEditEnabled] = useState(false);
    const propertyName = userProperty.name || 'Date';

    // Convert value to Date object safely
    const getDateValue = () => {
        if (!userProperty.value) {
            return null;
        }
        const dateObj =
            userProperty.value instanceof Date
                ? userProperty.value
                : new Date(userProperty.value);
        return isDate(dateObj) ? dateObj : null;
    };

    if (isEditEnabled && !readonly) {
        const dateValue = getDateValue();
        return (
            <DatePicker
                dateFormat="MM/dd/yyyy"
                selected={dateValue || new Date()}
                customInput={
                    <DatePickerCustomInput
                        aria-label={`${propertyName} value input`}
                    />
                }
                onCalendarClose={() => {
                    setEditEnabled(false);
                }}
                onChange={(date: Date) =>
                    onChange?.({ ...userProperty, value: date })
                }
            />
        );
    }

    return (
        <Box
            sx={limitedValue}
            title={String(userProperty.value ?? '')}
            onClick={() => {
                if (!readonly) {
                    setEditEnabled(true);
                }
            }}
            role="button"
            aria-label={
                readonly ? `View ${propertyName}` : `Edit ${propertyName}`
            }
            tabIndex={readonly ? undefined : 0}
        >
            {userProperty.value ? (
                <Tooltip title={getRelativeTimeInLocalTZ(userProperty.value)}>
                    <span>{renderValue(userProperty.value)}</span>
                </Tooltip>
            ) : (
                <EmptyValuePlaceholder
                    text={readonly ? 'Empty' : undefined}
                    aria-label={`${propertyName} set a value`}
                />
            )}
        </Box>
    );
};
