import { useNavigate } from 'react-router';
import {
    DragDropContext,
    Droppable,
    DroppableProvided,
    DropResult,
} from '@hello-pangea/dnd';
import { ClerkPermission, Feature } from '../../../api/types';
import { useEnabledFeature } from '../../../queries/user';
import { WithPermission } from '../../WithPermission/WithPermission';
import { useTrack } from '../../../contexts/analytics';
import { useCallback, useMemo, Suspense } from 'react';
import { Box, Button } from '@mui/material';
import { PlusIcon } from '../../../icons/common/CPlusIcon';
import { focusVisible } from '../../../theme/focusVisible';
import { WithFeature } from '../../Feature/WithFeature';
import useSaveInboxesOrder from '../../../hooks/useSaveInboxesOrder';
import useSortedInboxes from '../../../hooks/useSortedInboxes';
import { VirtualizedInboxList } from './VirtualizedInboxList.lazy';
import { InboxList } from './InboxList';
import { CollapsibleAccordionSection } from '../../../components/CollapsibleAccordionSection/CollapsibleAccordionSection';
import { InboxListSkeleton } from './InboxListSkeleton';

interface SideBarInboxesProps {
    setMenuOpen: (value: boolean) => void;
}

export const MAX_INBOXES_BEFORE_VIRTUALIZATION = 5;

export const SideBarInboxes = ({ setMenuOpen }: SideBarInboxesProps) => {
    const { unmuted: unmutedSortedInboxes } = useSortedInboxes();
    const saveInboxesOrder = useSaveInboxesOrder();
    const track = useTrack();
    const navigate = useNavigate();

    const handleOnDragEnd = useCallback(
        (result: DropResult) => {
            if (!result.destination) {
                return;
            }

            saveInboxesOrder(result.source.index, result.destination.index);
        },
        [saveInboxesOrder],
    );

    const handleInboxItemClick = useCallback(() => {
        setMenuOpen(false);
    }, [setMenuOpen]);
    const canCreateNewNumber = useEnabledFeature(Feature.CreateNewNumber);
    const canImportNumber = useEnabledFeature(Feature.ImportNumber);
    const canAddInbox = useEnabledFeature(Feature.AddInbox);

    const handleAddButtonClick = useCallback(() => {
        track('clicked_create_new_inbox');

        return (!canCreateNewNumber && !canImportNumber) || !canAddInbox
            ? navigate('/payment/plan')
            : navigate('/setup-inbox/existing-number');
    }, [track, canCreateNewNumber, canImportNumber, canAddInbox, navigate]);

    const isVirtualized = useMemo(
        () => unmutedSortedInboxes.length > MAX_INBOXES_BEFORE_VIRTUALIZATION,
        [unmutedSortedInboxes.length],
    );

    return (
        <CollapsibleAccordionSection title="Phones">
            <WithFeature feature={Feature.AddInbox}>
                <WithPermission
                    requiredPermissions={[ClerkPermission.ModifyTeam]}
                >
                    <Box px={2} pb={2} pt={1.5}>
                        <Button
                            variant="outlined"
                            size="small"
                            startIcon={<PlusIcon />}
                            color="info"
                            onClick={handleAddButtonClick}
                            fullWidth
                            data-navigation-element
                            sx={{
                                '&:focus-visible': {
                                    ...focusVisible,
                                    outlineOffset: '2px',
                                },
                            }}
                            tabIndex={0}
                        >
                            Add Number
                        </Button>
                    </Box>
                </WithPermission>
            </WithFeature>
            <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId="inboxes-list">
                    {(providedDroppable: DroppableProvided) => (
                        <Box
                            ref={providedDroppable.innerRef}
                            {...providedDroppable.droppableProps}
                            tabIndex={-1}
                            sx={{
                                height: isVirtualized ? 300 : undefined,
                            }}
                        >
                            {isVirtualized ? (
                                <Suspense fallback={<InboxListSkeleton />}>
                                    <VirtualizedInboxList
                                        inboxes={unmutedSortedInboxes}
                                        onInboxClick={handleInboxItemClick}
                                    />
                                </Suspense>
                            ) : (
                                <InboxList
                                    inboxes={unmutedSortedInboxes}
                                    onInboxClick={handleInboxItemClick}
                                />
                            )}
                            {providedDroppable.placeholder}
                        </Box>
                    )}
                </Droppable>
            </DragDropContext>
        </CollapsibleAccordionSection>
    );
};
