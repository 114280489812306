import { useState } from 'react';
import {
    AddressElement,
    useElements,
    useStripe,
    CardElement,
} from '@stripe/react-stripe-js';
import { useAnalytics } from '../../../contexts/analytics';
import { useMeQueryData } from '../../../queries/user';
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    IconButton,
    Typography,
} from '@mui/material';
import { DialogTitleTwoLine } from '../../../elements/Dialog/DialogTitleTwoLine';
import { useAddPaymentMethod } from '../../../queries/billing';
import { Close } from '@mui/icons-material';
import { DialogCardIcon } from '../../../elements/Dialog/DialogCardIcon';
import { LockIcon } from '../../../icons/common/CLockIcon';

type Props = {
    onClose: () => void;
};

const mapsApiKey = import.meta.env.VITE_GOOGLE_PLACES_API_KEY ?? '';

export const AddCardForm = ({ onClose }: Props) => {
    const stripe = useStripe();
    const elements = useElements();
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const analytics = useAnalytics();
    const me = useMeQueryData();

    const { mutateAsync: addPaymentMethod, isPending } = useAddPaymentMethod();

    const dispatchAddCardEvent = () => {
        analytics.dispatch({
            payload: {
                eventPayload: {
                    userId: me?.id,
                    email: me?.email,
                    teamId: me?.activeTeam?.id,
                },
                eventName: 'user_add_card',
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        setIsLoading(true);

        if (!stripe || !elements) {
            return;
        }

        const cardElement = await elements.getElement(CardElement);
        const address = await elements.getElement(AddressElement)?.getValue();

        if (!cardElement || !address) {
            return;
        }

        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
            billing_details: address.value,
        });

        if (error) {
            setError(error.message ?? 'Something went wrong');
        } else {
            await addPaymentMethod(paymentMethod.id);
            dispatchAddCardEvent();
            onClose();
        }
        setIsLoading(false);
    };

    return (
        <Dialog open sx={{ width: 672, maxWidth: 1, margin: 'auto' }}>
            <IconButton
                size="small"
                color="default"
                onClick={() => onClose()}
                sx={{ alignSelf: 'end', mt: 4, mr: 4, mb: -9 }}
            >
                <Close />
            </IconButton>
            <DialogCardIcon color="primary" />
            <DialogTitleTwoLine
                title="Billing details"
                subtitle={
                    <Typography variant="body3" color="text.secondary">
                        Enter your payment information
                    </Typography>
                }
            />
            <DialogContent>
                <form onSubmit={handleSubmit} id="addCardForm">
                    <Typography variant="body3" sx={{ display: 'flex', pb: 1 }}>
                        Card details
                    </Typography>
                    <Box
                        sx={{
                            border: '1px solid',
                            borderColor: '#e6e6e6',
                            p: 3,
                            borderRadius: '5px',
                        }}
                    >
                        <CardElement
                            options={{
                                disableLink: true,
                                hidePostalCode: true,
                                style: {
                                    base: {
                                        fontSize: '16px',
                                        color: '#424770',
                                        '::placeholder': {
                                            color: '#aab7c4',
                                        },
                                    },
                                    invalid: {
                                        color: '#9e2146',
                                    },
                                },
                            }}
                        />
                    </Box>

                    <Box mb={2} />
                    <AddressElement
                        options={
                            mapsApiKey.length
                                ? {
                                      mode: 'billing',
                                      autocomplete: {
                                          mode: 'google_maps_api',
                                          apiKey: mapsApiKey,
                                      },
                                  }
                                : {
                                      mode: 'billing',
                                  }
                        }
                    />

                    {error && (
                        <Typography
                            variant="body3"
                            sx={{ display: 'flex', mt: 2 }}
                            color="error"
                        >
                            {error}
                        </Typography>
                    )}
                </form>
            </DialogContent>
            <DialogActions>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 4,
                        width: 1,
                    }}
                >
                    <Button
                        sx={{ width: 1 }}
                        startIcon={!isPending && !isLoading && <LockIcon />}
                        type="submit"
                        form="addCardForm"
                        disabled={isPending || isLoading}
                    >
                        {isPending || isLoading ? (
                            <CircularProgress size={18} color="error" />
                        ) : (
                            'Confirm'
                        )}
                    </Button>
                    <Typography
                        variant="body4"
                        color="text.secondary"
                        sx={{ width: 1, textAlign: 'center' }}
                    >
                        Your data is securely protected with AES 256-bit
                        encryption
                    </Typography>
                </Box>
            </DialogActions>
        </Dialog>
    );
};
