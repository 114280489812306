import { CarrierConnectProps } from './CarrierConnect';
import { useEffect, useState } from 'react';
import { StepShell } from '../../Onboarding/StepShell';
import { useListChannelsQuery } from '../../../queries/inboxes';
import { InboxProvider } from '../../../api/types';
import { Button, Stack, InputAdornment, TextField, Link } from '@mui/material';
import { Telnyx } from '../../../icons/carriers/Telnyx';
import { useNavigate } from 'react-router';
import { KeyIcon } from '../../../assets/newAssets/icons/CKeyIcon';
import { getSquare } from '../../../theme/style.helpers';
import { colors } from '../../../theme/palette';

export const TelnyxConnect = ({
    goBackward,
    goForward,
    setCredentials,
}: CarrierConnectProps) => {
    const [apiKey, setApiKey] = useState('');
    const [isEnabled, setEnabled] = useState(false);
    const navigate = useNavigate();

    const { data = [], isFetching } = useListChannelsQuery(
        {
            provider: InboxProvider.TELNYX,
            telnyxApiKey: apiKey,
        },
        isEnabled,
    );

    useEffect(() => {
        if (!isFetching) {
            setEnabled(false);
        }
    }, [isFetching]);

    useEffect(() => {
        if (data && data.length > 0) {
            setCredentials({ apiKey });
            navigate(goForward);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    return (
        <StepShell
            title="Telnyx setup"
            subtitle="The Api Key can be found in your Telnyx dashboard."
            icon={<Telnyx />}
            goBackward={goBackward}
        >
            <Stack spacing={6} width={320}>
                <TextField
                    value={apiKey}
                    onChange={(e) => setApiKey(e.target.value)}
                    fullWidth
                    type="password"
                    placeholder="API Key"
                    InputProps={{
                        autoFocus: true,
                        startAdornment: (
                            <InputAdornment position="start">
                                <KeyIcon
                                    sx={{
                                        ...getSquare(20),
                                        color: colors.superGrey[500],
                                    }}
                                />
                            </InputAdornment>
                        ),
                    }}
                />

                <Button
                    onClick={() => {
                        setEnabled(true);
                    }}
                    disabled={!apiKey || isFetching}
                    type="submit"
                    fullWidth
                >
                    {isFetching ? 'Loading...' : 'Proceed'}
                </Button>
                <Link
                    href="https://support.telnyx.com/en/articles/4305158-api-keys-and-how-to-use-them"
                    variant="body4"
                    color="info.main"
                    textAlign="center"
                    target="_blank"
                >
                    How do I find my Telnyx API key?
                </Link>
            </Stack>
        </StepShell>
    );
};
