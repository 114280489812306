/* eslint-disable @typescript-eslint/no-explicit-any */
import { ContactPropertyType, Profile } from '../../api/types';
import { PropertyIcon } from '../../icons/iconsMap';

enum PersonTypeValue {}

export enum PropertySource {
    Clerk = 'clerk',
    Google = 'google',
    Microsoft = 'microsoft',
    Salesforce = 'salesforce',
    Shopify = 'shopify',
    Hubspot = 'hubspot',
    Pipedrive = 'pipedrive',
}

export interface UserPropertyModel<T = any> {
    id: string;
    type: ContactPropertyType;
    name: string;
    icon?: PropertyIcon;
    options?: T extends PersonTypeValue ? Array<Profile> : Array<string>;
    readonly?: boolean;
    readonlyIcon?: boolean;
    source?: PropertySource;
    value?: T;
    created?: Date;
    updated?: Date;
}
