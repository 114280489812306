import { Typography } from '@mui/material';
import { useMeQueryData, useTeammates } from '../../../queries/user';
import { UUID } from '../../../types/uuid';
import { UserAvatar } from '../../UserAvatar/UserAvatar';
import { ActivityLine } from '../ActivityLine';
import { getFullName } from '../../../utils/user';
import { Navigate } from '../Navigate';
import { ActivityType, Status } from '../types';
import { useConversation } from '../../../queries/conversations';
import { useName } from '../../Inbox/Conversation/use-conversation-props';
import { useMarkActivityAsRead } from '../../../queries/activity';

export type AssignedConversationActivity = {
    id: UUID;
    type: ActivityType.AssignedConversation;
    initiatingContext: {
        userId: UUID;
    };
    targetContext: {
        userId: UUID;
        inboxId: UUID;
        conversationId: UUID;
    };
    occurredAt: string;
    status: Status;
};

export const AssignedConversation = ({
    id,
    initiatingContext,
    status,
    targetContext,
}: AssignedConversationActivity) => {
    const me = useMeQueryData();
    const { data: teammates } = useTeammates();

    const { mutateAsync: markAsRead } = useMarkActivityAsRead();

    const { data: conversation } = useConversation(
        targetContext.conversationId,
    );

    const { data: conversationName } = useName(conversation?.members ?? []);
    const initUser = teammates?.find(
        (teammate) => teammate.id === initiatingContext.userId,
    );
    const targetUserName =
        targetContext.userId === me?.id
            ? 'you'
            : getFullName(
                  teammates?.find(
                      (teammate) => teammate.id === targetContext.userId,
                  ),
              );
    return (
        <ActivityLine
            id={id}
            avatar={<UserAvatar imageUrl={initUser?.avatar?.url} size={20} />}
            title={
                <>
                    <Typography
                        variant="body3"
                        fontWeight={500}
                        data-testid="assigned-conversation-initiator"
                    >
                        {getFullName(initUser)}
                    </Typography>{' '}
                    <Typography variant="body3">has assigned</Typography>{' '}
                    <Typography
                        variant="body3"
                        fontWeight={500}
                        data-testid="assigned-conversation-has-assigned-to"
                    >
                        {targetUserName}
                    </Typography>{' '}
                    <Typography variant="body3">
                        to the conversation with
                    </Typography>{' '}
                    <Typography
                        variant="body3"
                        fontWeight={500}
                        data-testid="assigned-conversation-conversation-name"
                    >
                        {conversationName}
                    </Typography>
                </>
            }
            status={status}
            action={
                <Navigate
                    path={`/inbox/${targetContext.inboxId}/conversations/${targetContext.conversationId}`}
                    onClick={() => markAsRead(id)}
                />
            }
        />
    );
};
