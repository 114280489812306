import * as Sentry from '@sentry/react';
import { Stack, TextField, CircularProgress, IconButton } from '@mui/material';
import { useState } from 'react';
import { Inbox } from '../../../../api/types';
import { SettingsRow } from '../../SettingsRow';
import {
    useInboxUpdateQuery,
    useRemoveCallForwarding,
} from '../../../../queries/inboxes';
import { TrashCanIcon } from '../../../../icons/shared/TrashCanIcon';

export function CallForwardingRow({ inbox }: { inbox: Inbox }) {
    const [forwardingNumber, setForwardingNumber] = useState(
        inbox.callForwardingPhoneNumber || '',
    );
    const [isSaving, setIsSaving] = useState(false);
    const updateInbox = useInboxUpdateQuery();
    const removeCallForwarding = useRemoveCallForwarding();

    const handleSave = async (newValue: string) => {
        if (!newValue.trim() || newValue === inbox.callForwardingPhoneNumber) {
            return;
        }

        try {
            setIsSaving(true);
            await updateInbox.mutateAsync({
                id: inbox.id,
                callForwardingPhoneNumber: newValue.trim(),
            });
        } catch (error) {
            Sentry.captureException(error);
        } finally {
            setIsSaving(false);
        }
    };

    const handleDelete = async () => {
        try {
            setIsSaving(true);
            await removeCallForwarding.mutateAsync(inbox.id);
            setForwardingNumber('');
        } catch (error) {
            Sentry.captureException(error);
        } finally {
            setIsSaving(false);
        }
    };

    return (
        <SettingsRow label="Call Forwarding Number">
            <Stack spacing={2} direction="row" alignItems="center">
                <TextField
                    placeholder="415-555-1234"
                    value={forwardingNumber}
                    onChange={(e) => setForwardingNumber(e.target.value)}
                    onBlur={() => handleSave(forwardingNumber)}
                    disabled={isSaving}
                />
                {isSaving && <CircularProgress size={24} />}
                {inbox.callForwardingPhoneNumber && !isSaving && (
                    <IconButton
                        onClick={handleDelete}
                        size="small"
                        aria-label="delete forwarding number"
                    >
                        <TrashCanIcon fill="red" />
                    </IconButton>
                )}
            </Stack>
        </SettingsRow>
    );
}
