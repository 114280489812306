import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    FormControlLabel,
    Stack,
    Typography,
} from '@mui/material';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useBrandQuery } from '../../queries/settings';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { PhoneVerificationProps } from './PhoneVerification';
import {
    useCheckHostabilityQueryData,
    useHostInboxMutation,
    useInboxQueryData,
    usePortInboxMutation,
} from '../../queries/inboxes';
import { RectangleFrame } from '../../components/RectangleFrame/RectangleFrame';
import dayjs from 'dayjs';
import { HostDisclaimer, PortDisclaimer } from './helpers';
import { InboxProvider } from '../../api/types';
import { useTrack } from '../../contexts/analytics';
import { UserProfileIcon } from '../../icons/common/UserProfileIcon';
import { CalendarIcon } from '../../icons/common/CСalendarIcon';

export interface SignatureVerificationStepFormData {
    hasTeamsLicense?: boolean;
    hasAgreedToTransferPolicy?: boolean;
    hasBandwidthOC?: boolean;
    hasRemovedTeamsCallingPlan?: boolean;
}

export const SignatureVerificationStep = () => {
    const { inboxId } = useParams<PhoneVerificationProps>();
    const navigate = useNavigate();

    const { control, handleSubmit } =
        useForm<SignatureVerificationStepFormData>();

    const [isLoading, setLoading] = useState(false);
    const [isError, setError] = useState(false);

    const brand = useBrandQuery();
    const inbox = useInboxQueryData(inboxId ?? '');
    const hostCheck = useCheckHostabilityQueryData([inbox?.phone ?? '']);
    const track = useTrack();

    const { mutateAsync: hostNumber } = useHostInboxMutation(inboxId ?? '');
    const { mutateAsync: portNumber } = usePortInboxMutation(inboxId ?? '');

    const onSubmit: SubmitHandler<
        SignatureVerificationStepFormData
    > = async () => {
        try {
            setLoading(true);
            hostCheck?.hostable ? await hostNumber() : await portNumber();

            track('verification_phone_signature');

            setTimeout(() => {
                navigate(`/inbox/${inboxId}`);
                setLoading(false);
            }, 5000);
        } catch (e) {
            setError(true);
            setLoading(false);
        }
    };

    return (
        <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            width={1}
            justifyContent="center"
            alignContent="center"
            display="flex"
            mt={4}
        >
            <Stack spacing={4} width={0.5}>
                <Box>{hostCheck ? <HostDisclaimer /> : <PortDisclaimer />}</Box>

                {inbox?.provider === InboxProvider.MSTEAMS &&
                    !hostCheck?.hostable && (
                        <Stack spacing={3}>
                            <Box>
                                <RectangleFrame variant="light">
                                    <Controller
                                        name="hasBandwidthOC"
                                        control={control}
                                        defaultValue={false}
                                        rules={{
                                            required: true,
                                            validate: (v) => v,
                                        }}
                                        render={({ field }) => (
                                            <FormControlLabel
                                                sx={{ m: 0 }}
                                                control={
                                                    <Checkbox
                                                        color="info"
                                                        sx={{ p: 0, mr: 4 }}
                                                        checked={field.value}
                                                        size="small"
                                                        {...field}
                                                    />
                                                }
                                                label={
                                                    <Typography
                                                        variant="body3"
                                                        color="primary.dark"
                                                    >
                                                        I&apos;ve activated
                                                        Bandwidth via{' '}
                                                        <a
                                                            href="https://admin.teams.microsoft.com/operators/6e0bc3a3-43f7-4374-80ee-b2ae0af3c0d5/edit/1591fe31-8acc-4ce1-aaff-09aca16aac95"
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            Microsoft Operator
                                                            Connect
                                                        </a>{' '}
                                                    </Typography>
                                                }
                                            />
                                        )}
                                    />
                                </RectangleFrame>
                            </Box>

                            {inbox.locationType !== 'IVR' && (
                                <Box>
                                    <RectangleFrame variant="light">
                                        <Controller
                                            name="hasTeamsLicense"
                                            control={control}
                                            defaultValue={false}
                                            rules={{
                                                required: true,
                                                validate: (v) => v,
                                            }}
                                            render={({ field }) => (
                                                <FormControlLabel
                                                    sx={{ m: 0 }}
                                                    control={
                                                        <Checkbox
                                                            sx={{ p: 0, mr: 4 }}
                                                            color="info"
                                                            checked={
                                                                field.value
                                                            }
                                                            size="small"
                                                            {...field}
                                                        />
                                                    }
                                                    label="I have a Teams Phone Standard license or E5 license"
                                                />
                                            )}
                                        />
                                    </RectangleFrame>
                                </Box>
                            )}

                            <Box>
                                <RectangleFrame variant="light">
                                    <Controller
                                        name="hasRemovedTeamsCallingPlan"
                                        control={control}
                                        defaultValue={false}
                                        rules={{
                                            required: true,
                                            validate: (v) => v,
                                        }}
                                        render={({ field }) => (
                                            <FormControlLabel
                                                sx={{ m: 0 }}
                                                control={
                                                    <Checkbox
                                                        sx={{ p: 0, mr: 4 }}
                                                        color="info"
                                                        checked={field.value}
                                                        size="small"
                                                        {...field}
                                                    />
                                                }
                                                label={
                                                    <Typography
                                                        variant="body3"
                                                        color="primary.dark"
                                                    >
                                                        I have removed the Teams
                                                        calling plan. Questions?
                                                        Get help{' '}
                                                        <a
                                                            href="https://support.clerk.chat/collections/5451154-microsoft-teams"
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            here
                                                        </a>
                                                    </Typography>
                                                }
                                            />
                                        )}
                                    />
                                </RectangleFrame>
                            </Box>
                        </Stack>
                    )}

                <Box>
                    <RectangleFrame variant="light">
                        <Box py={2}>
                            <Controller
                                name="hasAgreedToTransferPolicy"
                                control={control}
                                defaultValue={false}
                                rules={{ required: true, validate: (v) => v }}
                                render={({ field }) => (
                                    <FormControlLabel
                                        sx={{ m: 0 }}
                                        control={
                                            <Checkbox
                                                sx={{ p: 0, mr: 4 }}
                                                color="info"
                                                checked={field.value}
                                                size="small"
                                                {...field}
                                            />
                                        }
                                        label={
                                            <Typography
                                                variant="body3"
                                                color="primary.dark"
                                            >
                                                I agree to the{' '}
                                                <a
                                                    href="https://clerk.chat/legal/terms"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    terms and conditions ↗
                                                </a>
                                            </Typography>
                                        }
                                    />
                                )}
                            />
                        </Box>
                    </RectangleFrame>
                </Box>

                <Stack
                    direction={{ xs: 'row', sm: 'column', lg: 'row' }}
                    spacing={3}
                >
                    <Box width="100%">
                        <RectangleFrame>
                            <Box
                                display="flex"
                                sx={{ py: 2 }}
                                alignItems="center"
                                justifyContent="space-between"
                                width={1}
                            >
                                <Box display="flex" alignItems="center" mb={1}>
                                    <UserProfileIcon
                                        fontSize="small"
                                        sx={{ mr: 4 }}
                                    />
                                    <Typography variant="body3">
                                        Authorized Person
                                    </Typography>
                                </Box>
                                <Typography
                                    variant="body3"
                                    fontWeight="medium"
                                    sx={{ fontStyle: 'italic' }}
                                >
                                    {`${brand.data?.contactFirstName} ${brand.data?.contactLastName}`}
                                </Typography>
                            </Box>
                        </RectangleFrame>
                    </Box>

                    <Box width="100%">
                        <RectangleFrame>
                            <Box
                                display="flex"
                                sx={{ py: 2 }}
                                alignItems="center"
                                justifyContent="space-between"
                                width={1}
                            >
                                <Box display="flex" alignItems="center" mb={1}>
                                    <CalendarIcon
                                        fontSize="small"
                                        sx={{ mr: 4 }}
                                    />
                                    <Typography variant="body3">
                                        Date
                                    </Typography>
                                </Box>
                                <Typography
                                    variant="body3"
                                    fontWeight="medium"
                                    sx={{ fontStyle: 'italic' }}
                                >
                                    {dayjs().format('MMMM D, YYYY')}
                                </Typography>
                            </Box>
                        </RectangleFrame>
                    </Box>
                </Stack>

                {isError && (
                    <Typography color="error" variant="body3">
                        Sorry, something went astray with your request. Please
                        contact support
                    </Typography>
                )}

                <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    spacing={3}
                    justifyContent="space-between"
                >
                    <div>
                        <Button
                            variant="outlined"
                            disabled={isLoading}
                            onClick={() => history.back()}
                        >
                            Back
                        </Button>
                    </div>
                    <div>
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            disabled={isLoading}
                        >
                            {isLoading ? (
                                <CircularProgress size={24} color="inherit" />
                            ) : (
                                'Complete Verification'
                            )}
                        </Button>
                    </div>
                </Stack>
            </Stack>
        </Box>
    );
};
