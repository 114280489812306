import { Box, Button, Divider, Stack } from '@mui/material';
import { EnableRow } from './EnableRow';
import { TimezonesRow } from './TimezonesRow';
import { ScheduleTypeRow } from './ScheduleTypeRow';
import { DAYS_OF_WEEK } from '../../../components/workflows/WorkflowStepConfigurator/ScheduleStep/utils';
import { DayRow } from './DayRow';
import { MultiDayForm } from './MultiDayForm';
import { MessageRow } from './MessageRow';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { getDefaultValues, getWorkflowPayload } from './utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { type OutOfOfficeInputs, schema } from './constants';
import { useCallback, useEffect } from 'react';
import { Workflow } from '../../../components/workflows/types';
import {
    useWorkflowCreateQuery,
    useWorkflowUpdateQuery,
} from '../../../queries/workflows';
import { ToastActions, useToastContext } from '../../toast/reducer';

export function Form({
    workflow,
    inboxId,
}: {
    workflow: Workflow;
    inboxId: string;
}) {
    const methods = useForm({
        defaultValues: getDefaultValues(workflow),
        resolver: yupResolver(schema),
    });

    const updateQuery = useWorkflowUpdateQuery();
    const createQuery = useWorkflowCreateQuery();
    const { dispatch: toastDispatch } = useToastContext();

    const disabled = !methods.watch('enabled');
    const scheduleType = methods.watch('scheduleType');

    useEffect(() => {
        if (workflow) {
            methods.reset(getDefaultValues(workflow));
        }
    }, [methods, workflow]);

    const onSubmit = useCallback(
        async (data: OutOfOfficeInputs) => {
            if (!workflow) {
                return;
            }

            const payload = getWorkflowPayload(workflow, data);
            try {
                workflow.id === -1
                    ? await createQuery.mutateAsync({
                          ...payload,
                          inboxId,
                      })
                    : await updateQuery.mutateAsync({
                          ...payload,
                          workflowId: workflow.id,
                      });

                toastDispatch({
                    type: ToastActions.ADD,
                    payload: {
                        severity: 'success',
                        title: 'Phone settings',
                        description: 'Out of Office settings are saved',
                    },
                });
            } catch (_) {
                toastDispatch({
                    type: ToastActions.ADD,
                    payload: {
                        severity: 'error',
                        title: 'Phone settings',
                        description: 'Failed to save Out of Office settings',
                    },
                });
            }
        },
        [createQuery, inboxId, toastDispatch, updateQuery, workflow],
    );

    return (
        <FormProvider {...methods}>
            <Stack spacing={6} flex={1} height={1}>
                <Controller
                    control={methods.control}
                    name="enabled"
                    render={({ field }) => (
                        <EnableRow
                            value={field.value}
                            onChange={(enabled) => {
                                field.onChange(enabled);

                                if (workflow.id !== -1) {
                                    updateQuery
                                        .mutateAsync({
                                            workflowId: workflow.id,
                                            enabled,
                                        })
                                        .catch(() => {
                                            methods.setValue(
                                                'enabled',
                                                !enabled,
                                            );

                                            toastDispatch({
                                                type: ToastActions.ADD,
                                                payload: {
                                                    severity: 'error',
                                                    title: 'Phone settings',
                                                    description: `Failed to ${enabled ? 'enable' : 'disable'} Out of Office settings`,
                                                },
                                            });
                                        });
                                }
                            }}
                        />
                    )}
                />
                <Divider />

                <TimezonesRow disabled={disabled} />
                <ScheduleTypeRow disabled={disabled} />

                {scheduleType === 'custom' ? (
                    DAYS_OF_WEEK.map((day) => (
                        <DayRow disabled={disabled} key={day.id} day={day} />
                    ))
                ) : (
                    <MultiDayForm
                        scheduleType={scheduleType}
                        disabled={disabled}
                    />
                )}

                <MessageRow disabled={disabled} />
                <Divider />
                <Box pb={3}>
                    <Button
                        onClick={methods.handleSubmit(onSubmit)}
                        disabled={
                            disabled ||
                            createQuery.isPending ||
                            updateQuery.isPending
                        }
                        sx={{ width: 124 }}
                        color="info"
                        size="small"
                        aria-label="Save out of office changes"
                    >
                        Save changes
                    </Button>
                </Box>
            </Stack>
        </FormProvider>
    );
}
