import { SvgIcon } from '@mui/material';
import { ClerkIconProps } from '../../types/clerk-icon.props';

export const ArrowForwardFilled = (props: ClerkIconProps) => (
    <SvgIcon inheritViewBox {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.0581 4.55806C11.814 4.80214 11.814 5.19786 12.0581 5.44194L15.9911 9.375H2.5C2.15482 9.375 1.875 9.65482 1.875 10C1.875 10.3452 2.15482 10.625 2.5 10.625H15.9911L12.0581 14.5581C11.814 14.8021 11.814 15.1979 12.0581 15.4419C12.3021 15.686 12.6979 15.686 12.9419 15.4419L17.9419 10.4419C18.186 10.1979 18.186 9.80214 17.9419 9.55806L12.9419 4.55806C12.6979 4.31398 12.3021 4.31398 12.0581 4.55806Z"
                fill="currentColor"
            />
        </svg>
    </SvgIcon>
);
