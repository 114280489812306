import { useEffect, useMemo, useState } from 'react';
import { newConversation, message } from './styles';
import { NewConversationProvider } from './NewConversationProvider/NewConversationProvider';
import { SearchPanel } from './SearchPanel/SearchPanel';
import { NewConversationMessage } from './NewConversationMessage/NewConversationMessage';
import { AnyContact, FinishHandler } from './types';
import { UUID } from '../../types/uuid';
import { Box, LinearProgress } from '@mui/material';
import { NewConversationControls } from './NewConversationControls/NewConversationControls';
import { ConversationAlert } from './Alert/ConversationAlert';
import { Feature } from '../../api/types';
import {
    useCohorts,
    useContactIdsFromCohortsQuery,
} from '../../queries/cohorts';
import { CohortMetaDto } from '../../api/cohorts';
import { useConversationFindQuery } from '../../queries/conversations';
import { InboxConversationWorkflow } from '../InboxWorkflow';
import uniq from 'lodash/uniq';
import Loading from '../Loading/Loading';
import { useSearchParams } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { QueryParam } from '../../pages/NewInbox/query.params';
import { useEnabledFeature } from '../../queries/user';
import { isContactPhone } from './utils/options';
import { useIsBannerShown } from '../../main/settings/Compliance/useIsBannerShown';
import { EXTRA_OFFSET_ON_MOBILE } from '../../pages/NewInbox/query.helpers';
import { BANNER_HEIGHT } from '../Banner/Banner';
import { PaymentFailedBanner } from '../Banner/PaymentFailedBanner';
import { OutOfMessagesBanner } from '../Banner/OutOfMessagesBanner';
import { useCountOptedOut } from '../Contacts/use-contact-by-phone';
import isString from 'lodash/isString';
import { PhonesOptedOutBanner } from '../Banner/PhonesOptedOutBanner';

interface Props {
    inboxId: UUID;
    onFinish: FinishHandler;
}

export enum Mode {
    Conversation = 'conversation',
    Campaign = 'campaign',
}

export const NewConversation = ({ inboxId, onFinish }: Props) => {
    const isCampaignsAllowed = useEnabledFeature(Feature.Campaigns);
    const { data: cohorts = [], isFetched: isCohortsFetched } = useCohorts({
        enabled: isCampaignsAllowed,
    });
    const [searchParams, setSearchParams] = useSearchParams();
    const [mode, setMode] = useState<Mode>(Mode.Conversation);
    const [selectedContacts, setSelectedContacts] = useState<AnyContact[]>([]);
    const [selectedCohorts, setSelectedCohorts] = useState<CohortMetaDto[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const selectedCohortIds = useMemo(
        () => selectedCohorts.map((cohort) => cohort.id),
        [selectedCohorts],
    );
    const queryCohortIds = searchParams
        .getAll(QueryParam.ToCohortIds)
        .map((id) => +id);

    useEffect(() => {
        if (isCohortsFetched && !isEmpty(queryCohortIds)) {
            setSelectedCohorts(
                cohorts.filter(({ id }) => queryCohortIds.includes(id)),
            );
            setSearchParams(
                (prev) => {
                    prev.delete(QueryParam.ToCohortIds);
                    return prev;
                },
                { replace: true },
            );
        }
    }, [queryCohortIds, isCohortsFetched, cohorts, setSearchParams]);

    const { data: contactIdsFromCohorts } =
        useContactIdsFromCohortsQuery(selectedCohortIds);

    const idsFromContacts = selectedContacts.map((contact) =>
        isContactPhone(contact) ? contact.phone : contact.id,
    );
    const selectedContactIds = uniq([
        ...idsFromContacts,
        ...contactIdsFromCohorts,
    ]);

    const { data: existingConversation, isFetching } = useConversationFindQuery(
        inboxId,
        selectedContactIds,
    );

    const isLimitReached = useMemo(
        () => mode !== 'campaign' && selectedContactIds.length > 10,
        [mode, selectedContactIds],
    );

    const toggleMode = () => {
        setMode((prev) =>
            prev === Mode.Conversation ? Mode.Campaign : Mode.Conversation,
        );
    };
    const isBannerShown = useIsBannerShown();
    const optedOutCount = useCountOptedOut(
        selectedContacts.map(({ phone }) => phone).filter(isString),
    );

    const contactsCount = useMemo(() => {
        const contactsCountFromCohorts = selectedCohorts.reduce(
            (acc, cohort) => {
                return acc + cohort.contactsCount;
            },
            0,
        );

        return selectedContacts.length + contactsCountFromCohorts;
    }, [selectedContacts.length, selectedCohorts]);

    useEffect(() => {
        if (contactsCount > 10 && mode === Mode.Conversation) {
            setMode(Mode.Campaign);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contactsCount]);

    return (
        <NewConversationProvider inboxId={inboxId} onFinish={onFinish}>
            <Box
                sx={[
                    newConversation,
                    {
                        height: {
                            xs: `calc(100vh - ${EXTRA_OFFSET_ON_MOBILE}px)`,
                            md: isBannerShown
                                ? `calc(100vh - ${BANNER_HEIGHT}px)`
                                : '100vh',
                        },
                    },
                ]}
            >
                {!isCohortsFetched && !isEmpty(queryCohortIds) && (
                    <LinearProgress
                        sx={{
                            position: 'absolute',
                            left: 0,
                            top: 0,
                            width: 1,
                            zIndex: 1,
                        }}
                    />
                )}
                <SearchPanel
                    isLimitReached={isLimitReached}
                    selectedContacts={selectedContacts}
                    setSelectedContacts={setSelectedContacts}
                    selectedCohorts={selectedCohorts}
                    setSelectedCohorts={setSelectedCohorts}
                />
                {isLimitReached && (
                    <ConversationAlert
                        toggleMode={toggleMode}
                        isLimitReached={isLimitReached}
                    />
                )}
                {existingConversation && mode === Mode.Conversation && (
                    <InboxConversationWorkflow
                        inboxId={inboxId}
                        conversationId={existingConversation.id}
                        isMessagePreview={true}
                    />
                )}
                {((isFetching && mode === Mode.Conversation) || isLoading) && (
                    <Loading />
                )}
                <div>
                    <NewConversationControls
                        mode={mode}
                        toggleMode={toggleMode}
                        disabledMode={
                            contactsCount > 10 && mode === Mode.Campaign
                        }
                    />
                    {optedOutCount > 0 && (
                        <Box sx={{ p: 6 }}>
                            <PhonesOptedOutBanner count={optedOutCount} />
                        </Box>
                    )}
                    <PaymentFailedBanner />
                    <OutOfMessagesBanner />
                    <Box sx={message}>
                        <NewConversationMessage
                            inboxId={inboxId}
                            onFinish={onFinish}
                            mode={mode}
                            contactIds={selectedContactIds}
                            isLimitReached={isLimitReached}
                            setIsLoading={setIsLoading}
                            conversationId={existingConversation?.id}
                            disabled={optedOutCount > 0}
                        />
                    </Box>
                </div>
            </Box>
        </NewConversationProvider>
    );
};
