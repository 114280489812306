import { FormControl, FormHelperText, InputAdornment } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { SettingsRow } from '../SettingsRow';
import { OutOfOfficeInputs } from './constants';
import { EnhancedSelect } from '../../../components/EnhancedSelect/EnhancedSelect';
import { Option } from '../../../components/EnhancedSelect/types';
import { TIMEZONES } from '../../../components/workflows/WorkflowStepConfigurator/ScheduleStep/utils';
import { AccessTimeIcon } from '../../../components/ContactProperty/PropertyIcon/AccessTimeIcon';

export function TimezonesRow({ disabled = false }: { disabled?: boolean }) {
    const { control } = useFormContext<OutOfOfficeInputs>();

    return (
        <SettingsRow label="Timezone">
            <Controller
                control={control}
                render={({ field, formState }) => {
                    const hasError = Boolean(formState.errors.timezone);
                    return (
                        <FormControl error={hasError}>
                            <EnhancedSelect
                                searchable
                                placeholder="Select a timezone"
                                options={TIMEZONES as Option[]}
                                value={field.value}
                                onChange={(val) => field.onChange(val)}
                                sx={{
                                    width: {
                                        xs: 1,
                                        md: 320,
                                    },
                                }}
                                MenuProps={{
                                    MenuListProps: {
                                        'aria-label': 'Timezones list',
                                    },
                                }}
                                inputProps={{
                                    'aria-label': 'Timezone select',
                                }}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <AccessTimeIcon />
                                    </InputAdornment>
                                }
                                disabled={disabled}
                            />
                            {hasError && (
                                <FormHelperText>
                                    {formState.errors.timezone?.message}
                                </FormHelperText>
                            )}
                        </FormControl>
                    );
                }}
                name="timezone"
            />
        </SettingsRow>
    );
}
