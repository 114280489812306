import { Box, Stack } from '@mui/material';
import { ReactNode, useState } from 'react';
import { colors } from '../../theme/palette';
import { Action } from './Action';
import { Status } from './types';
import { TruncatedTypography } from '../TruncatedText/TruncatedText';
import { UUID } from '../../types/uuid';

type Props = {
    id: UUID;
    avatar: ReactNode;
    title: ReactNode;
    action: ReactNode;
    status: Status;
};

export const ActivityLine = ({ avatar, title, action, status, id }: Props) => {
    const [isHovered, setIsHovered] = useState(false);
    return (
        <Stack
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            direction="row"
            sx={{
                border: '1px solid',
                borderRadius: 1.5,
                borderColor: isHovered
                    ? colors.info.main
                    : colors.lightGrey[400],
                transition: 'border-color 0.2s',
                background:
                    status === Status.Unread ? colors.blue[25] : 'initial',
                alignItems: 'center',
                justifyContent: 'space-between',
                cursor: 'pointer',
                py: 2,
                px: 4,
                mb: 2,
            }}
        >
            <Stack
                direction="row"
                sx={{
                    gap: 4,
                    minHeight: 30,
                    alignItems: 'center',
                    maxWidth: 'calc(100% - 65px)',
                }}
            >
                {avatar}
                <TruncatedTypography
                    fontSize="14px"
                    lineHeight="20px"
                    lines={2}
                >
                    {title}
                </TruncatedTypography>
            </Stack>
            {isHovered ? (
                <Action
                    id={id}
                    action={action}
                    withMarkAsRead={status === Status.Unread}
                />
            ) : (
                status === Status.Unread && (
                    <Box
                        sx={{
                            p: 1,
                            bgcolor: 'info.main',
                            borderRadius: '50%',
                            mr: 2.5,
                        }}
                        data-testid="unread-activity-indicator"
                    />
                )
            )}
        </Stack>
    );
};
