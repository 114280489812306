import { IconButton } from '@mui/material';
import { ArrowForwardFilled } from '../../icons/common/CArrowForwardFilled';
import { Link as RouterLink } from 'react-router-dom';

export const Navigate = ({
    path,
    onClick,
}: {
    path: string;
    onClick?: () => void;
}) => {
    return (
        <IconButton
            color="primary"
            component={RouterLink}
            to={path}
            onClick={onClick}
        >
            <ArrowForwardFilled />
        </IconButton>
    );
};
