import createClient, { client } from './http';
import { Profile, UserTeamSetting } from './types';

export const getMe = () =>
    client.get<Profile>('/users/profile').then(({ data }) => data);

export function getSettings(): Promise<UserTeamSetting[]> {
    return createClient()
        .get<UserTeamSetting[]>('/users/settings')
        .then((r) => r.data);
}

export function updateMe(me: Partial<Profile>) {
    return createClient()
        .post('/users/profile', me)
        .then<Profile>((r) => r.data);
}

export function updateMeSettings(setting: Partial<UserTeamSetting>) {
    return createClient()
        .post('/users/settings', setting)
        .then((r) => r.data);
}

export function resetMicrosoftGraphCredentials() {
    return createClient().get('/users/microsoft/graph/reset');
}
