import {
    Box,
    Button,
    CircularProgress,
    InputAdornment,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import Callout from '../../components/Callout';
import {
    useBrandCreate,
    useBrandQuery,
    useBrandUpdate,
} from '../../queries/settings';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { PhoneVerificationProps } from './PhoneVerification';
import { useTrack } from '../../contexts/analytics';
import { useInbox } from '../../queries/inboxes';
import { CompanyIcon } from '../../icons/common/CCompanyIcon';
import { UserProfileIcon } from '../../icons/common/UserProfileIcon';
import Loading from '../../components/Loading/Loading';

type CustomerVerificationStepFormData = {
    fullName: string;
    companyName: string;
};

export const CustomerVerificationStep = () => {
    const navigate = useNavigate();
    const { inboxId } = useParams<PhoneVerificationProps>();
    const { control, handleSubmit, clearErrors } =
        useForm<CustomerVerificationStepFormData>();
    const { data: brand, isLoading: brandLoading } = useBrandQuery();
    const { mutateAsync: createBrand, isPending: createPending } =
        useBrandCreate();
    const { mutateAsync: updateBrand, isPending: updatePending } =
        useBrandUpdate();
    const { isLoading: inboxLoading, data: inbox } = useInbox(inboxId!);

    const track = useTrack();
    const isLoading =
        brandLoading || inboxLoading || createPending || updatePending;
    const [submitting, setSubmitting] = useState(false);

    if (inboxLoading) {
        return <Loading />;
    }

    if (!inbox) {
        return (
            <Box mt={6}>
                <Typography>
                    Oops! We didn&apos;t find that phone number! Please reload
                    and try again
                </Typography>
            </Box>
        );
    }

    const onSubmit: SubmitHandler<CustomerVerificationStepFormData> = async (
        data: CustomerVerificationStepFormData,
    ) => {
        setSubmitting(true);
        const payload = {
            companyName: data.companyName,
            contactFirstName: data.fullName.split(' ')[0],
            contactLastName: data.fullName.split(' ')[1],
        };
        if (brand?.id) {
            await updateBrand({
                id: brand.id,
                brand: payload,
            });
        } else {
            await createBrand(payload);
        }
        setSubmitting(false);
        clearErrors();

        track('verification_customer_info', {
            brandId: brand?.id,
            inboxId,
        });

        if (inbox.verified) {
            navigate(`/inbox/${inboxId}/verify/address`);
        } else {
            navigate(`/inbox/${inboxId}/verify/call`);
        }
    };

    if (brandLoading) {
        return <Loading />;
    }

    return (
        <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            width={1}
            mt={4}
        >
            <Stack spacing={6}>
                <Callout style="warning">
                    <span>📞</span>
                    <div>
                        Complete the verification to activate your number for
                        sending and receiving text messages.
                    </div>
                </Callout>

                <Controller
                    name="fullName"
                    control={control}
                    rules={{
                        required: true,
                        minLength: 3,
                        pattern: /^([A-Za-z-' ]+)[\s]([A-Za-z-' ]+)$/,
                    }}
                    defaultValue={[
                        brand?.contactFirstName,
                        brand?.contactLastName,
                    ]
                        .filter((x) => !!x)
                        .join(' ')}
                    render={({ field, fieldState }) => (
                        <TextField
                            label="Your full name"
                            required
                            error={fieldState.isDirty && fieldState.invalid}
                            helperText={
                                fieldState.isDirty && fieldState.invalid
                                    ? 'Please enter your full name'
                                    : null
                            }
                            InputProps={{
                                autoComplete: 'off',
                                notched: false,
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <UserProfileIcon />
                                    </InputAdornment>
                                ),
                            }}
                            fullWidth
                            {...field}
                        />
                    )}
                />

                <Controller
                    name="companyName"
                    control={control}
                    rules={{ required: true, minLength: 3 }}
                    defaultValue={brand?.companyName}
                    render={({ field, fieldState }) => (
                        <TextField
                            label="Legal Company Name"
                            required
                            error={fieldState.isDirty && fieldState.invalid}
                            helperText={
                                fieldState.isDirty && fieldState.invalid
                                    ? 'Company must be 3 characters long'
                                    : null
                            }
                            InputProps={{
                                autoComplete: 'off',
                                notched: false,
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <CompanyIcon />
                                    </InputAdornment>
                                ),
                            }}
                            fullWidth
                            {...field}
                        />
                    )}
                />

                <Box display="flex" justifyContent="flex-end" mt={2}>
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={isLoading || submitting}
                        sx={{ width: { xs: '100%', sm: '160px' } }}
                    >
                        {isLoading || submitting ? (
                            <CircularProgress size={24} color="inherit" />
                        ) : (
                            'Next'
                        )}
                    </Button>
                </Box>
            </Stack>
        </Box>
    );
};
