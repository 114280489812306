import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export const ObjectsColumnIcon = (
    props: Exclude<SvgIconProps, 'component'>,
) => (
    <SvgIcon {...props} viewBox="0 0 24 24" fill="none">
        <path
            d="M4 5.6C4 5.03995 4 4.75992 4.10899 4.54601C4.20487 4.35785 4.35785 4.20487 4.54601 4.10899C4.75992 4 5.03995 4 5.6 4H8.4C8.96005 4 9.24008 4 9.45399 4.10899C9.64215 4.20487 9.79513 4.35785 9.89101 4.54601C10 4.75992 10 5.03995 10 5.6V10.4C10 10.9601 10 11.2401 9.89101 11.454C9.79513 11.6422 9.64215 11.7951 9.45399 11.891C9.24008 12 8.96005 12 8.4 12H5.6C5.03995 12 4.75992 12 4.54601 11.891C4.35785 11.7951 4.20487 11.6422 4.10899 11.454C4 11.2401 4 10.9601 4 10.4V5.6Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M14 13.6C14 13.0399 14 12.7599 14.109 12.546C14.2049 12.3578 14.3578 12.2049 14.546 12.109C14.7599 12 15.0399 12 15.6 12H18.4C18.9601 12 19.2401 12 19.454 12.109C19.6422 12.2049 19.7951 12.3578 19.891 12.546C20 12.7599 20 13.0399 20 13.6V18.4C20 18.9601 20 19.2401 19.891 19.454C19.7951 19.6422 19.6422 19.7951 19.454 19.891C19.2401 20 18.9601 20 18.4 20H15.6C15.0399 20 14.7599 20 14.546 19.891C14.3578 19.7951 14.2049 19.6422 14.109 19.454C14 19.2401 14 18.9601 14 18.4V13.6Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M4 17.6C4 17.0399 4 16.7599 4.10899 16.546C4.20487 16.3578 4.35785 16.2049 4.54601 16.109C4.75992 16 5.03995 16 5.6 16H8.4C8.96005 16 9.24008 16 9.45399 16.109C9.64215 16.2049 9.79513 16.3578 9.89101 16.546C10 16.7599 10 17.0399 10 17.6V18.4C10 18.9601 10 19.2401 9.89101 19.454C9.79513 19.6422 9.64215 19.7951 9.45399 19.891C9.24008 20 8.96005 20 8.4 20H5.6C5.03995 20 4.75992 20 4.54601 19.891C4.35785 19.7951 4.20487 19.6422 4.10899 19.454C4 19.2401 4 18.9601 4 18.4V17.6Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M14 5.6C14 5.03995 14 4.75992 14.109 4.54601C14.2049 4.35785 14.3578 4.20487 14.546 4.10899C14.7599 4 15.0399 4 15.6 4H18.4C18.9601 4 19.2401 4 19.454 4.10899C19.6422 4.20487 19.7951 4.35785 19.891 4.54601C20 4.75992 20 5.03995 20 5.6V6.4C20 6.96005 20 7.24008 19.891 7.45399C19.7951 7.64215 19.6422 7.79513 19.454 7.89101C19.2401 8 18.9601 8 18.4 8H15.6C15.0399 8 14.7599 8 14.546 7.89101C14.3578 7.79513 14.2049 7.64215 14.109 7.45399C14 7.24008 14 6.96005 14 6.4V5.6Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </SvgIcon>
);
