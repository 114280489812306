import { CampaignMessage, useCampaignMessages } from '../use-campaign-messages';
import groupBy from 'lodash/groupBy';
import isEmpty from 'lodash/isEmpty';
import toPairs from 'lodash/toPairs';
import dayjs from 'dayjs';
import { Message } from '../CampaignMessage/Message';
import { HistorySkeleton } from './HistorySkeleton';
import { NoMessages } from '../NoMessages';
import { MessagesLink } from '../CampaignMessage/MessagesLink';
import { useRef } from 'react';
import { DateDivider } from '../../../../pages/NewInbox/Conversation/DateDivider';

type Props = {
    campaignId: number;
    onEdit?: (c: CampaignMessage) => void;
};

export const History = ({ campaignId, onEdit }: Props) => {
    const {
        data: messages,
        isLoading,
        isError,
    } = useCampaignMessages(campaignId);
    const bottom = useRef<HTMLDivElement>(null);
    const dated = toPairs(
        groupBy(messages || [], (message) =>
            dayjs(message.timestamp).format('MMMM D, YYYY'),
        ),
    );

    if (isLoading) {
        return <HistorySkeleton />;
    }

    if (isError && !messages) {
        return <div>Something went wrong</div>;
    }

    if (!messages || isEmpty(messages)) {
        return <NoMessages />;
    }

    return (
        <div data-testid="campaign-history">
            <div
                tabIndex={0}
                onFocus={() => bottom.current?.focus()}
                data-navigation-element
            />
            {dated.map(([date, messages]) => (
                <div key={date}>
                    <DateDivider date={date} />
                    {messages.map((message) => (
                        <Message
                            key={message.id}
                            message={message}
                            onEdit={onEdit ? () => onEdit(message) : undefined}
                            sx={{ my: 6 }}
                        >
                            {message.sentCount > 0 && (
                                <MessagesLink message={message} />
                            )}
                        </Message>
                    ))}
                </div>
            ))}
            <div
                ref={bottom}
                tabIndex={0}
                aria-label="Chat history. Navigate up to read earlier messages. Navigate down to write a new message."
                data-navigation-element
            />
        </div>
    );
};
