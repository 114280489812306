import { Suspense, useCallback, useMemo } from 'react';
import {
    DragDropContext,
    Droppable,
    DroppableProvided,
    DropResult,
} from '@hello-pangea/dnd';

import { Box } from '@mui/material';
import useSaveInboxesOrder from '../../../hooks/useSaveInboxesOrder';
import useSortedInboxes from '../../../hooks/useSortedInboxes';
import { CollapsibleAccordionSection } from '../../../components/CollapsibleAccordionSection/CollapsibleAccordionSection';
import { MAX_INBOXES_BEFORE_VIRTUALIZATION } from '../SideBarInboxes/SideBarInboxes';
import { InboxListSkeleton } from '../SideBarInboxes/InboxListSkeleton';
import VirtualizedInboxList from '../SideBarInboxes/VirtualizedInboxList';
import { InboxList } from '../SideBarInboxes/InboxList';

interface SideBarMutedInboxesProps {
    setMenuOpen: (value: boolean) => void;
}

export const SideBarMutedInboxes = ({
    setMenuOpen,
}: SideBarMutedInboxesProps) => {
    const { muted: mutedSortedInboxes } = useSortedInboxes();
    const saveInboxesOrder = useSaveInboxesOrder(true);

    const handleOnDragEnd = useCallback(
        (result: DropResult) => {
            if (!result.destination) {
                return;
            }

            saveInboxesOrder(result.source.index, result.destination.index);
        },
        [saveInboxesOrder],
    );

    const handleInboxItemClick = useCallback(() => {
        setMenuOpen(false);
    }, [setMenuOpen]);

    const isVirtualized = useMemo(
        () => mutedSortedInboxes.length > MAX_INBOXES_BEFORE_VIRTUALIZATION,
        [mutedSortedInboxes.length],
    );

    if (mutedSortedInboxes.length > 0) {
        return (
            <CollapsibleAccordionSection
                title={`Muted Phones (${mutedSortedInboxes.length})`}
                defaultExpanded={false}
            >
                <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId="inboxes-list">
                        {(providedDroppable: DroppableProvided) => (
                            <Box
                                ref={providedDroppable.innerRef}
                                {...providedDroppable.droppableProps}
                                tabIndex={-1}
                                sx={{
                                    height: isVirtualized ? 300 : undefined,
                                }}
                            >
                                {isVirtualized ? (
                                    <Suspense fallback={<InboxListSkeleton />}>
                                        <VirtualizedInboxList
                                            inboxes={mutedSortedInboxes}
                                            onInboxClick={handleInboxItemClick}
                                        />
                                    </Suspense>
                                ) : (
                                    <InboxList
                                        inboxes={mutedSortedInboxes}
                                        onInboxClick={handleInboxItemClick}
                                    />
                                )}
                                {providedDroppable.placeholder}
                            </Box>
                        )}
                    </Droppable>
                </DragDropContext>
            </CollapsibleAccordionSection>
        );
    }

    return null;
};
