import { CustomEditor, CustomElement } from '../../../../Slate/slate';

export const withTemplates = (editor: CustomEditor) => {
    const { isInline, isVoid } = editor;

    editor.isInline = (element: CustomElement) => {
        return element.type === 'template' ? true : isInline(element);
    };

    editor.isVoid = (element: CustomElement) => {
        return element.type === 'template' ? true : isVoid(element);
    };

    return editor;
};
