import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export const GrabberIcon = (props: Exclude<SvgIconProps, 'component'>) => (
    <SvgIcon
        {...props}
        viewBox="0 0 24 24"
        fill="none"
        width="1em"
        height="1em"
    >
        <path
            d="M13.75 18.5C13.75 19.7375 14.7625 20.75 16 20.75C17.2375 20.75 18.25 19.7375 18.25 18.5C18.25 17.2625 17.2375 16.25 16 16.25C14.7625 16.25 13.75 17.2625 13.75 18.5ZM13.75 5C13.75 6.2375 14.7625 7.25 16 7.25C17.2375 7.25 18.25 6.2375 18.25 5C18.25 3.7625 17.2375 2.75 16 2.75C14.7625 2.75 13.75 3.7625 13.75 5ZM13.75 11.75C13.75 12.9875 14.7625 14 16 14C17.2375 14 18.25 12.9875 18.25 11.75C18.25 10.5125 17.2375 9.5 16 9.5C14.7625 9.5 13.75 10.5125 13.75 11.75Z"
            fill="currentColor"
        />
        <path
            d="M5.75 18.5C5.75 19.7375 6.7625 20.75 8 20.75C9.2375 20.75 10.25 19.7375 10.25 18.5C10.25 17.2625 9.2375 16.25 8 16.25C6.7625 16.25 5.75 17.2625 5.75 18.5ZM5.75 5C5.75 6.2375 6.7625 7.25 8 7.25C9.2375 7.25 10.25 6.2375 10.25 5C10.25 3.7625 9.2375 2.75 8 2.75C6.7625 2.75 5.75 3.7625 5.75 5ZM5.75 11.75C5.75 12.9875 6.7625 14 8 14C9.2375 14 10.25 12.9875 10.25 11.75C10.25 10.5125 9.2375 9.5 8 9.5C6.7625 9.5 5.75 10.5125 5.75 11.75Z"
            fill="currentColor"
        />
    </SvgIcon>
);
