import cc from 'classcat';
import { useRef } from 'react';
import styles from './HeaderMenu.module.scss';
import useOnClickOutside from '../../../hooks/useOnClickOutside';
import { EditIcon } from '../../../icons/common/CEditIcon';
import { DeleteIcon } from '../../../icons/common/CDeleteIcon';
import { colors } from '../../../theme/palette';
import { getSquare } from '../../../theme/style.helpers';

interface HeaderMenuProps {
    onOutsideClick: () => void;
    onEditClick: () => void;
    onDeleteClick: () => void;
}

export const HeaderMenu = ({
    onOutsideClick,
    onEditClick,
    onDeleteClick,
}: HeaderMenuProps) => {
    const wrapperRef = useRef<HTMLDivElement>(null);

    useOnClickOutside(wrapperRef, onOutsideClick);

    return (
        <div className={styles['header-menu']} ref={wrapperRef}>
            <ul className={styles['header-menu__items']}>
                <li className={styles['header-menu__item']}>
                    <button
                        className={cc([
                            styles['header-menu__action-btn'],
                            styles['header-menu__edit-btn'],
                        ])}
                        onClick={onEditClick}
                    >
                        <EditIcon sx={getSquare(20)} />
                        <span>Edit name</span>
                    </button>
                </li>
                <li className={styles['header-menu__item']}>
                    <button
                        className={cc([
                            styles['header-menu__action-btn'],
                            styles['header-menu__delete-btn'],
                        ])}
                        onClick={onDeleteClick}
                    >
                        <DeleteIcon
                            sx={{ ...getSquare(20), color: colors.red[500] }}
                        />
                        <span>Delete</span>
                    </button>
                </li>
            </ul>
        </div>
    );
};
