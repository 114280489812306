import {
    Autocomplete,
    AutocompletePropsSizeOverrides,
    CircularProgress,
    SxProps,
    TextField,
    Theme,
} from '@mui/material';
import { OverridableStringUnion } from '@mui/types';
import { useCohorts } from '../../../queries/cohorts';
import { CohortMeta } from '../../../api/types';
import * as React from 'react';
import { AutocompleteRenderOptionState } from '@mui/material/Autocomplete/Autocomplete';
import { useMemo } from 'react';

type Props = {
    renderOption?: (
        props: React.HTMLAttributes<HTMLLIElement>,
        option: CohortMeta,
        state: AutocompleteRenderOptionState,
    ) => React.ReactNode;
    placeholder?: string;
    disabled?: boolean;
    size?: OverridableStringUnion<
        'small' | 'medium',
        AutocompletePropsSizeOverrides
    >;
    selected?: { id: CohortMeta['id'] } & Partial<CohortMeta>;
    onChange: (c?: CohortMeta | null) => void;
    sx?: SxProps<Theme>;
    fullWidth?: boolean;
};
export const CohortAutocomplete = (props: Props) => {
    const { data = [], isLoading } = useCohorts();
    const selected = useMemo(() => {
        if (!props.selected) {
            return null;
        }

        const selectedCohort = data.find(
            (list) => list.id === props.selected?.id,
        );

        if (!selectedCohort) {
            return null;
        }

        return {
            ...selectedCohort,
            ...props.selected,
        };
    }, [data, props.selected]);

    return (
        <Autocomplete
            fullWidth={props.fullWidth}
            size={props.size}
            sx={props.sx}
            disabled={isLoading}
            value={selected}
            onChange={(_, value) => props.onChange(value ? value : undefined)}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            popupIcon={
                isLoading ? (
                    <CircularProgress color="info" size={20} />
                ) : undefined
            }
            options={data}
            getOptionLabel={({ id, name }) =>
                data.find((list) => list.id === id)?.name ??
                name ??
                'Unnamed list'
            }
            getOptionKey={({ id }) => id}
            aria-label="Lists filter"
            renderOption={props.renderOption}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="outlined"
                    placeholder={props.placeholder ?? 'All lists'}
                />
            )}
        />
    );
};
