import cc from 'classcat';
import styles from './ToggleSwitch.module.scss';

type ToggleSwitchProps = {
    onToggle: () => void;
    isChecked: boolean;
    disabled?: boolean;
    className?: string;
};

/** @deprecated use mui switch instead */
export const ToggleSwitch = (props: ToggleSwitchProps) => (
    <label
        className={
            props.className
                ? cc([styles['switch'], props.className])
                : styles['switch']
        }
    >
        <input
            type="checkbox"
            checked={props.isChecked}
            disabled={props.disabled}
            onChange={props.onToggle}
        />
        <span className={styles['slider']} />
    </label>
);
