import { Grid, Stack, Typography, Link } from '@mui/material';
import { container } from './styles';
import { ClerkLogoIcon } from '../../components/ContactProperty/SourceIcon/ClerkLogoIcon';
import { getSquare } from '../../theme/style.helpers';

type AuthLayoutProps = {
    children: React.ReactNode;
};

export const AuthLayout = (props: AuthLayoutProps) => (
    <Stack
        p={6}
        height="100vh"
        direction="row"
        justifyContent="space-between"
        sx={container}
        overflow="auto"
    >
        <Grid
            container
            direction="column"
            flex={1}
            justifyContent="space-between"
            alignItems="center"
            flexWrap="nowrap"
        >
            <Stack
                alignSelf="flex-start"
                spacing={2}
                direction="row"
                alignItems="center"
                mt={4}
                ml={4}
            >
                <ClerkLogoIcon sx={getSquare(32)} />
                <Typography variant="h4" color="primary.dark">
                    Clerk Chat
                </Typography>
            </Stack>
            <Grid
                item
                container
                justifyContent="center"
                alignItems="center"
                flex={1}
            >
                {props.children}
            </Grid>
            <Stack spacing={6} width={400} py={4}>
                <Typography
                    variant="body4"
                    color="custom.gray.super"
                    align="center"
                >
                    By proceeding you acknowledge that you have read, understood
                    and agree to our{' '}
                    <Link
                        variant="body4"
                        href="https://clerk.chat/legal/terms"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Terms and Conditions
                    </Link>
                    .
                </Typography>

                <Stack
                    spacing={8}
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Typography variant="body4" color="custom.gray.super">
                        © 2025 Clerk Chat
                    </Typography>

                    <Link
                        variant="body4"
                        href="https://clerk.chat/legal/privacy"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Privacy Policy
                    </Link>

                    <Link
                        variant="body4"
                        href="https://support.clerk.chat"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Support
                    </Link>
                </Stack>
            </Stack>
        </Grid>
    </Stack>
);
