import { SvgIcon } from '@mui/material';
import { ClerkIconProps } from '../../types/clerk-icon.props';

export const ReceiptDoubleCheckIcon = (props: ClerkIconProps) => (
    <SvgIcon {...props}>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
        >
            <g clipPath="url(#clip0_33497_102267)">
                <path
                    d="M10.9957 4.23023C10.6853 3.91638 10.1796 3.91242 9.86436 4.22141L3.95102 10.0658L1.38044 7.40459C1.07384 7.08504 0.56627 7.07455 0.246757 7.38115C-0.07279 7.68774 -0.0832783 8.19531 0.223315 8.51483C0.224704 8.51625 0.226058 8.51767 0.227448 8.5191L3.36173 11.7672C3.51172 11.9202 3.71673 12.0069 3.93101 12.0078H3.93983C4.15005 12.0071 4.35162 11.9239 4.5011 11.776L10.9885 5.36159C11.3019 5.05076 11.3051 4.54506 10.9957 4.23023Z"
                    fill="currentColor"
                />
                <path
                    d="M8.17252 11.7672C8.32214 11.9199 8.52649 12.0064 8.74021 12.0078H8.74903C8.95925 12.0071 9.16082 11.9239 9.3103 11.776L15.7977 5.36159C16.0918 5.03048 16.0617 4.52371 15.7307 4.22965C15.4305 3.96307 14.9795 3.95956 14.6752 4.22142L8.76424 10.0658L8.55337 9.84689C8.24681 9.52734 7.73924 9.51682 7.41969 9.82341C7.10014 10.13 7.08962 10.6375 7.39621 10.9571C7.3976 10.9585 7.39899 10.96 7.40038 10.9614L8.17252 11.7672Z"
                    fill="currentColor"
                />
            </g>
            <defs>
                <clipPath id="clip0_33497_102267">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    </SvgIcon>
);
