import { useMutation, useQuery } from '@tanstack/react-query';
import {
    ActivityResponse,
    countUnread,
    getActivities,
    markActivityAsRead,
    markAllActivitiesAsRead,
} from '../api/activity';
import { Activity, Status } from '../components/ActivityConstructor/types';
import { queryClient } from './queryClient';

const ACTIVITY_KEY = 'activity';
export const UNREAD_ACTIVITIES_COUNT_KEY = 'unread_activities_count';
export const useActivity = () =>
    useQuery({
        queryKey: [ACTIVITY_KEY],
        queryFn: getActivities,
    });

export const useMarkAllActivitiesAsRead = () => {
    return useMutation({
        mutationFn: markAllActivitiesAsRead,
        onMutate: () => {
            queryClient.setQueryData<ActivityResponse>(
                [ACTIVITY_KEY],
                (oldData) => ({
                    ...oldData,
                    data:
                        oldData?.data.map((a) => ({
                            ...a,
                            status: Status.Read,
                        })) ?? [],
                    links: oldData?.links ?? {},
                    total: oldData?.total ?? 0,
                }),
            );

            queryClient.setQueryData([UNREAD_ACTIVITIES_COUNT_KEY], 0);
        },
    });
};

export const useMarkActivityAsRead = () => {
    return useMutation({
        mutationFn: markActivityAsRead,
        onMutate: (id: string) => {
            const previousActivities =
                queryClient.getQueryData<ActivityResponse>([ACTIVITY_KEY]);
            queryClient.setQueryData<ActivityResponse>(
                [ACTIVITY_KEY],
                (oldData) => {
                    if (!oldData) {
                        return oldData;
                    }
                    return {
                        ...oldData,
                        data: oldData.data.map((a) => ({
                            ...a,
                            status: a.id === id ? Status.Read : a.status,
                        })),
                        links: oldData.links,
                        total: oldData.total,
                    };
                },
            );

            return {
                previousActivities,
            };
        },
        onSuccess: (updatedActivity: Activity) => {
            queryClient.refetchQueries({
                queryKey: [UNREAD_ACTIVITIES_COUNT_KEY],
            });
            queryClient.setQueryData(
                [ACTIVITY_KEY],
                (activities: ActivityResponse) => ({
                    ...activities,
                    data: activities.data.map((a) =>
                        a.id === updatedActivity.id ? updatedActivity : a,
                    ),
                    links: activities.links,
                    total: activities.total,
                }),
            );
        },
    });
};

export const useUnreadActivitiesCount = () =>
    useQuery({
        queryKey: [UNREAD_ACTIVITIES_COUNT_KEY],
        queryFn: countUnread,
    });
