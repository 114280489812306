import * as storage from '../utils/local-storage';
import axios, { CreateAxiosDefaults } from 'axios';

/** @deprecated use client insted */
export const createClient = () => {
    const options: CreateAxiosDefaults = {
        baseURL: new URL(import.meta.env.VITE_API_BASE_URL).toString(),
    };

    const token = storage.get('auth');

    if (token) {
        options.headers = {
            Authorization: `Bearer ${token}`,
        };
    }

    return axios.create(options);
};

export default createClient;

export const API_VASE_URL =
    import.meta.env.VITE_API_BASE_URL || 'https://web-api.clerk.chat';
if (!import.meta.env.VITE_API_BASE_URL) {
    // eslint-disable-next-line no-console
    console.warn('API_BASE_URL is not set, using default value');
}
export const client = axios.create({
    baseURL: new URL(API_VASE_URL).toString(),
});

client.interceptors.request.use((config) => {
    const token = storage.get('auth');
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});
