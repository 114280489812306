import { useNavigate, useParams } from 'react-router';
import { useEffect, useMemo, useState } from 'react';
import { validate as uuidValidate } from 'uuid';
import { useInboxes } from '../../queries/inboxes';
import { usePropertiesQuery } from '../../queries/properties';
import { ModernInbox } from '../../pages/NewInbox/ModernInbox';
import { Entity } from '../../pages/NewInbox/query.helpers';
import { useConversation } from '../../queries/conversations';
import { InboxStatus, RoleTypes } from '../../api/types';
import { useInboxSearchParams } from './useInboxSearchParams';
import { useCurrentRole } from '../../utils/user';
import { AdminInbox } from '../../components/AdminInbox/AdminInbox';
import Loading from '../../components/Loading/Loading';

type View = 'conversation' | 'campaign' | 'campaigns' | 'finish' | null;

const detectView = (entityId: string): View => {
    if (entityId === 'finish') {
        return 'finish';
    }

    if (entityId === 'campaigns') {
        return 'campaigns';
    }

    if (uuidValidate(entityId)) {
        return 'conversation';
    }

    const campaignId = Number.parseInt(entityId, 10);
    if (
        entityId?.length !== 36 && //definitely not a UUID
        Number.isFinite(campaignId) &&
        !Number.isNaN(campaignId) &&
        campaignId > 0
    ) {
        return 'campaign';
    }

    return null;
};

export function InboxPage() {
    usePropertiesQuery();
    const navigate = useNavigate();
    const currentRole = useCurrentRole();
    const {
        inboxId,
        actionId,
        entity: paramsEntity,
        entityId: paramsEntityId,
        subEntityId,
    } = useParams<{
        inboxId: string;
        actionId: string;
        entity: string;
        entityId?: string;
        subEntityId?: string;
        campaignId?: string;
        campaignMessageId?: string;
    }>();
    const query = useInboxSearchParams();
    const { data: inboxes = [], isLoading } = useInboxes();
    const [view, setView] = useState<View>(detectView(actionId!));

    const inbox = useMemo(
        () => inboxes.find(({ id }) => id === inboxId),
        [inboxes, inboxId],
    );

    const { data: conversation } = useConversation(
        paramsEntity === 'conversations' ? paramsEntityId : undefined,
    );
    const hasContactInfo = useMemo(() => {
        return (
            paramsEntity === 'conversations' &&
            inbox &&
            conversation?.members.length === 1
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [view, inbox, conversation?.members.length]);

    useEffect(() => {
        if (inbox?.status === 'pending') {
            navigate(`/inbox/${inbox.id}/verify/customer`);
        }
    }, [inbox?.id, inbox?.status, navigate]);

    useEffect(() => {
        setView(detectView(actionId!));
    }, [actionId]);

    if (isLoading && !inbox) {
        return <Loading />;
    }

    if (currentRole === RoleTypes.Admin) {
        return <AdminInbox inbox={inbox!} />;
    }

    if ([null, 'campaign', 'campaigns'].includes(view)) {
        const entity = [
            Entity.Campaigns,
            Entity.Conversations,
            Entity.New,
        ].includes(paramsEntity as Entity)
            ? paramsEntity
            : Entity.Conversations;
        const entityId =
            entity === Entity.Campaigns && paramsEntityId
                ? +paramsEntityId
                : paramsEntityId;
        return (
            <ModernInbox
                inboxId={inboxId!}
                entity={entity as Entity}
                entityId={entityId}
                subEntityId={+subEntityId!}
                query={query}
                hasContactInfo={hasContactInfo}
                pendingActivationInbox={
                    inbox &&
                    [
                        InboxStatus.PENDING_HOSTING,
                        InboxStatus.PENDING_VALIDATION,
                    ].includes(inbox?.status)
                        ? inbox
                        : undefined
                }
            />
        );
    }
}
