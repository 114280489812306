import {
    Box,
    Button,
    CircularProgress,
    FormControl,
    InputAdornment,
    MenuItem,
    Stack,
    TextField,
} from '@mui/material';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import Callout from '../../components/Callout';
import { useBrandQuery, useBrandUpdate } from '../../queries/settings';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { CompanyEntityType } from '../../api/types';
import {
    canadaStateOptions,
    countriesOptions,
    organizationLegalFormOptions,
    stateOptions,
} from '../../main/settings/Compliance/utils';
import { PhoneVerificationProps } from './PhoneVerification';
import { AddressIcon } from '../../icons/common/CAddressIcon';
import { MapIcon } from '../../icons/common/CMap';
import { CountryIcon } from '../../icons/common/CCountryIcon';
import { ZipIcon } from '../../icons/common/CZipIcon';
import { LegalIcon } from '../../icons/common/CLegalIcon';

// Updated to match the Brand interface
interface AddressVerificationStepFormData {
    companyAddress: string;
    city: string;
    state: string;
    companyZip: string;
    companyCountry: 'US' | 'CA';
    organizationLegalForm: CompanyEntityType;
}

export const AddressVerificationStep = () => {
    const { inboxId } = useParams<PhoneVerificationProps>();
    const navigate = useNavigate();
    const { control, handleSubmit, clearErrors, watch } =
        useForm<AddressVerificationStepFormData>();
    const watchCountry = watch('companyCountry');
    const { data: brand, isLoading: brandLoading } = useBrandQuery();
    const { mutateAsync: updateBrand, isPending } = useBrandUpdate();

    const [isLoading, setLoading] = useState(false);

    const onSubmit: SubmitHandler<AddressVerificationStepFormData> = async (
        data: AddressVerificationStepFormData,
    ) => {
        setLoading(true);
        await updateBrand({
            brand: {
                companyAddress: data.companyAddress,
                city: data.city,
                state: data.state,
                companyZip: data.companyZip,
                companyCountry: data.companyCountry,
                organizationLegalForm: data.organizationLegalForm,
            },
            id: brand!.id,
        });
        setLoading(false);
        clearErrors();

        navigate(`/inbox/${inboxId}/verify/phone-type`);
    };

    return brandLoading ? (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
        >
            <CircularProgress />
        </Box>
    ) : (
        <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            width={1}
            mt={4}
        >
            <Stack spacing={6}>
                <Callout style="warning">
                    <span>☝️ </span>
                    Please ensure the address matches to the one currently on
                    file with your carrier.
                </Callout>

                <Controller
                    name="companyAddress"
                    control={control}
                    defaultValue={brand?.companyAddress}
                    rules={{ required: true, minLength: 5 }}
                    render={({ field, fieldState }) => (
                        <TextField
                            label="Address"
                            required
                            autoFocus
                            error={fieldState.isDirty && fieldState.invalid}
                            helperText={
                                fieldState.isDirty && fieldState.invalid
                                    ? 'Address is required'
                                    : null
                            }
                            InputProps={{
                                autoComplete: 'off',
                                notched: false,
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <AddressIcon />
                                    </InputAdornment>
                                ),
                            }}
                            fullWidth
                            {...field}
                        />
                    )}
                />

                <Stack spacing={6} direction={{ xs: 'column', sm: 'row' }}>
                    <Controller
                        name="city"
                        control={control}
                        rules={{ required: true, minLength: 3 }}
                        defaultValue={brand?.city}
                        render={({ field, fieldState }) => (
                            <TextField
                                label="City"
                                required
                                error={fieldState.isDirty && fieldState.invalid}
                                helperText={
                                    fieldState.isDirty && fieldState.invalid
                                        ? 'City must be 3 characters long'
                                        : null
                                }
                                InputProps={{
                                    autoComplete: 'off',
                                    notched: false,
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <MapIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                fullWidth
                                {...field}
                            />
                        )}
                    />

                    <Controller
                        name="state"
                        control={control}
                        rules={{ required: true }}
                        defaultValue={brand?.state}
                        render={({ field, fieldState }) => (
                            <TextField
                                label="State"
                                select
                                error={!!fieldState.error}
                                helperText={fieldState.error?.message}
                                InputProps={{
                                    autoComplete: 'off',
                                    notched: false,
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <MapIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                value={field.value || ''}
                                onChange={field.onChange}
                                fullWidth
                            >
                                {(watchCountry === 'CA'
                                    ? canadaStateOptions
                                    : stateOptions
                                ).map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        )}
                    />
                </Stack>

                <Stack spacing={6} direction={{ xs: 'column', sm: 'row' }}>
                    <Controller
                        name="companyCountry"
                        control={control}
                        rules={{ required: true }}
                        defaultValue={brand?.companyCountry || 'US'}
                        render={({ field, fieldState }) => (
                            <FormControl error={!!fieldState.error} fullWidth>
                                <TextField
                                    label="Country"
                                    select
                                    error={fieldState.invalid}
                                    helperText={fieldState.error?.message}
                                    InputProps={{
                                        startAdornment: !field.value && (
                                            <InputAdornment position="start">
                                                <CountryIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                    value={field.value || ''}
                                    onChange={field.onChange}
                                    fullWidth
                                >
                                    {countriesOptions.map((option) => (
                                        <MenuItem
                                            key={option.id}
                                            value={option.id}
                                        >
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </FormControl>
                        )}
                    />

                    <Controller
                        name="companyZip"
                        control={control}
                        rules={{ required: true, minLength: 3 }}
                        defaultValue={brand?.companyZip}
                        render={({ field, fieldState }) => (
                            <TextField
                                label="ZIP / Postal Code"
                                required
                                error={fieldState.isDirty && fieldState.invalid}
                                helperText={
                                    fieldState.isDirty && fieldState.invalid
                                        ? 'Zip code must be 3 characters long'
                                        : null
                                }
                                InputProps={{
                                    autoComplete: 'off',
                                    notched: false,
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <ZipIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                fullWidth
                                {...field}
                            />
                        )}
                    />
                </Stack>

                <Controller
                    name="organizationLegalForm"
                    control={control}
                    rules={{ required: true }}
                    defaultValue={
                        brand?.organizationLegalForm ||
                        CompanyEntityType.PRIVATE_PROFIT
                    }
                    render={({ field, fieldState }) => (
                        <TextField
                            label="Entity Type"
                            select
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            InputProps={{
                                autoComplete: 'off',
                                notched: false,
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <LegalIcon />
                                    </InputAdornment>
                                ),
                            }}
                            value={field.value || ''}
                            onChange={(event) => {
                                field.onChange(event.target.value);
                            }}
                            fullWidth
                        >
                            {organizationLegalFormOptions.map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    )}
                />

                <Stack
                    spacing={6}
                    direction={{ xs: 'column', sm: 'row' }}
                    justifyContent="space-between"
                >
                    <Box
                        width={{ xs: '100%', sm: '48%' }}
                        display="flex"
                        justifyContent="flex-start"
                    >
                        <Button
                            variant="outlined"
                            disabled={isLoading || isPending}
                            onClick={() =>
                                navigate(`/inbox/${inboxId}/verify/customer`)
                            }
                            sx={{ width: { xs: '100%', sm: '160px' } }}
                        >
                            Back
                        </Button>
                    </Box>
                    <Box
                        width={{ xs: '100%', sm: '48%' }}
                        display="flex"
                        justifyContent="flex-end"
                    >
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            disabled={isLoading || isPending}
                            sx={{ width: { xs: '100%', sm: '160px' } }}
                        >
                            {isLoading || isPending ? (
                                <CircularProgress size={24} color="inherit" />
                            ) : (
                                'Proceed'
                            )}
                        </Button>
                    </Box>
                </Stack>
            </Stack>
        </Box>
    );
};
