import { Box, Button, Stack } from '@mui/material';
import backImage from '../../../../assets/newAssets/images/back-image.png';
import { getSquare } from '../../../../theme/style.helpers';
import { PropsWithChildren } from 'react';
import { useTrack } from '../../../../contexts/analytics';
import { signOut } from '../../../../api/auth';
import { TeamSelector } from '../../../../components/TeamSelector/TeamSelector';

export const BlockedOnboardingScreenLayout = ({
    children,
}: PropsWithChildren) => {
    const track = useTrack();
    return (
        <Box sx={{ height: '100vh', p: '39px', position: 'relative' }}>
            <Box
                sx={{
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    ...getSquare(40),
                    borderRight: '1px solid',
                    borderBottom: '1px solid',
                    borderColor: '#DBE1EA',
                }}
            />
            <Box
                sx={{
                    position: 'absolute',
                    right: 0,
                    top: 0,
                    ...getSquare(40),
                    borderLeft: '1px solid',
                    borderBottom: '1px solid',
                    borderColor: '#DBE1EA',
                }}
            />
            <Box
                sx={{
                    position: 'absolute',
                    left: 0,
                    bottom: 0,
                    ...getSquare(40),
                    borderRight: '1px solid',
                    borderTop: '1px solid',
                    borderColor: '#DBE1EA',
                }}
            />
            <Box
                sx={{
                    position: 'absolute',
                    right: 0,
                    bottom: 0,
                    ...getSquare(40),
                    borderLeft: '1px solid',
                    borderTop: '1px solid',
                    borderColor: '#DBE1EA',
                }}
            />
            <Box
                sx={{
                    background: `url(${backImage}) no-repeat center top / cover`,
                    height: 'calc(100% - 2px)',
                    border: '1px solid',
                    borderColor: '#DBE1EA',
                    boxSizing: 'content-box',
                }}
            >
                <Box sx={{ p: 10, boxSizing: 'border-box', height: 1 }}>
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Stack gap={2} direction="row">
                            <TeamSelector teamOnly withBackground />
                        </Stack>
                        <Button
                            onClick={async () => {
                                track('sign_out_blocked_onboarding');
                                await signOut();
                            }}
                            size="small"
                            variant="outlined"
                            color="info"
                        >
                            Logout
                        </Button>
                    </Stack>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            pt: 25,
                            height: 1,
                        }}
                    >
                        {children}
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};
