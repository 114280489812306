import { pixelize } from '../../pages/NewInbox/inbox.styles';
import { Box, BoxProps, SxProps } from '@mui/material';

const indicator: SxProps = {
    backgroundColor: 'primary.main',
    color: 'primary.contrastText',
    fontSize: 10,
    lineHeight: pixelize(16),
    letterSpacing: pixelize(0.2),
    px: 1.5,
    borderRadius: 15,
    textAlign: 'center',
};

export const Indicator = ({ children, sx = [], ...rest }: BoxProps) => (
    <Box sx={[indicator, ...(Array.isArray(sx) ? sx : [sx])]} {...rest}>
        {children}
    </Box>
);
