import { Inbox } from '../../api/types';
import { IconButton } from '@mui/material';
import { InboxContextMenu } from './InboxContextMenu';
import { useMenu } from '../../hooks/use-menu';
import { theme } from '../../theme/theme';
import { focusVisible } from '../../theme/focusVisible';
import { SettingsIcon } from '../../icons/common/SettingsIcon';
import { useSidebarContext } from './InboxSettingsContext';
import { isMobile } from '../../utils/mobile';

export function ContextMenu({
    inboxId,
    disabled = false,
}: {
    inboxId: Inbox['id'];
    disabled?: boolean;
}) {
    const menu = useMenu();
    const { openInboxSettings } = useSidebarContext();
    return (
        <>
            <IconButton
                disabled={disabled}
                id={`inbox-context-menu-button-${inboxId}`}
                aria-controls={
                    menu.open ? `inbox-context-menu-${inboxId}` : undefined
                }
                aria-expanded={menu.open ? 'true' : undefined}
                onClick={(e) => {
                    if (isMobile()) {
                        e.preventDefault();
                        menu.handleOpen(e);
                    } else {
                        openInboxSettings({ inboxId });
                    }
                }}
                sx={{
                    '&.MuiButtonBase-root.MuiIconButton-root:focus-visible': {
                        ...focusVisible,
                        outlineOffset: '0px',
                        backgroundColor: theme.palette.passive.main,
                    },
                }}
                aria-haspopup="true"
                color="primary"
                aria-label="Inbox context menu"
                data-navigation-element
            >
                <SettingsIcon />
            </IconButton>
            <InboxContextMenu inboxId={inboxId} menu={menu} />
        </>
    );
}
