import { Components } from '@mui/material/styles';
import { Theme } from '@mui/material';

export const MuiListSubheader: Components<Theme>['MuiListSubheader'] = {
    styleOverrides: {
        root: ({ theme }) => ({
            ...theme.typography.body4,
            color: 'custom.gray.super',
            padding: theme.spacing(2),
            margin: theme.spacing(1, 1, 0, 1),
        }),
    },
};
