import { StepShell } from '../../Onboarding/StepShell';
import { carrierOptions } from '../utils';
import { InboxProvider } from '../../../api/types';
import { useTrack } from '../../../contexts/analytics';
import { useSetupInboxContext } from '../setup-inbox.context';
import {
    List,
    ListItem,
    Button,
    ListItemAvatar,
    ListItemText,
    Chip,
} from '@mui/material';
import { connectButton, icon, list, listItem } from './styles';
import { Link } from 'react-router-dom';
import { Carrier } from '../types';

const getRoute = (carrier: Carrier) => {
    switch (carrier) {
        case 'other':
        case 'zoom':
            return '/setup-inbox/phone-connect';
        case 'msteams': {
            return '/setup-inbox/ms-teams/auth';
        }
        case 'webex': {
            return '/setup-inbox/webex/auth';
        }
        case 'whatsapp': {
            return '/setup-inbox/whatsapp/auth';
        }
        default: {
            return '/setup-inbox/carrier-connect';
        }
    }
};

export const ExistingPhoneNumber = () => {
    const { setCarrier } = useSetupInboxContext();
    const track = useTrack();

    return (
        <StepShell
            title="Let's Get You Started!"
            subtitle="Add your phone number to connect with your favorite services. Pick your carrier below to begin!"
            icon="📲"
        >
            <List sx={list}>
                {carrierOptions.map((carrier) => {
                    return (
                        <ListItem sx={listItem} key={carrier.id}>
                            <ListItemAvatar sx={icon}>
                                {carrier.icon}
                            </ListItemAvatar>
                            <ListItemText
                                primary={carrier.label}
                                primaryTypographyProps={{
                                    variant: 'body3',
                                    color: carrier.isComingSoon
                                        ? 'custom.text.disabled'
                                        : 'primary.dark',
                                }}
                            />
                            {carrier.isComingSoon ? (
                                <Chip label="Coming soon" />
                            ) : (
                                <Button
                                    size="small"
                                    variant="outlined"
                                    color="primary"
                                    sx={connectButton}
                                    component={Link}
                                    to={getRoute(carrier?.id)}
                                    onClick={() => {
                                        track(
                                            'onboarding_user_selected_carrier',
                                            {
                                                carrier: carrier.id,
                                            },
                                        );

                                        const provider =
                                            carrier.id as InboxProvider;
                                        setCarrier(provider);
                                    }}
                                >
                                    Connect
                                </Button>
                            )}
                        </ListItem>
                    );
                })}
            </List>
        </StepShell>
    );
};
