import { AvailableIntegration } from '../integrations-list';
import {
    MenuItem,
    RowWithMenu,
} from '../../../../components/RowWithMenu/RowWithMenu';
import { Chip, Stack, Typography, Box } from '@mui/material';
import { LockIcon } from '../../../../icons/shared/LockIcon';
import { SyncStatus } from './SyncStatus';
import { GlobeIcon } from './GlobeIcon';
import { image } from './styles';
import { CloseIcon } from '../../../../icons/shared/CloseIcon';
import { RefreshIcon } from '../../../../icons/shared/RefreshIcon';
import { SettingsCogIcon } from '../../../../icons/shared/SettingsCogIcon';
import { useTrack } from '../../../../contexts/analytics';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { Integration, IntegrationTypes, Profile } from '../../../../api/types';
import {
    useDisconnectIntegration,
    useSyncIntegration,
    useUpdateIntegration,
} from '../../../../queries/integrations';
import { useTeammates } from '../../../../queries/user';
import { userName } from '../../../../helpers/formatting';
import {
    ToastActions,
    useToastContext,
} from '../../../../containers/toast/reducer';
import { AxiosError } from 'axios';

type Props = AvailableIntegration & {
    connected: Integration;
    me: Profile;
};

export function ConnectedRow({
    icon,
    name,
    hasSettings,
    connected,
    me,
}: Props) {
    const track = useTrack();
    const { mutateAsync: updateIntegration } = useUpdateIntegration();
    const { mutate: disconnectIntegration } = useDisconnectIntegration();
    const { mutateAsync: syncIntegration } = useSyncIntegration();
    const { dispatch: toastDispatch } = useToastContext();

    const isOwned = connected.userId === me.id;

    const navigate = useNavigate();

    const handleRefresh = async () => {
        try {
            await syncIntegration({
                id: connected.id,
            });

            track('integration_sync_initiated', {
                integration: connected.integrationSource,
                email: me.email,
                userId: me.id,
                teamId: me.activeTeam.id,
            });
        } catch (e) {
            handleBadRequest(e);
        }
    };

    const handleSettings = () => {
        navigate(`/settings/integrations/${connected.id}`);
    };

    const handleDisconnect = () => {
        try {
            disconnectIntegration({
                id: connected.id,
            });

            track('integration_disconnected', {
                integration: connected.integrationSource,
                email: me.email,
                userId: me.id,
                teamId: me.activeTeam.id,
            });
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error('DISCONNECT ERROR', e);
        }
    };

    function handleBadRequest(e: unknown) {
        if (!(e instanceof AxiosError)) {
            return;
        }
        if (e.response?.status === 400) {
            toastDispatch({
                type: ToastActions.ADD,
                payload: {
                    severity: 'error',
                    title: 'Update Integration',
                    description: e.response?.data?.message,
                },
            });
        }
    }

    const handleTogglePrivacy = useCallback(async () => {
        try {
            await updateIntegration({
                id: connected.id,
                isPublic: !connected.public,
            });

            track('integration_share_changed', {
                integration: connected.integrationSource,
                state: connected.public ? 'private' : 'public',
                email: me.email,
                userId: me.id,
                teamId: me.activeTeam.id,
            });
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error('TOGGLE PRIVACY ERROR', e);
            handleBadRequest(e);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [connected, me]);

    const menuItems = useMemo(() => {
        const menuItems: MenuItem[] = [
            {
                label: 'Refresh',
                icon: <RefreshIcon />,
                onClick: handleRefresh,
            },
        ];

        if (hasSettings) {
            menuItems.push({
                label: 'Settings',
                icon: <SettingsCogIcon />,
                onClick: handleSettings,
            });
        }

        if (connected.integrationType === IntegrationTypes.Contact) {
            menuItems.push({
                label: connected.public
                    ? 'Switch to Private'
                    : 'Switch to Team',
                icon: connected.public ? <GlobeIcon /> : <LockIcon />,
                onClick: handleTogglePrivacy,
                divider: true,
            });
        }

        menuItems.push({
            onClick: handleDisconnect,
            icon: <CloseIcon color="error" />,
            label: (
                <Typography variant="subtitle2" color="error">
                    Disconnect
                </Typography>
            ),
        });

        return menuItems;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasSettings, handleTogglePrivacy]);

    const { data: [profile] = [] } = useTeammates({
        select: (profiles) =>
            profiles.filter(({ id }) => id === connected.userId),
        disabled: isOwned,
    });

    return (
        <RowWithMenu menuItems={menuItems} isMenuDisabled={!isOwned}>
            <Stack spacing={4} direction="row" alignItems="center" flex={1}>
                <Box component="img" src={icon} alt={name} sx={image} />
                <div>
                    <Typography variant="body3" color="primary.dark">
                        {name}
                    </Typography>

                    <SyncStatus
                        status={connected.lastSyncStatus}
                        time={connected.lastSyncedAt}
                    />
                </div>
            </Stack>
            {connected.integrationType === IntegrationTypes.Contact && (
                <>
                    {!isOwned && (
                        <Typography variant="body4" color="text.secondary">
                            Added by {userName(profile)}
                        </Typography>
                    )}
                    <Chip
                        size="small"
                        color={connected.public ? 'info' : 'default'}
                        label={connected.public ? 'Team' : 'Private'}
                        icon={connected.public ? <GlobeIcon /> : <LockIcon />}
                    />
                </>
            )}
        </RowWithMenu>
    );
}
