import { Components, tableRowClasses, Theme } from '@mui/material';
import { colors } from './palette';

export const MuiTableRow: Components<Theme>['MuiTableRow'] = {
    styleOverrides: {
        root: {
            [`&.${tableRowClasses.hover}`]: {
                ':hover': {
                    backgroundColor: colors.blue[50],
                },
            },
            [`&.${tableRowClasses.selected}`]: {
                backgroundColor: colors.blue[50],
            },
        },
    },
};
