import { useEffect, useMemo, useState } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import styles from '../WorkflowStepConfigurator.module.scss';
import Button from '../../../../elements/Buttons';
import { WorkflowAction } from '../../types';
import { useWorkflowContext } from '../../WorkflowProvider';
import { WorkflowReducerActionTypes } from '../../WorkflowProvider/reducer';
import { useCohortsQuery } from '../../../../queries/cohorts';
import AdvancedSelect, {
    OptionBase,
} from '../../../../elements/AdvancedSelect';
import { useModalContext } from '../../../../containers/modal/reducer';
import CreateEditCohort from '../../../../containers/CreateEditCohort';
import { Icon } from '../../../../icons/Icon';
import { STEP_ICON_MAP } from '../../utils';
import { CloseIcon } from '../../../../icons/common/CCloseIcon';

interface AddToCohortStepProps {
    step: WorkflowAction;
}

export const AddToCohortStep = ({ step }: AddToCohortStepProps) => {
    const { dispatch } = useWorkflowContext();
    const [actionStep, setStep] = useState(cloneDeep(step));
    const cohorts = useCohortsQuery();
    const { dispatch: dispatchModal } = useModalContext();

    const cohortOptions = useMemo(
        (): OptionBase[] =>
            cohorts.map((c) => ({ id: c.id.toString(), label: c.name })),
        [cohorts],
    );

    useEffect(() => setStep(cloneDeep(step as WorkflowAction)), [step]);

    const onSaveHandler = () => {
        dispatch({
            type: WorkflowReducerActionTypes.UPDATE_WORKFLOW_STEP,
            payload: actionStep,
        });
    };

    return (
        <div className={styles['aside']}>
            <header className={styles['aside__header']}>
                <h3 className={styles['aside__title']}>
                    <Icon name={STEP_ICON_MAP[step.action]} />
                    <span>Add To Cohort</span>
                </h3>
                <button
                    className={styles['aside__close-btn']}
                    onClick={() =>
                        dispatch({
                            type: WorkflowReducerActionTypes.SET_SELECTED_STEP,
                            payload: null,
                        })
                    }
                >
                    <CloseIcon />
                </button>
            </header>

            <div className={styles['aside__content']}>
                <section className={styles['aside__box']}>
                    <p>
                        Automatically add inbound number or contact into the
                        following list:
                    </p>
                    <label className={styles['aside__box-label']}>List</label>
                    <AdvancedSelect
                        options={cohortOptions}
                        onChange={(c) =>
                            setStep({
                                ...actionStep,
                                cohortConfiguration: { cohortId: parseInt(c) },
                            })
                        }
                        searchable={true}
                        value={actionStep.cohortConfiguration?.cohortId?.toString()}
                    />
                    <br />
                    <Button
                        type="default"
                        onClick={() =>
                            dispatchModal({
                                type: 'PUSH',
                                payload: {
                                    content: () => (
                                        <CreateEditCohort step="create" />
                                    ),
                                },
                            })
                        }
                    >
                        Create New List
                    </Button>
                </section>
            </div>
            <div className={styles['aside__button-container']}>
                <Button onClick={onSaveHandler} fullWidth>
                    Save
                </Button>
            </div>
        </div>
    );
};
