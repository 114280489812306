import { useNavigate } from 'react-router';
import { ConversationsList } from './ConversationsList';
import Loading from '../Loading/Loading';
import { useScheduledConversationsQuery } from '../../queries/scheduled-messages';
import { ClockRevertIcon } from '../../assets/newAssets/icons/CClockRevertIcon';
import { getSquare } from '../../theme/style.helpers';

export const ScheduledConversationsList = () => {
    const {
        conversations,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        isPending,
    } = useScheduledConversationsQuery();
    const navigate = useNavigate();

    if (isPending) {
        return <Loading />;
    }

    return (
        <ConversationsList
            name="Scheduled"
            icon={<ClockRevertIcon sx={getSquare(24)} />}
            conversations={conversations}
            fetchNextPage={fetchNextPage}
            hasNextPage={hasNextPage}
            isFetchingNextPage={isFetchingNextPage}
            getHref={(conversationId) => `/scheduled/${conversationId}`}
            onTouchEnd={(conversationId) =>
                navigate(`/scheduled/${conversationId}`)
            }
            view="Cohort"
            isLoading={isPending}
        />
    );
};
