import { Typography } from '@mui/material';
import { useMeQueryData, useTeammates } from '../../../queries/user';
import { UUID } from '../../../types/uuid';
import { UserAvatar } from '../../UserAvatar/UserAvatar';
import { ActivityLine } from '../ActivityLine';
import { getFullName } from '../../../utils/user';
import { Navigate } from '../Navigate';
import { ActivityType, Status } from '../types';
import { useMarkActivityAsRead } from '../../../queries/activity';

export type SentTeamInviteActivity = {
    id: UUID;
    type: ActivityType.SentTeamInvite;
    initiatingContext: {
        userId: UUID;
    };
    targetContext: {
        teamId: UUID;
        inviteeEmail: string;
    };
    occurredAt: string;
    status: Status;
};

export const SentTeamInvite = ({
    initiatingContext,
    targetContext,
    status,
    id,
}: SentTeamInviteActivity) => {
    const { data: teammates } = useTeammates({
        select: (teammates) => {
            const teammate = teammates.find(
                (teammate) => teammate.id === initiatingContext.userId,
            );
            return teammate ? [teammate] : [];
        },
    });

    const { mutateAsync: markAsRead } = useMarkActivityAsRead();

    const me = useMeQueryData();
    const initUser = teammates?.[0];
    return (
        <ActivityLine
            id={id}
            avatar={<UserAvatar imageUrl={initUser?.avatar?.url} size={20} />}
            title={
                <>
                    <Typography
                        variant="body3"
                        fontWeight={500}
                        data-testid="sent-team-invite-initiator"
                    >
                        {me?.id === initUser?.id
                            ? 'You'
                            : getFullName(initUser)}
                    </Typography>
                    <Typography variant="body3"> invited </Typography>
                    <Typography
                        variant="body3"
                        fontWeight={500}
                        data-testid="sent-team-invite-invitee-email"
                    >
                        {targetContext.inviteeEmail}
                    </Typography>
                    <Typography
                        variant="body3"
                        data-testid="sent-team-invite-team-name"
                    >
                        {' '}
                        to {me?.activeTeam.name}
                    </Typography>
                </>
            }
            status={status}
            action={
                <Navigate
                    path="/settings/members"
                    onClick={() => markAsRead(id)}
                />
            }
        />
    );
};
