import { CircularProgress, IconButton, Stack, Tooltip } from '@mui/material';
import { ReactNode } from 'react';
import { MarkAsReadIcon } from '../Inbox/CMarkAsReadIcon';
import { useMarkActivityAsRead } from '../../queries/activity';
import { UUID } from '../../types/uuid';
import { useTrack } from '../../contexts/analytics';

type Props = {
    id: UUID;
    action: ReactNode;
    withMarkAsRead?: boolean;
};

export const Action = ({ action, withMarkAsRead = false, id }: Props) => {
    const { mutateAsync: markAsRead, isPending } = useMarkActivityAsRead();
    const track = useTrack();
    return (
        <Stack direction="row" gap={2} alignItems="center">
            {withMarkAsRead && (
                <Tooltip title="Mark as read" placement="top" arrow>
                    <IconButton
                        color="primary"
                        onClick={() => {
                            markAsRead(id).then((activity) =>
                                track('activity_marked_as_read', {
                                    activityId: activity.id,
                                }),
                            );
                        }}
                        data-testid="mark-as-read-button"
                    >
                        {isPending ? (
                            <CircularProgress size={18} />
                        ) : (
                            <MarkAsReadIcon />
                        )}
                    </IconButton>
                </Tooltip>
            )}

            {action}
        </Stack>
    );
};
