import confetti from 'canvas-confetti';
import { useEffect, useLayoutEffect, useMemo } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { canvas, contentWrapper, root } from './styles';
import { useInboxesQueryData } from '../../../queries/inboxes';
import { Link } from 'react-router-dom';
import { queryClient } from '../../../queries/queryClient';
import { CURRENT_USER_KEY } from '../../../queries/user';

export const PaymentFinish = () => {
    const inboxes = useInboxesQueryData();

    useEffect(() => {
        queryClient.invalidateQueries({ queryKey: [CURRENT_USER_KEY] });
    }, []);

    useLayoutEffect(() => {
        const canvas = document.getElementById(
            'payment-finish-canvas',
        ) as HTMLCanvasElement;

        if (canvas) {
            const myConfetti = confetti.create(canvas, { resize: true });
            myConfetti({
                particleCount: 300,
                spread: 220,
                origin: { y: 0.1 },
            });
        }
    }, []);

    const completionLink = useMemo(() => {
        if (!inboxes || inboxes.length === 0) {
            return '/setup-inbox/existing-number';
        }

        return `/inbox/${inboxes[0].id}/new`;
    }, [inboxes]);

    return (
        <Box sx={root}>
            <Box component="canvas" id="payment-finish-canvas" sx={canvas} />
            <Box sx={contentWrapper}>
                <Stack width={400} spacing={4} alignItems="center">
                    <Box mb={1}>🎉</Box>

                    <Box textAlign="center">
                        <Typography variant="h2" color="primary.dark" mb={1}>
                            Successfully paid!
                        </Typography>

                        <Typography variant="body3" color="primary.dark">
                            Now you can use Clerk without any limits!
                        </Typography>
                    </Box>

                    <Button
                        size="large"
                        color="info"
                        component={Link}
                        to={completionLink}
                    >
                        {inboxes && inboxes.length > 0
                            ? 'Start messaging now!'
                            : 'Setup your first inbox'}
                    </Button>
                </Stack>
            </Box>
        </Box>
    );
};
