import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export const CodeForkIcon = (props: Exclude<SvgIconProps, 'component'>) => (
    <SvgIcon {...props} viewBox="0 0 24 24" fill="none">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.5 3.75C4.5335 3.75 3.75 4.5335 3.75 5.5C3.75 6.4665 4.5335 7.25 5.5 7.25C6.4665 7.25 7.25 6.4665 7.25 5.5C7.25 4.5335 6.4665 3.75 5.5 3.75ZM2.25 5.5C2.25 3.70507 3.70507 2.25 5.5 2.25C7.29493 2.25 8.75 3.70507 8.75 5.5C8.75 7.03679 7.68335 8.32445 6.25 8.66303V8.8C6.25 9.37243 6.25058 9.75664 6.27476 10.0525C6.29822 10.3396 6.3401 10.4769 6.38624 10.5675C6.50608 10.8027 6.69731 10.9939 6.93251 11.1138C7.02307 11.1599 7.16035 11.2018 7.44748 11.2252C7.74336 11.2494 8.12757 11.25 8.7 11.25H15.3C15.8724 11.25 16.2566 11.2494 16.5525 11.2252C16.8396 11.2018 16.9769 11.1599 17.0675 11.1138C17.3027 10.9939 17.4939 10.8027 17.6138 10.5675C17.6599 10.4769 17.7018 10.3396 17.7252 10.0525C17.7494 9.75664 17.75 9.37243 17.75 8.8V8.66303C16.3166 8.32445 15.25 7.03679 15.25 5.5C15.25 3.70507 16.7051 2.25 18.5 2.25C20.2949 2.25 21.75 3.70507 21.75 5.5C21.75 7.03679 20.6833 8.32445 19.25 8.66303V8.83045C19.25 9.3646 19.25 9.81044 19.2203 10.1747C19.1892 10.5546 19.1221 10.9112 18.9503 11.2485C18.6866 11.7659 18.2659 12.1866 17.7485 12.4503C17.4112 12.6221 17.0546 12.6892 16.6747 12.7203C16.3104 12.75 15.8646 12.75 15.3305 12.75H12.75V15.337C14.1834 15.6755 15.25 16.9632 15.25 18.5C15.25 20.2949 13.7949 21.75 12 21.75C10.2051 21.75 8.75 20.2949 8.75 18.5C8.75 16.9632 9.81665 15.6755 11.25 15.337V12.75H8.66955C8.1354 12.75 7.68956 12.75 7.32533 12.7203C6.94545 12.6892 6.58879 12.6221 6.25153 12.4503C5.73408 12.1866 5.31338 11.7659 5.04973 11.2485C4.87789 10.9112 4.81078 10.5546 4.77974 10.1747C4.74998 9.81044 4.74999 9.36459 4.75 8.83043L4.75 8.66303C3.31665 8.32445 2.25 7.03679 2.25 5.5ZM18.5 3.75C17.5335 3.75 16.75 4.5335 16.75 5.5C16.75 6.4665 17.5335 7.25 18.5 7.25C19.4665 7.25 20.25 6.4665 20.25 5.5C20.25 4.5335 19.4665 3.75 18.5 3.75ZM12 16.75C11.0335 16.75 10.25 17.5335 10.25 18.5C10.25 19.4665 11.0335 20.25 12 20.25C12.9665 20.25 13.75 19.4665 13.75 18.5C13.75 17.5335 12.9665 16.75 12 16.75Z"
            fill="currentColor"
        />
    </SvgIcon>
);
