import { useQuery } from '@tanstack/react-query';
import { getReseller } from '../api/reseller';
import { useMeQuery } from './user';
import { Reseller } from '../api/types';

export const useReseller = () => {
    const me = useMeQuery();
    return useQuery<Reseller>({
        queryKey: ['reseller'],
        queryFn: getReseller,
        enabled: !!me.data?.activeTeam?.resellerId,
    });
};
