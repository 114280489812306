import { CustomEditor, TemplateElement } from '../../../../Slate/slate';
import { Descendant, Transforms } from 'slate';
import { jsx } from 'slate-hyperscript';

export function templateDeserializer(el: Node, children: Descendant[]) {
    if (el.nodeName.toLowerCase() !== 'tpl') {
        return null;
    }

    if (children.length === 1 && 'text' in children[0]) {
        const template = createTemplateNode(children[0]?.text);
        return jsx('element', template, template.children);
    }

    return null;
}

export function templateSerializer(node: Descendant) {
    if ('type' in node && node.type === 'template') {
        return textToTemplate(node.template);
    }

    return null;
}

function textToTemplate(text: string) {
    return `{{{${text}}}}`;
}

function createTemplateNode(template: string): TemplateElement {
    return {
        type: 'template',
        template,
        children: [{ text: textToTemplate(template) }],
    };
}

export function insertTemplate(editor: CustomEditor, template: string) {
    Transforms.insertNodes(editor, createTemplateNode(template));
    Transforms.move(editor);
}
