import { useMutation, useQuery } from '@tanstack/react-query';
import {
    Brand,
    BrandRegistrationStatus,
    ClerkPermission,
    CreateBrandDto,
    Profile,
    PublicFile,
    UpdateBrandDto,
} from '../api/types';
import { queryClient } from './queryClient';
import { CURRENT_USER_KEY, TEAMMATES_KEY, useMeQueryData } from './user';
import { client } from '../api/http';
import { useTrack } from '../contexts/analytics';
import { AxiosError } from 'axios';
import { usePermission } from '../containers/WithPermission/WithPermission';

const BRAND_KEY = 'brand';

export const useBrandQuery = (enabled = true) => {
    const access = usePermission(ClerkPermission.BrandAccess);

    return useQuery({
        queryKey: [BRAND_KEY],
        queryFn: () => client.get<Brand>('/brand').then(({ data }) => data),
        staleTime: 1_000,
        enabled: enabled && access,
    });
};

export const useIsVerified = () =>
    useBrandQuery().data?.status === BrandRegistrationStatus.VERIFIED;

export const useBrandCreate = () => {
    const track = useTrack();

    return useMutation({
        mutationFn: (brand: CreateBrandDto) =>
            client.post<Brand>('/v2/brand', brand).then(({ data }) => data),
        onSettled: () =>
            queryClient.invalidateQueries({ queryKey: [BRAND_KEY] }),
        onError: (err: AxiosError<{ message?: string[] }>) => {
            track('brand_creation_error', {
                code: err.response?.status,
                errorCount: Array.isArray(err.response?.data?.message)
                    ? err.response.data.message?.length
                    : 1,
            });
        },
        onSuccess: () => {
            track('brand_created');
        },
    });
};

export const useBrandUpdate = () => {
    const track = useTrack();

    return useMutation({
        mutationFn: ({ id, brand }: UpdateBrandDto) =>
            client
                .put<Partial<Brand>>(`/v2/brand/${id}`, brand)
                .then(({ data }) => data),
        onSettled: () =>
            queryClient.invalidateQueries({ queryKey: [BRAND_KEY] }),
        onSuccess: () => {
            track('brand_updated');
        },
    });
};

export const useRegistration = () => {
    return useMutation({
        mutationFn: (id: number) => client.post<void>(`/brand/${id}/register`),
        onSuccess: async () => {
            await queryClient.refetchQueries({ queryKey: [BRAND_KEY] });
        },
    });
};

export const useUpdateUserAvatar = () => {
    const me = useMeQueryData();
    return useMutation({
        mutationFn: ({ file }: { file: File }) => {
            if (file.size > 10_000_000) {
                throw Error('Image max size exceeded.');
            }

            const formData = new FormData();
            formData.append('file', file);

            return client
                .post<PublicFile>(`/users/avatar`, formData)
                .then(({ data }) => data);
        },
        onSuccess: async ({ url }) => {
            queryClient.setQueryData<Profile[]>([TEAMMATES_KEY], (prev) => {
                return !prev
                    ? prev
                    : prev.map((profile) => {
                          if (profile.id === me?.id) {
                              return {
                                  ...profile,
                                  avatar: { url },
                              };
                          }
                          return profile;
                      });
            });
            await queryClient.refetchQueries({ queryKey: [CURRENT_USER_KEY] });
        },
    });
};
