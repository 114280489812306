import { Button } from '@mui/material';
import { useInboxes } from '../../../queries/inboxes';
import { useMeQueryData, useUpdateMe } from '../../../queries/user';
import { useMemo } from 'react';
import { SettingsLayout } from '../../../containers/SettingsLayout/SettingsLayout';
import uniq from 'lodash/uniq';

export const NotificationSettings = () => {
    const me = useMeQueryData();
    const { data: currentTeamInboxes = [] } = useInboxes();
    const { mutate, isPending } = useUpdateMe();

    // Need to filter out muted inboxes that are only on the current team. user.mutedInboxIds includes all muted inboxes across all teams.
    const currentTeamMutedInboxIds = useMemo(
        () =>
            currentTeamInboxes.filter((inbox) =>
                me?.mutedInboxIds?.includes(inbox.id),
            ),
        [currentTeamInboxes, me?.mutedInboxIds],
    );

    const allCurrentTeamInboxesAreMuted: boolean = useMemo(
        () => currentTeamInboxes.length === currentTeamMutedInboxIds.length,
        [currentTeamInboxes.length, currentTeamMutedInboxIds.length],
    );

    const toggleMuteAllInboxes = () => {
        const newMutedInboxIds = allCurrentTeamInboxesAreMuted
            ? me?.mutedInboxIds?.filter(
                  // New muted inbox ids should not include any inbox ids that are not in the current team
                  (id) => !currentTeamInboxes.map(({ id }) => id).includes(id),
              )
            : uniq([
                  // New muted inbox ids should include all inbox ids from the current team
                  ...(me?.mutedInboxIds || []),
                  ...currentTeamInboxes.map(({ id }) => id),
              ]);

        mutate({
            mutedInboxIds: newMutedInboxIds,
        });
    };

    return (
        <SettingsLayout title="Notifications" category="Workspace">
            <Button
                variant="outlined"
                disableRipple
                onClick={toggleMuteAllInboxes}
                disabled={isPending}
                sx={{ width: 200 }}
            >
                {allCurrentTeamInboxesAreMuted
                    ? 'Unmute all Inboxes'
                    : 'Mute all Inboxes'}
            </Button>
        </SettingsLayout>
    );
};
