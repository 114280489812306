import { useEffect } from 'react';
import { useTrack } from '../../contexts/analytics';
import { useNavigate } from 'react-router';

export const useHotkeys = (url: string) => {
    const track = useTrack();
    const navigate = useNavigate();

    useEffect(() => {
        function keyHandler(this: Document, e: KeyboardEvent) {
            if (e.code === 'KeyN' && (e.altKey || e.metaKey)) {
                e.preventDefault();
                track('new_conversation_toggled_with_hotkey');
                navigate(url);
            }
        }

        window.document.addEventListener('keydown', keyHandler);

        return () => {
            window.document.removeEventListener('keydown', keyHandler);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};
