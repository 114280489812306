import { useEffect, useState } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import styles from './WebhookStep.module.scss';
import { WorkflowAction } from '../../types';
import { useWorkflowContext } from '../../WorkflowProvider';
import { WorkflowReducerActionTypes } from '../../WorkflowProvider/reducer';
import Button from '../../../../elements/Buttons';
import Input from '../../../../elements/Inputs/Input';
import { Icon } from '../../../../icons/Icon';
import { STEP_ICON_MAP } from '../../utils';
import { CloseIcon } from '../../../../icons/common/CCloseIcon';
import { getSquare } from '../../../../theme/style.helpers';
import { colors } from '../../../../theme/palette';

interface WebhookStepProps {
    step: WorkflowAction;
}
export const WebhookStep = ({ step }: WebhookStepProps) => {
    const { dispatch } = useWorkflowContext();
    const [actionStep, setStep] = useState(cloneDeep(step));

    useEffect(() => setStep(cloneDeep(step as WorkflowAction)), [step]);

    const onSaveHandler = () => {
        dispatch({
            type: WorkflowReducerActionTypes.UPDATE_WORKFLOW_STEP,
            payload: actionStep,
        });
    };

    return (
        <div className={styles['aside']}>
            <header className={styles['aside__header']}>
                <h3 className={styles['aside__title']}>
                    <Icon name={STEP_ICON_MAP[step.action]} />
                    <span>Webhook</span>
                </h3>
                <button
                    className={styles['aside__close-btn']}
                    onClick={() =>
                        dispatch({
                            type: WorkflowReducerActionTypes.SET_SELECTED_STEP,
                            payload: null,
                        })
                    }
                >
                    <CloseIcon
                        sx={{ ...getSquare(24), color: colors.superGrey[500] }}
                    />
                </button>
            </header>

            <div className={styles['aside__content']}>
                <section className={styles['aside__box']}>
                    <label className={styles['aside__box-label']}>
                        Item name
                    </label>
                    <Input
                        placeholder="Enter name"
                        value={actionStep?.name || ''}
                        onChange={(name) => setStep({ ...actionStep, name })}
                        uncontrolled
                        fullWidth
                    />
                </section>

                <section className={styles['aside__box']}>
                    <label className={styles['aside__box-label']}>
                        Request URL
                    </label>
                    <Input
                        placeholder="Enter URL"
                        value={actionStep?.webhookConfiguration?.url || ''}
                        onChange={(url) => {
                            const webhookConfiguration = {
                                ...actionStep.webhookConfiguration,
                                url,
                            };

                            setStep({ ...actionStep, webhookConfiguration });
                        }}
                        uncontrolled
                        fullWidth
                    />
                </section>
                <section className={styles['aside__box']}>
                    <label className={styles['aside__box-label']}>
                        Request Payload (in JSON)
                    </label>
                    <Input
                        isTextArea
                        placeholder='JSON payload to send. Example using the message body {"textmessage": "{{body}}"}'
                        value={actionStep?.webhookConfiguration?.payload || ''}
                        onChange={(payload) => {
                            const webhookConfiguration = {
                                ...actionStep.webhookConfiguration,
                                payload,
                            };

                            setStep({ ...actionStep, webhookConfiguration });
                        }}
                        uncontrolled
                        fullWidth
                    />
                    <p className={styles['aside__box-label']}>
                        The variables available are: to,from,body,inboxPhone. To
                        use them, place them in double brackets.
                    </p>
                </section>
            </div>

            <div className={styles['aside__button-container']}>
                <Button onClick={onSaveHandler} fullWidth>
                    Save
                </Button>
            </div>
        </div>
    );
};
