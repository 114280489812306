import { IconButton, Tooltip } from '@mui/material';
import { SnippetIcon } from './SnippetIcon';
import { RefObject, useState, memo, useEffect } from 'react';
import { CustomEditor } from '../../../../Slate/slate';
import { MessageTemplate } from '../../../../../api/types';
import { SnippetPopover } from './SnippetPopover';
import { focusVisible } from '../../../../../theme/focusVisible';

type Props = {
    containerRef: RefObject<HTMLElement | null>;
    disabled?: boolean;
    editor: CustomEditor;
    withProperties: boolean;
    propertiesRegEx: RegExp;
    onSelect: (template: MessageTemplate) => void;
};

export const SnippetButton = memo(function SnippetButton({
    containerRef,
    disabled = false,
    withProperties,
    onSelect,
}: Props) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        window.addEventListener('resize', handleClose);
        return () => {
            window.removeEventListener('resize', handleClose);
        };
    }, []);

    return (
        <>
            <Tooltip title="Add Template" arrow placement="top">
                <span>
                    <IconButton
                        disabled={disabled}
                        onClick={() => {
                            setAnchorEl((ref) =>
                                ref ? null : containerRef?.current,
                            );
                        }}
                        color={anchorEl ? 'info' : 'primary'}
                        aria-label="Add Template"
                        sx={{
                            '&:focus-visible': {
                                ...focusVisible,
                                outlineOffset: '2px',
                            },
                        }}
                    >
                        <SnippetIcon />
                    </IconButton>
                </span>
            </Tooltip>

            {!!anchorEl && (
                <SnippetPopover
                    anchorEl={anchorEl}
                    onSelect={(template) => {
                        onSelect(template);
                        handleClose();
                    }}
                    onClose={handleClose}
                    withProperties={withProperties}
                />
            )}
        </>
    );
});
