import React, { useMemo, useState } from 'react';
import {
    Divider,
    IconButton,
    InputAdornment,
    ListItemIcon,
    ListSubheader,
    MenuItem,
    Select,
    SelectProps,
    Typography,
} from '@mui/material';
import { SearchTextField } from '../../elements/SearchTextField/SearchTextField';
import * as styles from './styles';
import { CloseFilledIcon } from '../../icons/common/CloseFilledIcon';
import { Option, OptionGroup } from './types';
import { OptionItem } from './OptionItem';

export type EnhancedSelectProps<Value> = Omit<
    SelectProps<Value>,
    'onChange'
> & {
    options: Option[] | OptionGroup[];
    searchable?: boolean;
    onSearch?: (searchTerm: string) => void;
    hasCheckbox?: boolean;
    menuFullHeight?: boolean;
    onChange: (value: string, checked: boolean) => void;
    footer?: React.ReactNode;
    topAction?: React.ReactNode;
    menuMaxWidth?: number;
    noOptionsText?: string;
    onClear?: () => void;
};

export function EnhancedSelect<Value = unknown>({
    value,
    options,
    hasCheckbox,
    placeholder,
    searchable,
    onChange,
    footer,
    topAction,
    menuMaxWidth,
    multiple = false,
    variant = 'outlined',
    noOptionsText,
    onClose,
    onOpen,
    menuFullHeight = false,
    MenuProps,
    onSearch,
    onClear,
    ...rest
}: EnhancedSelectProps<Value>) {
    const [isOpened, setIsOpened] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const filteredOptions = useMemo(() => {
        if (
            !searchable ||
            searchTerm.length <= 0 ||
            typeof onSearch === 'function'
        ) {
            return options;
        }

        if (options.every((item) => 'options' in item)) {
            return options.reduce<OptionGroup[]>((acc, item) => {
                const filteredOptions = item.options.filter((option) =>
                    option.label
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase()),
                );

                if (filteredOptions.length > 0) {
                    return acc.concat({
                        ...item,
                        options: filteredOptions,
                    });
                }
                return acc;
            }, []);
        }

        return options.filter((option) =>
            option.label.toLowerCase().includes(searchTerm.toLowerCase()),
        );
    }, [searchable, searchTerm, onSearch, options]);

    const flatOptions = useMemo(() => {
        return options.reduce<Option[]>(
            (acc, item) => acc.concat('options' in item ? item.options : item),
            [],
        );
    }, [options]);

    return (
        <Select
            multiple={multiple}
            variant={variant}
            open={isOpened}
            displayEmpty={!!placeholder}
            value={value}
            placeholder={placeholder}
            onClose={(e) => {
                setIsOpened(false);
                typeof onClose === 'function' && onClose(e);
            }}
            onOpen={(e) => {
                setIsOpened(true);
                typeof onOpen === 'function' && onOpen(e);
            }}
            inputProps={{
                ...rest.inputProps,
            }}
            endAdornment={
                onClear && value ? (
                    <InputAdornment sx={{ marginRight: 5 }} position="end">
                        <IconButton
                            size={rest.size}
                            onClick={() => {
                                onClear();
                            }}
                        >
                            <CloseFilledIcon sx={{ opacity: 0.48 }} />
                        </IconButton>
                    </InputAdornment>
                ) : undefined
            }
            renderValue={() => {
                if ((!value || (value as string).length === 0) && placeholder) {
                    return (
                        <Typography
                            variant="body3"
                            mr={3}
                            color="custom.gray.super"
                            noWrap
                        >
                            {placeholder}
                        </Typography>
                    );
                }

                if (Array.isArray(value)) {
                    return (
                        <Typography variant="body3" mr={3} noWrap>
                            {value
                                .map(
                                    (id) =>
                                        flatOptions.find((o) => o.id === id)
                                            ?.label ?? '',
                                )
                                .filter(Boolean)
                                .join(', ')}
                        </Typography>
                    );
                }

                const selectedItem = flatOptions.find((o) => o.id === value);
                const Icon = selectedItem?.icon;
                return (
                    <>
                        {!!Icon && (
                            <ListItemIcon>
                                <Icon sx={styles.icon} />
                            </ListItemIcon>
                        )}
                        <Typography variant="body3" mr={3} noWrap>
                            {selectedItem?.label ?? ''}
                        </Typography>
                    </>
                );
            }}
            MenuProps={{
                ...MenuProps,
                sx: menuFullHeight
                    ? MenuProps?.sx
                    : {
                          ...MenuProps?.sx,
                          maxHeight: 300,
                      },
            }}
            {...rest}
        >
            {!!searchable && [
                /* @ts-expect-error - `onKeyDown` is not available in `MenuItem` type, but it is available in DOM element and required for `SearchTextField` */
                <MenuItem
                    key="search"
                    dense
                    sx={styles.searchHeader}
                    onKeyDown={(e: KeyboardEvent) => e.stopPropagation()}
                >
                    <SearchTextField
                        fullWidth
                        value={searchTerm}
                        variant="outlined"
                        InputProps={{ sx: styles.searchInputSx }}
                        inputProps={{ sx: styles.searchInput }}
                        placeholder="Search"
                        onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                            event.stopPropagation();
                        }}
                        onChange={(event) => {
                            event.stopPropagation();
                            setSearchTerm(
                                (event.target as HTMLInputElement)?.value,
                            );
                            typeof onSearch === 'function' &&
                                onSearch?.(
                                    (event.target as HTMLInputElement)?.value,
                                );
                        }}
                    />
                </MenuItem>,
                <Divider key="search-divider" />,
            ]}
            {!!topAction && [
                <MenuItem sx={{ py: 0 }} key="topAction">
                    {topAction}
                </MenuItem>,
                <Divider key="divider" />,
            ]}
            {filteredOptions.length ? (
                filteredOptions.map((item) => {
                    if ('options' in item) {
                        return [
                            <ListSubheader key={item.label}>
                                {item.label}
                            </ListSubheader>,
                            ...item.options.map((option) => {
                                const isChecked = Array.isArray(value)
                                    ? value.includes(option.id)
                                    : value === option.id;

                                return (
                                    <OptionItem
                                        key={option.id}
                                        isChecked={isChecked}
                                        option={option}
                                        hasCheckbox={hasCheckbox}
                                        menuMaxWidth={menuMaxWidth}
                                        onChange={(event) => {
                                            event.stopPropagation();

                                            onChange(option.id, !isChecked);

                                            if (!multiple) {
                                                setIsOpened(false);
                                            }
                                        }}
                                    />
                                );
                            }),
                        ];
                    }

                    const isChecked = Array.isArray(value)
                        ? value.includes(item.id)
                        : value === item.id;

                    return (
                        <OptionItem
                            key={item.id}
                            isChecked={isChecked}
                            option={item}
                            hasCheckbox={hasCheckbox}
                            menuMaxWidth={menuMaxWidth}
                            onChange={(event) => {
                                event.stopPropagation();

                                onChange(item.id, !isChecked);

                                if (!multiple) {
                                    setIsOpened(false);
                                }
                            }}
                        />
                    );
                })
            ) : (
                <Typography
                    display="block"
                    px={4}
                    py={2}
                    variant="body3"
                    color="text.disabled"
                >
                    {noOptionsText ?? 'No Options'}
                </Typography>
            )}
            {!!footer && [
                <Divider key="divider" />,
                <MenuItem sx={{ py: 0 }} key="footer">
                    {footer}
                </MenuItem>,
            ]}
        </Select>
    );
}
