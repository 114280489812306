import { ContactPropertyType } from '../api/types';
import { propertyTypeIconNameMap } from '../components/ContactProperty/contactPropertyTypeMap';
import { PropertySource } from '../components/ContactProperty/types';
import { PropertyIcon } from '../icons/iconsMap';

export const getPropertyIcon = (
    icon?: PropertyIcon,
    type?: ContactPropertyType,
    source?: PropertySource,
): PropertyIcon => {
    if (icon && icon?.match(/\//g) && type) {
        return propertyTypeIconNameMap[type];
    }

    if (icon) {
        return icon;
    }

    switch (source) {
        case PropertySource.Clerk:
            return 'clerk-logo';
        case PropertySource.Google:
            return 'google-contacts-logo';
        case PropertySource.Microsoft:
            return 'microsoft-outlook-logo';
        case PropertySource.Salesforce:
            return 'salesforce-logo';
        case PropertySource.Shopify:
            return 'shopify-logo';
        case PropertySource.Hubspot:
            return 'hubspot-logo';
    }

    return '';
};
