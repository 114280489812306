import { IconButton, Menu } from '@mui/material';

import { BiDotsHorizontalRounded } from 'react-icons/bi';
import { memo } from 'react';
import { ConversationActions } from '../../ConversationRow/ConversationActions';
import { useMenu } from '../../../hooks/use-menu';
import { Conversation } from '../../../api/types';

type Props = {
    conversation: Conversation;
};
export const ContextMenu = memo(function ContextMenu({ conversation }: Props) {
    const menu = useMenu();

    return (
        <>
            <IconButton
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                    e.preventDefault();
                    e.stopPropagation();
                    menu.handleOpen(e);
                }}
                className="item-context-menu"
                sx={{
                    position: 'absolute',
                    right: 12,
                    top: 28,
                    opacity: menu.open ? 1 : 0,
                }}
                aria-label="Context menu"
                size="small"
                color={menu.open ? 'white-contained' : 'primary'}
            >
                <BiDotsHorizontalRounded />
            </IconButton>
            <Menu
                anchorEl={menu.anchorEl}
                open={menu.open}
                onClose={() => menu.handleClose()}
                onClick={(e) => e.stopPropagation()}
            >
                <ConversationActions
                    conversation={conversation}
                    onAction={menu.handleClose}
                />
            </Menu>
        </>
    );
});
